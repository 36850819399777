export const cronNames: Record<number, string | undefined> = {
  1: 'ethereum',
  5: 'goerli',
  10: 'optimism	',
  137: 'polygon',
  100: 'gnosis',
  42161: 'arbitrum',
  80001: 'mumbai',
  324: 'zksync',
  1101: 'zkevm',
  56: 'bnb',
  8453: 'base',
  43114: 'avalanche',
  250: 'fantom',
  1313161554: 'aurora',
}
