import React from 'react'
import styled from 'styled-components'
import usePrice from '../../hooks/usePrice'
import refreshIcon from '../../assets/refresh.png'

interface TokenRegistryData {
  chainId: number
  address: string
}

const Price: React.FC<{ item: TokenRegistryData }> = ({ item }) => {
  const { price, getPrice, refreshPrice } = usePrice(item.chainId, item.address)

  const handleRefreshClick = () => {
    refreshPrice()
  }

  return (
    <>
      {price ? (
        <PriceContainer>
          {price}
          <img onClick={handleRefreshClick} src={refreshIcon} alt="Refresh" />
        </PriceContainer>
      ) : (
        <Button onClick={getPrice}>Show price</Button>
      )}
    </>
  )
}

const PriceContainer = styled.div`
  display: flex;
  gap: 5px;
`

const Button = styled.button`
  background-color: transparent;
  color: #5ce6d5;
  border: none;
  cursor: pointer;
  font-weight: bold;
`

export default Price
