export const PLAYERS = {
  // eslint-disable-next-line
  ['0xb3afb6db38a8e72905165c1fbb96772e63560790']: {
    name: 'Mimic Bot',
    backgroundColor: '#5542A9',
  },
  // eslint-disable-next-line
  ['0x27751a0fe3bd6ebfeb04b359d97b0cf199f20d22']: {
    name: 'Mimic Collector',
    backgroundColor: '#5542A9',
  },
  // eslint-disable-next-line
  ['0x1964f2a636dd324d3e49dca28d5012b3b42c577d']: {
    name: 'Relayer v1.0.0',
    backgroundColor: '#5542A9',
  },
  // eslint-disable-next-line
  ['0xd7252c026c3ca28d73b4deef62fe6ade86ec17a9']: {
    name: 'Relayer v1.1.0',
    backgroundColor: '#5542A9',
  },
  ['0x54fc6e302043aaf56154e8b4a7f01645edada906']: {
    name: 'Relayer v2.0.0',
    backgroundColor: '#5542A9',
  },
  // eslint-disable-next-line
  ['0x9e0a538749a486bbf127f8848a6f2cf4e1e92dbd']: {
    name: 'Relayer v3.0.0',
    backgroundColor: '#5542A9',
  },
}

export const RELAYER = '0x9E0A538749A486bbF127F8848a6f2CF4e1e92DbD'
