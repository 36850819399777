import moment from 'moment'
import { AdminNotificationResponse } from '../../../types/API'
import { Card, CloseButton, Description, Footer, Header, SeverityPill, Timestamp, Title } from './styles'

interface Props {
  notification: AdminNotificationResponse
  onDismiss: (notificationId: string) => void
}

const DATE_FORMAT = 'MMMM DD, YYYY [at] HH:mm:ss'

function NotificationCard({ notification: { _id, title, details, createdAt, severity }, onDismiss }: Props) {
  return (
    <Card>
      <Header>
        <Title>{title}</Title>
        <CloseButton onClick={() => onDismiss(_id)}>x</CloseButton>
      </Header>
      <Description>{details}</Description>
      <Footer>
        <Timestamp>{moment(createdAt).format(DATE_FORMAT)}</Timestamp>
        <SeverityPill severity={severity}>{severity}</SeverityPill>
      </Footer>
    </Card>
  )
}

export default NotificationCard
