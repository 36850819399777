import { AdminNotificationResponse, SeverityLevel } from '../types/API'
import { useInfiniteQuery } from '@tanstack/react-query'
import useLoggedUser from './useLoggedUser'
import { useEffect, useMemo, useState } from 'react'
import { useApi } from './useApi'

export type AdminNotificationsParams = {
  startDate?: Date
  endDate?: Date
  severity?: SeverityLevel
  limit?: number
  page?: number
}

function useAdminNotifications(params: Omit<AdminNotificationsParams, 'page'> = {}) {
  const { callApi } = useApi()
  const { data: me } = useLoggedUser()

  const fetchNotifications = async ({ pageParam = 1 }): Promise<AdminNotificationResponse[]> => {
    const response = await callApi<AdminNotificationResponse[]>('/notifications/admin', { ...params, page: pageParam })
    return response || []
  }

  const { data, isLoading, error, refetch, fetchNextPage, isFetching } = useInfiniteQuery({
    queryKey: ['adminNotifications', ...Object.entries(params)],
    queryFn: fetchNotifications,
    getNextPageParam: (lastPage, allPages) => {
      return lastPage?.length === params.limit ? allPages.length + 1 : undefined
    },
    initialPageParam: 1,
    staleTime: 1000,
    enabled: !!me,
  })

  const notifications = useMemo(() => data?.pages.flat() || [], [data])
  const [areNewNotifications, setAreNewNotifications] = useState(false)

  const lastViewedNotificationKey = me ? `lastViewedNotification-${me._id}` : undefined
  const dismissiedNotificationKey = me ? `dismissedNotifications-${me._id}` : undefined

  const updateViewedNotifications = () => {
    if (lastViewedNotificationKey && notifications.length > 0) {
      const lastNotificationId = notifications[0]._id

      localStorage.setItem(lastViewedNotificationKey, lastNotificationId)
      setAreNewNotifications(false)
    }
  }

  const checkNewNotifications = () => {
    if (notifications.length > 0) {
      if (!lastViewedNotificationKey) {
        return true
      }
      const lastNotificationId = localStorage.getItem(lastViewedNotificationKey)
      return notifications[0]._id !== lastNotificationId
    }
    return false
  }

  const getDismissedNotifications = (): Set<string> => {
    if (dismissiedNotificationKey) {
      const dismissedNotifications = localStorage.getItem(dismissiedNotificationKey)
      if (dismissedNotifications) {
        return new Set(JSON.parse(dismissedNotifications))
      }
      return new Set()
    }
    return new Set()
  }

  const [dismissedNotifications, setDismissedNotifications] = useState<Set<string>>(new Set())

  useEffect(() => {
    setDismissedNotifications(getDismissedNotifications())
    setAreNewNotifications(checkNewNotifications())
  }, [notifications])

  const dismissNotification = (notificationId: string) => {
    if (dismissiedNotificationKey) {
      const dismissedNotifications = getDismissedNotifications()
      dismissedNotifications.add(notificationId)
      localStorage.setItem(dismissiedNotificationKey, JSON.stringify(Array.from(dismissedNotifications)))

      setDismissedNotifications(dismissedNotifications)
    }
  }

  const showNotificationsAgain = () => {
    if (dismissiedNotificationKey) {
      localStorage.removeItem(dismissiedNotificationKey)
    }
    setDismissedNotifications(new Set())
  }

  return {
    notifications,
    isLoading,
    isFetching,
    error,
    refetch,
    updateViewedNotifications,
    areNewNotifications,
    dismissNotification,
    dismissedNotifications,
    showNotificationsAgain,
    fetchNextPage,
  }
}

export default useAdminNotifications
