import styled from 'styled-components'

export const Form = styled.form`
  width: 1050px;
  @media only screen and (max-width: 700px) {
    width: 100%;
  }
  margin: 0 auto 50px 0;
  padding: 0;
  border-radius: 20px;
  height: auto;
  button {
    margin-top: 10px;
    margin-right: 20px;
  }
  .container {
    display: flex;
    flex-wrap: wrap;
  }
  input {
    @media only screen and (max-width: 700px) {
      width: 100%;
    }
  }
  .input-group {
    width: calc(33.33% - 10px);
    margin-right: 10px;
    margin-bottom: 10px;
    @media only screen and (max-width: 700px) {
      width: 80%;
    }
  }
`

export const NewForm = styled(Form)`
  width: 1100px;
  margin-top: 50px;
  text-align: left;
  overflow: hidden;
  width: 100%;
  padding: 0 20px;
  h2 {
    margin-top: 0 !important;
  }
  button {
    margin-right: 0;
  }
`
