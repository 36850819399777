import React, { useState } from 'react'
import axios from 'axios'
import moment from 'moment'
import Switch from '../../Switch'
import { ButtonViolet, Message, Group } from '../../../utils/styles'
import { NewForm } from '../../../utils/typescript-styles'
import { useApi } from '../../../hooks/useApi'

const URL = process.env.REACT_APP_SERVER_BASE_URL

interface EcoModeFormProps {
  onSuccess?: () => void
  chainId?: number
  smartVaults?: any
}

const EcoModeForm: React.FC<EcoModeFormProps> = ({ chainId, smartVaults, onSuccess = () => {} }) => {
  const filteredSmartVault = smartVaults.find((sv: any) => sv.chainId === chainId)
  const initialSmartVaultId = filteredSmartVault ? filteredSmartVault._id : ''
  const { callApi } = useApi()
  const [smartVault, setSmartVault] = useState(initialSmartVaultId)
  const [chainIdValue, setChainId] = useState(chainId || '')
  const [guardPeriod, setGuardPeriod] = useState('')
  const [smoothFactor, seSmoothFactor] = useState('')
  const [executions, setExecutions] = useState('')
  const [shouldAvoidIsolatedTask, setShouldAvoidIsolatedTask] = useState('')
  const [isActive, setIsActive] = useState(false)
  const [gasPriceMedianThreshold, setGasPriceMedianThreshold] = useState('')
  const [message, setMessage] = useState('')

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    try {
      const lastUpdate = moment().toISOString()
      const isActive = true

      await callApi<void>(
        '/relayer-executor/eco-modes/',
        {
          chainId: chainIdValue,
          smartVault: smartVault,
          active: isActive,
          safeGuardPeriodPct: parseFloat(guardPeriod),
          averageSuccessSpeedSmoothFactor: parseFloat(smoothFactor),
          maximumExecutionsPerPeriod: parseFloat(executions),
          isolatedTasksAvoidanceList: shouldAvoidIsolatedTask !== '' ? shouldAvoidIsolatedTask.split(',') : [],
          gasPriceMedianThreshold: parseFloat(gasPriceMedianThreshold),
        },
        'POST'
      )

      setMessage('The eco-mode has been successfully created')
      onSuccess()
    } catch (error) {
      console.error('Error:', error)
      if (axios.isAxiosError(error) && error.response) {
        setMessage(`Error: ${error.response.data.content.message}`)
      } else {
        setMessage('Error: An unexpected error occurred')
      }
    }
  }

  return (
    <NewForm onSubmit={handleFormSubmit}>
      {message !== '' ? (
        <Message>
          <span>{message}</span>
          <span className="close" onClick={() => setMessage('')}>
            X
          </span>
        </Message>
      ) : (
        <>
          <Group>
            <div>
              <label>Smart Vault id</label>
              <input type="text" value={smartVault} onChange={(e) => setSmartVault(e.target.value)} required />
            </div>
            <div>
              <label>Chain Id</label>
              <input type="number" value={chainIdValue} onChange={(e) => setChainId(e.target.value)} required />
            </div>
          </Group>
          <Group>
            <div>
              <label>Safe Guard Period Pct</label>
              <input
                type="number"
                value={guardPeriod}
                onChange={(e) => setGuardPeriod(e.target.value)}
                min="0"
                max="1"
                step="0.01"
                required
              />
            </div>
            <div>
              <label>Average Success Speed Smooth Factor</label>
              <input
                type="number"
                value={smoothFactor}
                onChange={(e) => seSmoothFactor(e.target.value)}
                min="0"
                max="1"
                step="0.01"
                required
              />
            </div>
          </Group>
          <Group>
            <div>
              <label>Maximum Executions Per Period</label>
              <input type="number" value={executions} onChange={(e) => setExecutions(e.target.value)} required />
            </div>
            <div>
              <label>Gas Price Median Threshold</label>
              <input
                type="number"
                value={gasPriceMedianThreshold}
                onChange={(e) => setGasPriceMedianThreshold(e.target.value)}
                min="0"
                max="1"
                step="0.01"
                required
              />
            </div>
          </Group>
          <Group>
            <div>
              <label>Should Avoid Isolated Task (comma-separated)</label>
              <input
                type="text"
                name="shouldAvoidIsolatedTask"
                value={shouldAvoidIsolatedTask}
                onChange={(e) => setShouldAvoidIsolatedTask(e.target.value)}
              />
            </div>
            <div>
              <label>Active</label>
              <Switch ison={isActive} onToggle={() => setIsActive(!isActive)} />
            </div>
          </Group>
          <ButtonViolet type="submit">New</ButtonViolet>
        </>
      )}
    </NewForm>
  )
}

export default EcoModeForm
