import { useQuery } from '@tanstack/react-query'
import { QueueExecutionsResponse } from 'API'
import { useApi } from './useApi'

export interface QueueExecutionsFilters {
  mimicId?: string
  chainId?: number
  status?: string
  order?: 'asc' | 'desc'
  limit?: number
  page?: number
}

const useQueueExecutions = (params: QueueExecutionsFilters) => {
  const { callApi } = useApi()

  const fetchQueueExecutions = async (): Promise<QueueExecutionsResponse[]> => {
    try {
      return await callApi<QueueExecutionsResponse[]>('/relayer-executor/queue-executions', params)
    } catch (error) {
      console.error('Error fetching queue executions:', error)
      throw error
    }
  }

  return useQuery<QueueExecutionsResponse[]>({
    queryKey: ['queueExecutions', ...Object.values(params)],
    queryFn: fetchQueueExecutions,
  })
}

export default useQueueExecutions
