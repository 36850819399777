import styled from 'styled-components'
import { QueueExecutionsResponse } from '../../types/API'
import { ContainerTable, StickyTable } from '../../utils/styles'
import Address from '../general/Address'
import Network from '../general/Network'
import { capitalizeFirstLetter } from '../../utils/math-utils'
import moment from 'moment'

interface Props {
  data?: QueueExecutionsResponse[]
}

const DATE_FORMAT = 'MMMM DD, YYYY [at] HH:mm:ss'

function QueueExecutionsTable({ data }: Props) {
  return (
    <StickyTable>
      <Table>
        <thead>
          <tr>
            <th>Namespace</th>
            <th>Mimic ID</th>
            <th>Network</th>
            <th>Status</th>
            <th>Allowed At</th>
            <th>Started At</th>
            <th>Finished At</th>
            <th>Pod Job ID</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item, index) => (
            <tr key={`queue-execution-${index}`}>
              <td className="accent">{item.environment.namespace}</td>
              <td>
                <Address address={item.environment.mimicId} short={true} showIdentity={false} withLink={false} />
              </td>
              <td>
                <Network network={item.chainId} />
              </td>
              <td>{capitalizeFirstLetter(item.status)}</td>
              <td>{moment(item.allowedAt).format(DATE_FORMAT)}</td>
              <td>{moment(item.startedAt).format(DATE_FORMAT)}</td>
              <td>{moment(item.finishedAt).format(DATE_FORMAT)}</td>
              <td>{item.podJobId}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </StickyTable>
  )
}

const Table = styled(ContainerTable)`
  overflow-x: auto;
  max-width: 100%;
  position: relative;

  .accent {
    color: #33c2b0;
    font-wight: bold;
    font-family: 'DMSansBold';
  }
  tbody {
    tr {
      cursor: pointer;
    }
  }
`

export default QueueExecutionsTable
