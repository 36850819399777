import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import axios from 'axios'
import { ScrollTable, ContainerTable } from '../../../utils/styles'
import { useApi } from '../../../hooks/useApi'
import { ButtonViolet, Loader } from '../../../utils/styles'

interface LogGroups {
  creationTime: number
  arn: string
  logGroupClass: string
  logGroupName: string
  metricFilterCount: number
}
interface Data {
  logGroups: LogGroups[]
  nextToken: string
}

const URL = process.env.REACT_APP_SERVER_BASE_URL

const LogsStreams: React.FC = () => {
  const { callApi } = useApi()
  const navigate = useNavigate()
  const location = useLocation()
  const [logsData, setLogsStreams] = useState<LogGroups[] | null>(null)
  const [next, setNext] = useState<string | null>(null)
  const [button, setButton] = useState({ disabled: false, message: 'See more' })

  const fetchData = async () => {
    try {
      const response = await callApi('/admin/logs')
      setLogsStreams(response.logGroups)
      setNext(response.nextToken || null)
    } catch (error: any) {
      console.error('Token list error:', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handleRowClick = (logGroupName: string) => {
    navigate(`${location.pathname}/groups?name=${logGroupName}`, {
      replace: true,
    })
  }

  const handleSeeMore = async () => {
    try {
      const response = await callApi('/admin/logs', { nextToken: next })
      if (response.logGroups && response.logGroups.length > 0) {
        setLogsStreams(logsData ? [...logsData, ...(response.logGroups ?? [])] : response.logGroups)
        setNext(response.nextToken || null)
      } else if (response?.logGroups?.length === 0) {
        setButton({ disabled: true, message: 'No more logs' })
      }
    } catch (error) {
      console.error('There was an error with the web3 form:', error)
    }
  }

  return (
    <div>
      {logsData ? (
        <>
          <ScrollTable>
            <ContainerTable>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Arn</th>
                  <th>Created at</th>
                  <th>Class</th>
                  <th>Metric filter Count</th>
                </tr>
              </thead>
              <tbody>
                {logsData.map((item, index) => (
                  <tr key={index} onClick={() => handleRowClick(item.logGroupName)}>
                    <td className="accent">{item.logGroupName}</td>
                    <td>{item.arn}</td>
                    <td>{new Date(item.creationTime).toLocaleString()}</td>
                    <td>{item.logGroupClass}</td>
                    <td>{item.metricFilterCount}</td>
                  </tr>
                ))}
              </tbody>
            </ContainerTable>
          </ScrollTable>
          {next && (
            <ButtonViolet onClick={handleSeeMore} disabled={button.disabled}>
              {button.message}
            </ButtonViolet>
          )}
        </>
      ) : (
        <Loader />
      )}
    </div>
  )
}

export default LogsStreams
