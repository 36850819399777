import { useQuery } from '@tanstack/react-query'
import { EcoModesResponse } from 'API'
import { useApi } from './useApi'

function useEcoModes(chainId: number, id: string) {
  const { callApi } = useApi()

  const fetchNetworkInfo = async (): Promise<EcoModesResponse[]> => {
    return await callApi<EcoModesResponse[]>(`/relayer-executor/environments/${id}/eco-modes`, {
      chainId: chainId,
    })
  }

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ['useEcoModes', id + chainId + 'eco'],
    queryFn: fetchNetworkInfo,
  })
  return { data, isLoading, error, refetch }
}

export default useEcoModes
