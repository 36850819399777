// @ts-nocheck
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import axios from 'axios'
import toast, { Toaster } from 'react-hot-toast'
import { ScrollTable, ContainerTable, H2 } from '../../../utils/styles'
import { formatTokenAmount } from '../../../utils/math-utils'
import { compareAddresses } from '../../../utils/web3-utils'
import { RELAYER } from '../../../constants/playerAddress'
import useSmartVault from '../../../hooks/useSmartVault'
import useBotBalance from '../../../hooks/useBotBalance'
import { useApi } from '../../../hooks/useApi'
import useEcoModes from '../../../hooks/useEcoModes'
import Address from '../../general/Address'
import EcoModes from './EcoModes'
import MiniSwitch from '../../formUtils/MiniSwitch'
import CustomConfirmationModal from '../../CustomConfirmationModal'
import useLogs from '../../../hooks/useLogs'
import ChainHeader from './ChainHeader'

const URL = process.env.REACT_APP_SERVER_BASE_URL

const Settings: React.FC<{
  chainId: number
  environment: any
  chainInfo: any
}> = ({ chainId, environment, chainInfo }) => {
  const { callApi } = useApi()
  const params = useParams()
  const smartVault = useSmartVault(params.id!, chainId)
  const { data: balance, isLoading: balanceLoading, error: balanceError } = useBotBalance(chainId)
  const ecoModes = useEcoModes(chainId, params.id)
  const [executorModalOpen, setExecutorModalOpen] = useState(false)
  const [executorParam, setExecutorParam] = useState<any>(null)

  const logsSimulated = useLogs(
    params.id,
    1,
    1,
    {
      chainId: chainId,
      status: ['notSimulated', 'simulationFailed', 'simulationReverted', 'simulationSucceeded', 'executionDelayed'],
    },
    0
  )
  const logsExecuted = useLogs(
    params.id,
    1,
    1,
    {
      chainId: chainId,
      status: ['executionSucceeded', 'executionReverted', 'executionNotReached'],
    },
    0
  )

  const handleMonitor = async (item: any) => {
    try {
      const sendData = {
        chainId: parseInt(chainId),
        monitor: item.id,
      }
      await callApi('/jobs/monitor', sendData, 'POST')
    } catch (error: any) {
      console.error('There was an error triggering the monitor job:', error)
    }
  }

  const handleExecutorClick = (item: any) => {
    setExecutorParam({ ...item, token: null })
    setExecutorModalOpen(true)
  }

  const handleCancelJob = () => {
    setExecutorParam(null)
    setExecutorModalOpen(false)
  }

  const handleExecutor = async () => {
    try {
      const sendData = {
        chainId: parseInt(chainId),
        smartVault: executorParam.id,
        ...(executorParam.token && { tokens: executorParam.token.split(/,\s*/) }),
      }
      await callApi('/jobs/executor', sendData, 'POST')
      setExecutorParam(null)
      setExecutorModalOpen(false)
    } catch (error: any) {
      setExecutorParam(null)
      setExecutorModalOpen(false)
      console.error('There was an error triggering the executor job:', error)
    }
  }

  return (
    <div>
      <Toaster position="top-right" />
      <br />
      <br />
      <H2>Smart Vault</H2>
      <ScrollTable>
        <ContainerTable className="full-width">
          <thead>
            <tr>
              <th>Name</th>
              <th>Address</th>
              <th>Id</th>
              <th>Version</th>
              <th>Relayer deposit</th>
              <th>Relayer transactions</th>
              <th>Last simulation</th>
              <th>Last execution</th>
              <th>Denied</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {smartVault?.data?.smartVaults?.map((item, index) => {
              const relayerConfigs = item.relayerConfigs || []
              const lastRelayerConfig = relayerConfigs.find((item) => compareAddresses(item.relayer.id, RELAYER))
              return (
                <tr key={index}>
                  <td>{item.name}</td>
                  <td>
                    <Address address={item.id} short={true} />
                  </td>
                  <td>{environment?.smartVaults?.find((vault) => vault.chainId === chainId)?._id}</td>
                  <td>{item.implementation?.name}</td>
                  <td>
                    {formatTokenAmount(lastRelayerConfig?.balance, lastRelayerConfig?.nativeToken?.decimals, {
                      digits: 2,
                    })}{' '}
                    {lastRelayerConfig?.nativeToken?.symbol}
                  </td>
                  <td>
                    {(
                      (formatTokenAmount(lastRelayerConfig?.balance, lastRelayerConfig?.nativeToken?.decimals, {
                        digits: 2,
                      }) *
                        balance?.price) /
                      chainInfo?.averageTxCost
                    ).toFixed(2)}
                  </td>
                  <td>
                    {!logsSimulated.isLoading &&
                      logsSimulated.data?.data &&
                      logsSimulated.data.data.length > 0 &&
                      moment(logsSimulated.data.data[0]?.executedAt).fromNow()}
                  </td>
                  <td>
                    {!logsExecuted.isLoading &&
                      logsExecuted.data?.data &&
                      logsExecuted.data.data.length > 0 &&
                      moment(logsExecuted.data.data[0]?.executedAt).fromNow()}
                  </td>
                  <td>
                    <Denied
                      smartVaultId={environment?.smartVaults?.find((vault) => vault.chainId === chainId)?._id || null}
                    />
                  </td>
                  <td>
                    <button className="violet" onClick={() => handleMonitor(item)}>
                      Monitor
                    </button>
                    <button className="violet" onClick={() => handleExecutorClick(item)}>
                      Executor
                    </button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </ContainerTable>
      </ScrollTable>
      {executorModalOpen && (
        <CustomConfirmationModal
          message="Are you sure you want to trigger this job?"
          onConfirm={handleExecutor}
          onCancel={handleCancelJob}
          cancelName="Cancel Job"
          confirmName="Trigger"
        >
          <div>Do you want to add some tokens? (comma-separated)</div>
          <input
            type="text"
            value={executorParam.token}
            onChange={(e) => setExecutorParam({ ...executorParam, token: e.target.value })}
          />
        </CustomConfirmationModal>
      )}
      <br />
      <br />
      <br />
      <EcoModes chainId={chainId} smartVaults={environment?.smartVaults} />
      <br />
      <br />
      <br />
      <ChainHeader envId={params.id} chainId={chainId} />
      <H2>Nonce</H2>
      <ContainerTable className="full-width">
        <thead>
          <tr>
            <th>Local</th>
            <th>On Chain</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {chainInfo?.nonces ? Object.values(chainInfo.nonces).reduce((acc, { local }) => acc + local, 0) : '-'}
            </td>
            <td>
              {chainInfo?.nonces ? Object.values(chainInfo.nonces).reduce((acc, { onChain }) => acc + onChain, 0) : '-'}
            </td>
          </tr>
        </tbody>
      </ContainerTable>
    </div>
  )
}

function Denied({ smartVaultId }: { smartVaultId: string }) {
  const { callApi } = useApi()
  const [smartVaultIsDenied, setSmartVaultIsDenied] = useState(false)
  const [customModalOpen, setCustomModalOpen] = useState(false)
  const [deniedData, setDeniedData] = useState(null)

  const fetchDenied = async () => {
    try {
      const response = await callApi(`/relayer-executor/smart-vaults/${smartVaultId}`)
      setDeniedData(response)
    } catch (error) {}
  }

  const handleDeniedClick = () => {
    setCustomModalOpen(true)
  }

  const handleCancelDenied = () => {
    setCustomModalOpen(false)
  }

  const handleConfirmDenied = async () => {
    try {
      const response = await callApi(
        `/relayer-executor/smart-vaults/${smartVaultId}`,
        { ...deniedData, denied: !deniedData.denied },
        'PUT'
      )
      fetchDenied()
    } catch (error) {}

    setCustomModalOpen(false)
  }

  useEffect(() => {
    fetchDenied()
  }, [])

  return (
    <>
      <MiniSwitch ison={deniedData?.denied} onToggle={() => handleDeniedClick()} />
      {customModalOpen && (
        <CustomConfirmationModal
          message={
            'Are you sure you want to ' +
            (deniedData?.denied === true ? 'delete this smartVault from the denied list?' : 'deny this smartVault?')
          }
          onConfirm={handleConfirmDenied}
          onCancel={handleCancelDenied}
        />
      )}
    </>
  )
}

export default Settings
