import { useRef, useEffect } from 'react'
import axios from 'axios'
import { useAuth } from '../context/AuthContext'
import toast from 'react-hot-toast'

const BASE_URL = process.env.REACT_APP_SERVER_BASE_URL

export const useApi = () => {
  const { user, refreshToken, logout } = useAuth()
  let failedAttempts = 0

  const userRef = useRef(user)

  // Mantener el valor más reciente de `user` en el ref
  useEffect(() => {
    userRef.current = user
  }, [user])

  const callApi = async (endpoint, params = {}, method = 'GET', message = null) => {
    try {
      const headers = {
        'Content-Type': 'application/json',
        'x-auth-token': userRef.current?.token,
      }

      const response = await axios.request({
        url: `${BASE_URL}${endpoint}`,
        method,
        headers,
        ...(method === 'GET' ? { params } : { data: params }),
      })
      handleSuccessMessages(method, message)
      failedAttempts = 0

      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const status = error.response?.status
        const errorMessage = error.response?.data?.message || error.message

        if (status === 401) {
          failedAttempts += 1

          if (failedAttempts >= 3) {
            toast.error(`401 - Too many failed attempts. Logging out.`)
            logout()
            return null
          }

          const tokenRefreshed = await refreshToken()
          if (tokenRefreshed) {
            return await callApi(endpoint, params, method)
          } else {
            toast.error('Session expired. Please log in again.')
            logout()
            return null
          }
        } else if (status === 403) {
          toast.error(`403 - Access Denied: You don't have the necessary permissions.`)
        } else {
          toast.error(`Error ${status || 'unknown'}: ${errorMessage}`)
        }
      } else {
        console.error('Error in API call:', error)
        toast.error('❗️An unexpected error occurred')
      }

      return
    }
  }

  const handleSuccessMessages = (method: string, message: string | null) => {
    if (!message || message !== 'noMessage') {
      switch (method) {
        case 'PUT':
          toast.success('Your changes were successfully saved')
          break
        case 'POST':
          toast.success('Your item was successfully created')
          break
        case 'DELETE':
          toast.success('Your item was successfully deleted')
          break
        default:
          break
      }
    }
  }

  return { callApi }
}
