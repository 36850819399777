import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import moment from 'moment'
import { ScrollTable, ContainerTable, Flexbox, FlexRow, H2 } from '../../../utils/styles'
import useNetworkHeader from '../../../hooks/useNetworkHeader'
import useSubgraphs from '../../../hooks/useSubgraphs'
import useCrons from '../../../hooks/useCrons'
import { formatDuration } from '../../../hooks/useCrons'
import { cronNames } from '../../../constants/cronNames'
import { CronStatusResponse } from '../../../types/API'

interface Props {
  envId: string
  chainId: number
}

export default function ChainHeader({ envId, chainId }: Props) {
  const network = useNetworkHeader(envId, String(chainId))
  const subgraphs = useSubgraphs()

  const { data: crons, isLoading } = useCrons()
  let cronName = cronNames[chainId] || ''
  let cronSelection = crons?.filter((objet) => objet.name.toLowerCase()?.includes(cronName))

  function formatName(itemName: string, cronName: string) {
    const replacedName = itemName.replace(cronName, '').replace(/-+$/, '')
    const formattedName = replacedName.replace(/-/g, '_').replace(/_./g, (match) => match.toUpperCase()[1])
    return formattedName
  }

  useEffect(() => {
    cronName = cronNames[chainId] || ''
    cronSelection = crons?.filter((objet) => objet.name.toLowerCase()?.includes(cronName))
  }, [chainId])

  const subgraph = subgraphs && subgraphs?.data?.filter((item) => item.chainId === chainId.toString())

  function handleJob(item: CronStatusResponse): void {
    // TODO: handle job
  }

  return (
    <>
      <H2>Chain</H2>
      <ScrollTable>
        <ContainerTable>
          <thead>
            <tr>
              <th>Chain</th>
              <th>Denied</th>
              <th>TX Delay</th>
              <th>TX EXPIRATIONS</th>
              <th>Missing Tx Timeout</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{chainId}</td>
              <td>
                <Flexbox>
                  {network && network.data && network.data.denied === false && <Chip>Not denied</Chip>}
                  {network && network.data && network.data.denied === true && <Chip className="alert">Denied</Chip>}
                </Flexbox>
              </td>
              <td>{network && network.data && network.data.transactionDelaySeconds + ' sec'}</td>
              <td>{network && network.data && (network.data.transactionsExpirationSeconds || '-') + ' sec'}</td>
              <td>{network && network.data && (network.data.missingTxMinutesTimeout || '-') + ' min'}</td>
            </tr>
          </tbody>
        </ContainerTable>
      </ScrollTable>
      <H2>Subgraph</H2>
      <ScrollTable>
        <ContainerTable className="left">
          <thead>
            <tr>
              <th>Url</th>
              <th>Subgraph</th>
              <th>Synced</th>
              <th>Fatal Error</th>
              <th>Non Fatal Errors</th>
            </tr>
          </thead>
          <tbody>
            {subgraph?.map((item, index) => (
              <tr key={index}>
                <td>{network && network.data && network.data.subgraphUrl}</td>
                <td>{item.subgraph}</td>
                <td>{item.synced ? '🟢' : '🔴'}</td>
                <td>{item.fatalError ? item.fatalError.message : '-'}</td>
                <td>{item.nonFatalErrors?.map(({ message }, key) => <p key={key}>{message}</p>)}</td>
              </tr>
            ))}
          </tbody>
        </ContainerTable>
      </ScrollTable>
      {!isLoading && cronSelection && cronSelection.length > 0 && (
        <>
          <H2>Cron</H2>
          <ScrollTable>
            <ContainerTable>
              <thead>
                <tr>
                  <th>Cron</th>
                  <th>Duration</th>
                  <th>Last start Time</th>
                  <th>Last Compl. Time</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {cronSelection.map((item, index) => (
                  <tr key={index}>
                    <td className="accent">{item.name}</td>
                    <td>
                      {formatDuration(
                        moment.duration(moment(item.lastCompletionTime).diff(moment(item.lastStartTime)))
                      )}
                      {moment.duration(moment(item.lastCompletionTime).diff(moment(item.lastStartTime))).asMinutes() <
                        2 && item.active !== true ? (
                        <> 🚨</>
                      ) : (
                        ''
                      )}
                    </td>
                    <td>{moment(item.lastStartTime).format('DD/MM/YY HH:mm[hs]')}</td>
                    <td>
                      {item.active === true
                        ? 'Running 🏃‍♂️'
                        : moment(item.lastCompletionTime).format('DD/MM/YY HH:mm[hs]')}
                    </td>
                    <td>
                      <FlexRow>
                        <Link
                          to={'./dashboard/settings/logs/groups?name=' + formatName(item.name, cronName)}
                          className="violet"
                          target="_blank"
                        >
                          Logs
                        </Link>
                      </FlexRow>
                    </td>
                  </tr>
                ))}
                <tr>
                  <td></td>
                </tr>
              </tbody>
            </ContainerTable>
          </ScrollTable>
        </>
      )}
    </>
  )
}

const Chip = styled.div`
  border-radius: 20px;
  background: #0eb3a1;
  padding: 4px 14px;
  &.warning {
    background: #fcce14;
  }
  &.alert {
    background: #eb2f71;
  }
`
