import { useEffect, useState } from 'react'
import useQueueExecutions, { QueueExecutionsFilters } from '../hooks/useQueueExecutions'
import { H1, LittleButton, Loader, Section } from '../utils/styles'
import QueueFilters from '../components/queueExecutions/QueueFilters'
import QueueExecutionsTable from '../components/queueExecutions/QueueExecutionsTable'
import toast, { Toaster } from 'react-hot-toast'
import styled from 'styled-components'

const LIMIT = 20

function QueueExecutions() {
  const [queueExecutionFilters, setQueueExecutionFilters] = useState<QueueExecutionsFilters>({ limit: LIMIT })
  const { data, isLoading, error } = useQueueExecutions(queueExecutionFilters)

  const handleSetFilters = (filters: QueueExecutionsFilters) => {
    setQueueExecutionFilters((prevFilters) => ({ ...prevFilters, ...filters, limit: LIMIT, page: undefined }))
  }

  const handlePageChange = (newPage: number) => {
    setQueueExecutionFilters((prevFilters) => ({ ...prevFilters, page: newPage }))
  }

  useEffect(() => {
    if (error) {
      toast('There was an error fetching queue executions')
      console.error(error)
    }
  }, [error])

  return (
    <Section>
      <Toaster position="top-right" />
      <H1>Queue Executions</H1>
      <QueueFilters filters={queueExecutionFilters} setFilters={handleSetFilters} />
      {isLoading ? <Loader /> : <QueueExecutionsTable data={data} />}
      <Pagination>
        <LittleButton
          onClick={() => handlePageChange((queueExecutionFilters.page ?? 0) - 1)}
          disabled={(queueExecutionFilters.page ?? 0) === 0}
        >
          Previous
        </LittleButton>
        <span>Page {(queueExecutionFilters.page ?? 0) + 1}</span>
        <LittleButton
          onClick={() => handlePageChange((queueExecutionFilters.page ?? 0) + 1)}
          disabled={(data?.length ?? -1) < LIMIT}
        >
          Next
        </LittleButton>
      </Pagination>
    </Section>
  )
}

const Pagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  button {
    margin-top: 0;
  }
  p {
    margin: 0 20px;
  }
`

export default QueueExecutions
