import { useQuery } from '@tanstack/react-query'
import { VolumeResponse } from '../types/API'
import { useApi } from './useApi'

const useVolume = (
  id: string | undefined,
  startDate: Date | null,
  endDate: Date | null,
  tasks: string[],
  chainId: string
) => {
  const { callApi } = useApi()

  const fetchVolume = async (): Promise<VolumeResponse> => {
    const params = {
      chainIds: [chainId],
      startDate: startDate?.toISOString(),
      endDate: endDate?.toISOString(),
      tasks,
    }

    return await callApi<VolumeResponse>(`/relayer-executor/environments/${id}/volume`, params)
  }

  return useQuery<VolumeResponse>({
    queryKey: ['volume', id, startDate, endDate, tasks, chainId],
    queryFn: fetchVolume,
    enabled: !!id && !!startDate && !!endDate,
  })
}

export default useVolume
