import { useQuery } from '@tanstack/react-query'
import { useApi } from './useApi'
import { UserResponse } from '../types/API'

const useLoggedUser = () => {
  const { callApi } = useApi()

  const fetchMe = async (): Promise<UserResponse | undefined> => {
    const url = '/admin/users/me'
    return await callApi<UserResponse>(url)
  }

  return useQuery<UserResponse>({
    queryKey: ['me'],
    queryFn: fetchMe,
  })
}

export default useLoggedUser
