import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import axios from 'axios'
import styled from 'styled-components'
import { ContainerTable, Loader } from '../../../utils/styles'
import { useApi } from '../../../hooks/useApi'
import { ButtonViolet } from '../../../utils/styles'
import Breadcrumbs from '../../structure/Breadcrumbs'

interface LogGroups {
  creationTime: number
  arn: string
  firstEventTimestamp: number
  lastEventTimestamp: number
  lastIngestionTime: number
  logStreamName: string
  uploadSequenceToken: string
  storedBytes: number
}
interface Data {
  logStreams: LogGroups[]
  nextToken: string
}

const URL = process.env.REACT_APP_SERVER_BASE_URL

const LogsStreams: React.FC = () => {
  const { callApi } = useApi()
  const navigate = useNavigate()
  const location = useLocation()
  const [next, setNext] = useState<string | null>(null)
  const name = window.location.href.split('/groups?name=')
  const [logsGroups, setLogsStreams] = useState<LogGroups[] | null>(null)
  const [button, setButton] = useState({ disabled: false, message: 'See more' })

  const fetchData = async () => {
    try {
      const response = await callApi(`/admin/logs/${name[1]}`)
      if (response) {
        setLogsStreams(response?.logStreams)
        setNext(response?.nextToken || null)
      }
    } catch (error) {
      console.error('Error in fetchData:', error)
    }
  }

  const handleSeeMore = async () => {
    try {
      const response = await callApi(`/admin/logs/${name[1]}`, { nextToken: next })

      if (response?.logStreams && response?.logStreams.length > 0) {
        setLogsStreams(logsGroups ? logsGroups.concat(response.logStreams) : null)
      } else if (response?.logStreams?.length === 0) {
        setButton({ disabled: true, message: 'No more logs' })
      }
    } catch (error) {
      console.error('There was an error with the web3 form:', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    const handleBack = (e: any) => {
      e.preventDefault()
      navigate('/dashboard/settings/logs')
    }

    window.addEventListener('popstate', handleBack)

    return () => {
      window.removeEventListener('popstate', handleBack)
    }
  }, [navigate])

  const handleRowClick = (id: string) => {
    navigate(`${location.pathname.split('logs/groups')[0]}logs/${id}/groups?name=${name[1]}`, {
      replace: true,
    })
  }
  const paths = [
    { name: 'Logs', url: '/dashboard/settings/logs' },
    { name: name[1], url: '' },
  ]

  return (
    <EcoModeSection>
      <br />
      <Breadcrumbs paths={paths} />
      {logsGroups ? (
        <>
          <ContainerTable>
            <thead>
              <tr>
                <th>Name</th>
              </tr>
            </thead>
            <tbody>
              {logsGroups.map((item, index) => (
                <tr key={index} onClick={() => handleRowClick(item?.logStreamName)}>
                  <td>{item?.logStreamName}</td>
                </tr>
              ))}
            </tbody>
          </ContainerTable>
          {next && (
            <ButtonViolet onClick={handleSeeMore} disabled={button.disabled}>
              {button.message}
            </ButtonViolet>
          )}
        </>
      ) : (
        <Loader />
      )}
    </EcoModeSection>
  )
}

const EcoModeSection = styled.div`
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 874px;
  max-width: 90%;
  td {
    cursor: pointer;
  }
  button:disabled,
  button[disabled] {
    background-color: #cccccc;
    color: #666666;
  }
`

export default LogsStreams
