import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { TokenResponse } from '../types/API'
import { useApi } from './useApi'

export type ManyTokenFilters = {
  chainId?: number
  addresses?: string[]
  symbol?: string
  name?: string
  spamCounterLimit?: number
  enabled?: boolean
  isNativeToken?: boolean
  isWrappedNativeToken?: boolean
}

const useManyTokenInfo = (
  tokenList: string[],
  filter: ManyTokenFilters,
  limit: number = 50,
  setLoadPage: (page: number) => void,
  setTotalPages: (pages: number) => void
): UseQueryResult<TokenResponse['data']> => {
  const { callApi } = useApi()

  const fetchAllTokens = async (): Promise<TokenResponse['data']> => {
    let allData: TokenResponse['data'] = {}

    // Function to split the tokenList into chunks of a given size
    const chunkArray = (array: string[], size: number): string[][] => {
      return array.reduce((acc, _, i) => (i % size ? acc : [...acc, array.slice(i, i + size)]), [] as string[][])
    }

    // Split the tokenList into chunks
    const tokenChunks = chunkArray(tokenList, limit)

    setTotalPages(tokenChunks.length)
    for (let i = 0; i < tokenChunks.length; i++) {
      const chunk = tokenChunks[i]

      try {
        const data = await fetchTokenInfo(chunk, filter, limit, callApi)
        setLoadPage(i + 1)
        if (data) {
          allData = { ...allData, ...data }
        }
      } catch (error) {
        console.error('Error fetching data for chunk', i, error)
      }
    }

    return allData
  }

  return useQuery<TokenResponse['data']>({
    queryKey: ['manyTokens', tokenList?.toString(), filter.chainId],
    queryFn: fetchAllTokens,
    enabled: tokenList?.length > 0,
  })
}

const fetchTokenInfo = async (
  tokenList: string[],
  filter: ManyTokenFilters,
  limit: number,
  callApi: (url: string, params: any) => Promise<TokenResponse['data']>
): Promise<TokenResponse['data']> => {
  if (!tokenList) return {}

  const params = {
    addresses: tokenList,
    limit,
    ...filter,
  }

  try {
    const data = await callApi('/token-registry/tokens', params)
    return data || {}
  } catch (error) {
    console.error('Error fetching token info:', error)
    throw error
  }
}

export default useManyTokenInfo
