import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import List from '../components/tokens/List'
import Registry from '../components/tokens/Registry'
import { Tab, H1, Section } from '../utils/styles'

export default function Tabs() {
  const [activeTab, setActiveTab] = useState('registry')
  const navigate = useNavigate()
  const { tab } = useParams()

  useEffect(() => {
    if (tab) {
      setActiveTab(tab)
    }
  }, [tab])

  const handleTabClick = (tab: string) => {
    setActiveTab(tab)
    navigate(`/dashboard/tokens/${tab}`)
  }

  return (
    <Section>
      <H1>Tokens</H1>
      <Tab>
        <button onClick={() => handleTabClick('registry')} className={activeTab === 'registry' ? 'active' : ''}>
          Registry
        </button>
        <button onClick={() => handleTabClick('list')} className={activeTab === 'list' ? 'active' : ''}>
          List
        </button>
      </Tab>
      <div>
        {activeTab === 'registry' && <Registry />}
        {activeTab === 'list' && <List />}
      </div>
    </Section>
  )
}
