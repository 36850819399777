import React, { useEffect, useState } from 'react'
import axios from 'axios'
import moment from 'moment'
import { ScrollTable, ContainerTable, Loader } from '../../utils/styles'
import { useApi } from '../../hooks/useApi'

interface Status {
  app: string
  name: string
  status: string
  startTime: any
  ip: string
  containerName: string
  containerImage: string
  containerPorts: [string]
}

const URL = process.env.REACT_APP_SERVER_BASE_URL

const StatusRelayer: React.FC = () => {
  const { callApi } = useApi()
  const [data, setData] = useState<Status[] | null>(null)

  const fetchData = async () => {
    try {
      const response = await callApi('/admin/pods')
      setData(response)
    } catch (error) {
      console.error('There was an error loading the data:', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div>
      {data ? (
        <>
          <ScrollTable>
            <ContainerTable>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>App</th>
                  <th>Status</th>
                  <th>Start Time</th>
                  <th>IP</th>
                  <th>Name</th>
                  <th>Image</th>
                  <th>Ports</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item, index) => (
                  <tr key={index}>
                    <td className="accent">{item.name}</td>
                    <td>{item.app}</td>
                    <td>
                      {item.status === 'Running' && ' 🏃‍♂️'}
                      {item.status !== 'Running' && item.status !== 'Succeeded' && '❗️'}
                      {item.status === 'Succeeded' ? '🟢' : item.status}
                    </td>
                    <td>{moment(item.startTime).format('DD/MM/YY HH:mm[hs]')}</td>
                    <td>{item.ip}</td>
                    <td>{item.containerName}</td>
                    <td>{item.containerImage}</td>
                    <td>{item.containerPorts?.join(', ')}</td>
                  </tr>
                ))}
              </tbody>
            </ContainerTable>
          </ScrollTable>
        </>
      ) : (
        <Loader />
      )}
    </div>
  )
}

export default StatusRelayer
