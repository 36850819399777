import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { ScrollTable, ContainerTable, Flexbox, Loader, Right, ButtonViolet } from '../../../utils/styles'
import CustomConfirmationModal from '../../../components/CustomConfirmationModal'
import RpcsForm from './RpcsForm'
import FormModal from '../../FormModal'
import MiniSwitch from '../../formUtils/MiniSwitch'
import deleteIcon from '../../../assets/delete.png'
import { useApi } from '../../../hooks/useApi'
import Network from '../../general/Network'
import edit from '../../../assets/edit.png'
import save from '../../../assets/save.png'
import cancel from '../../../assets/cancel.png'
import toast from 'react-hot-toast'

interface Data {
  _id: string
  active: boolean
  chainId: number
  score: number
  createdAt: string
  updatedAt: string
  type: string
  url: string
}

const URL = process.env.REACT_APP_SERVER_BASE_URL

const Rpcs: React.FC = () => {
  const { callApi } = useApi()
  const [data, setData] = useState<Data[] | null>(null)
  const [deleteParams, setDeleteParams] = useState<any | null>(null)
  const [editItem, setEditItem] = useState<Data | any>(null)
  const [editIndex, setEditIndex] = useState<number | any>(null)
  const [editedProps, setEditedProps] = useState<Data | any>(null)
  const [addNew, setAddNew] = useState(false)

  const compareObjects = (a: Data, b: Data): number => {
    if (a.chainId !== b.chainId) {
      return a.chainId - b.chainId
    }

    if (a.type !== b.type) {
      return a.type.localeCompare(b.type)
    }

    return a.active === b.active ? 0 : a.active ? 1 : -1
  }

  const fetchData = async () => {
    try {
      const response = await callApi<Data[]>('/web3/rpc-endpoints')
      if (response) {
        response.sort(compareObjects)
        setData(response)
      }
    } catch (error) {
      console.error('Rpc data error:', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handleDeleteClick = (item: any) => {
    setDeleteParams(item)
  }

  const handleConfirmDelete = async () => {
    if (deleteParams !== null) {
      try {
        const endpoint = `/web3/rpc-endpoints/${deleteParams._id}`
        await callApi(endpoint, {}, 'DELETE')
        fetchData()
      } catch (error) {
        console.error('There was an error deleting the network:', error)
      }

      setDeleteParams(null)
    }
  }

  const handleCancelDelete = () => {
    setDeleteParams(null)
  }

  const handleSuccess = () => {
    fetchData()
    setAddNew(false)
  }

  const handleEdit = (item: Data, index: number) => {
    setEditIndex(index)
    setEditItem(item)
    setEditedProps(item)
  }

  const handleCancelEdit = () => {
    setEditIndex(null)
  }

  const handleSaveEdit = async () => {
    try {
      const endpoint = `/web3/rpc-endpoints/${editItem._id}`
      const payload = {
        ...editItem,
        type: editedProps?.type,
        active: editedProps?.active,
        url: editedProps?.url,
        score: parseFloat(editedProps?.score),
      }
      await callApi(endpoint, payload, 'PUT')
      fetchData()
    } catch (error: any) {
      console.error('There was an error updating the RPC endpoint:', error)
    }
    setEditIndex(null)
  }

  const handleAddNewCancel = () => {
    setAddNew(false)
  }

  return (
    <div>
      <Right>
        <ButtonViolet onClick={() => setAddNew(!addNew)}>+ Add new</ButtonViolet>
      </Right>
      {addNew && (
        <FormModal onConfirm={handleSuccess} onCancel={handleAddNewCancel}>
          <RpcsForm onSuccess={handleSuccess} />
        </FormModal>
      )}
      {data ? (
        <>
          <ScrollTable>
            <ContainerTable className="editingTable">
              <thead>
                <tr>
                  <th>Network</th>
                  <th>Type</th>
                  <th>Score</th>
                  <th>URL</th>
                  <th>Active</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <Network network={item?.chainId} />
                      </td>
                      <td>
                        {editIndex === index ? (
                          <input
                            type="text"
                            value={editedProps?.type}
                            onChange={(e) =>
                              setEditedProps({
                                ...editedProps,
                                type: e.target.value,
                              })
                            }
                          />
                        ) : (
                          item.type
                        )}
                      </td>
                      <td>
                        {editIndex === index ? (
                          <input
                            type="number"
                            value={editedProps?.score}
                            onChange={(e) =>
                              setEditedProps({
                                ...editedProps,
                                score: e.target.value,
                              })
                            }
                          />
                        ) : item.score !== 0 ? (
                          item.score.toFixed(3)
                        ) : (
                          0
                        )}
                      </td>
                      <td>
                        {editIndex === index ? (
                          <input
                            type="text"
                            className="xx-large"
                            value={editedProps?.url}
                            onChange={(e) =>
                              setEditedProps({
                                ...editedProps,
                                url: e.target.value,
                              })
                            }
                          />
                        ) : (
                          item.url
                        )}
                      </td>
                      <td>
                        {editIndex === index ? (
                          <MiniSwitch
                            ison={editedProps.active}
                            onToggle={() =>
                              setEditedProps({
                                ...editedProps,
                                active: !editedProps.active,
                              })
                            }
                          />
                        ) : item.active ? (
                          '🟢'
                        ) : (
                          '🔴'
                        )}
                      </td>
                      <td>
                        {editIndex === index ? (
                          <Flexbox>
                            <img onClick={handleSaveEdit} src={save} alt="Save" />
                            <img onClick={handleCancelEdit} src={cancel} alt="Cancel" />
                          </Flexbox>
                        ) : (
                          <img onClick={() => handleEdit(item, index)} src={edit} alt="Edit" />
                        )}
                      </td>
                      <td>
                        <img onClick={() => handleDeleteClick(item)} src={deleteIcon} alt="Delete" />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </ContainerTable>
          </ScrollTable>
          {deleteParams !== null && (
            <CustomConfirmationModal
              message="Are you sure you want to delete this network?"
              onConfirm={handleConfirmDelete}
              onCancel={handleCancelDelete}
            />
          )}
        </>
      ) : (
        <Loader />
      )}
    </div>
  )
}

export default Rpcs
