// @ts-nocheck
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { useApi } from '../../../hooks/useApi'
import { ContainerTable, ScrollTable, H2 } from '../../../utils/styles'
import { formatTokenAmount } from '../../../utils/math-utils'
import MiniSwitch from '../../formUtils/MiniSwitch'
import useTasks from '../../../hooks/useTasks'
import CustomConfirmationModal from '../../CustomConfirmationModal'
import warning from '../../../assets/warning.svg'
import toast, { Toaster } from 'react-hot-toast'

const URL = process.env.REACT_APP_SERVER_BASE_URL

const Tasks: React.FC<{ chainId: number; smartVaults: any }> = ({ chainId, smartVaults }) => {
  const params = useParams<{ id: string }>()
  const { callApi } = useApi()
  const tasks = useTasks(chainId, params.id)
  const [smartVaultInfo, setSmartVaultInfo] = useState(smartVaults.find((sv: any) => sv.chainId === chainId))
  const deniedTasks = smartVaultInfo?.deniedTasks
  const [comment, setDeniedComment] = useState('')
  const [ecoModes, setEcoModes] = useState(null)
  const [timelocks, setTimelocks] = useState(null)
  const [isolatedModalOpen, setIsolatedModalOpen] = useState(false)
  const [deniedModalOpen, setDeniedModalOpen] = useState(false)
  const [selectedTaskId, setSelectedTaskId] = useState<string | null>(null)

  const fetchEcoModes = async () => {
    try {
      const ecos = await callApi(`/relayer-executor/environments/${params.id}/eco-modes`, { chainId })
      setEcoModes(ecos)
    } catch (error) {
      console.error('Error fetching eco modes:', error)
    }
  }

  const fetchTimelocks = async () => {
    try {
      const timelocks = await callApi(`/relayer-executor/smart-vaults/${smartVaultInfo._id}/timelocks`)
      setTimelocks(timelocks)
    } catch (error) {
      console.error('Error fetching timelocks:', error)
    }
  }

  const fetchSmartVault = async (id: string) => {
    try {
      const response = await callApi(`/relayer-executor/smart-vaults/${id}`)
      setSmartVaultInfo(response)
    } catch (error) {
      console.error('Error fetching smart vault info:', error)
    }
  }

  useEffect(() => {
    fetchEcoModes()
    setSmartVaultInfo(smartVaults.find((sv: any) => sv.chainId === chainId))
  }, [chainId])

  useEffect(() => {
    if (ecoModes && ecoModes[0]?._id) {
      fetchTimelocks()
    }
  }, [ecoModes])

  const handleIsolatedEdit = (id: string) => {
    setSelectedTaskId(id)
    setIsolatedModalOpen(true)
  }

  const handleConfirmIsolated = async () => {
    if (selectedTaskId && ecoModes && ecoModes[0]) {
      const url = `/relayer-executor/eco-modes/${ecoModes[0]._id}`
      const ecoModeTasks = ecoModes[0]?.isolatedTasksAvoidanceList?.includes(selectedTaskId)
        ? ecoModes[0]?.isolatedTasksAvoidanceList.filter((taskId) => taskId !== selectedTaskId)
        : [...ecoModes[0]?.isolatedTasksAvoidanceList, selectedTaskId]

      try {
        await callApi(url, { ...ecoModes[0], isolatedTasksAvoidanceList: ecoModeTasks }, 'PUT')
        fetchEcoModes()
      } catch (error) {}
    }
    setIsolatedModalOpen(false)
  }

  const handleCancelIsolated = () => {
    setSelectedTaskId(null)
    setIsolatedModalOpen(false)
  }

  const handleDeniedEdit = (id: string) => {
    setSelectedTaskId(id)
    setDeniedModalOpen(true)
  }

  const handleCancelDenied = () => {
    setSelectedTaskId(null)
    setDeniedModalOpen(false)
  }

  const handleConfirmDenied = async () => {
    if (selectedTaskId) {
      const url = `/relayer-executor/smart-vaults/${smartVaultInfo._id}`
      let denTasks = smartVaultInfo.deniedTasks
      let message = 'Task successfully denied'

      if (denTasks.some((task) => task.address?.includes(selectedTaskId))) {
        denTasks = denTasks.filter((item) => item.address !== selectedTaskId)
        message = 'Task successfully un-denied'
      } else {
        denTasks = [...denTasks, { address: selectedTaskId, comment: comment }]
      }

      try {
        await callApi(url, { ...smartVaultInfo, deniedTasks: denTasks }, 'PUT', 'noMessage')
        toast.success(message)
        setDeniedComment('')
        fetchSmartVault(smartVaultInfo._id)
      } catch (error) {
        toast.error('There was an error editing this task')
        console.error('There was an error editing this task:', error)
      }
    }
    setDeniedModalOpen(false)
  }

  const isolated = ecoModes ? ecoModes[0]?.isolatedTasksAvoidanceList : []

  return (
    <>
      <H2>Tasks</H2>
      <Toaster position="top-right" />
      {tasks.isLoading ? (
        'Loading...'
      ) : (
        <ScrollTable>
          <ContainerTable className="full-width">
            <thead>
              <tr>
                <th>Name</th>
                <th>Min Threshold</th>
                <th>Max Threshold</th>
                <th>Timelock Start</th>
                <th>Timelock End</th>
                <th>Max Slippage</th>
                <th>Token Out</th>
                <th>Max Fee</th>
                <th>Destination Chain</th>
                <th>Denied</th>
                <th>Comment</th>
                {ecoModes?.length > 0 && <th>Isolated</th>}
              </tr>
            </thead>
            <tbody>
              {tasks.data &&
                !tasks.isLoading &&
                tasks.data.tasks
                  .sort((a, b) => {
                    if (a.permissions <= 0 && b.permissions > 0) return 1
                    if (a.permissions > 0 && b.permissions <= 0) return -1
                    return a.name.localeCompare(b.name)
                  })
                  .map((item, index) => {
                    const timelock = timelocks?.find((timelock) => timelock.task === item.id)
                    return (
                      <tr key={index}>
                        <td className="accent">
                          <a
                            href={'https://app.mimic.fi/' + params.id + '/settings/' + item.name}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <TaskName>
                              {item.name}
                              {parseInt(item?.permissions) <= 0 && <img src={warning} alt="!" />}
                            </TaskName>
                          </a>
                        </td>
                        <td>
                          {item?.taskConfig?.defaultTokenThreshold?.min &&
                            formatTokenAmount(
                              item?.taskConfig?.defaultTokenThreshold?.min,
                              item?.taskConfig?.defaultTokenThreshold?.token?.decimals,
                              { digits: 2 }
                            )}{' '}
                          {item?.taskConfig?.defaultTokenThreshold?.min != 0 &&
                            item?.taskConfig?.defaultTokenThreshold?.token?.symbol}
                        </td>
                        <td>
                          {item?.taskConfig?.defaultTokenThreshold?.max &&
                            formatTokenAmount(
                              item?.taskConfig?.defaultTokenThreshold?.max,
                              item?.taskConfig?.defaultTokenThreshold?.token?.decimals,
                              { digits: 2 }
                            )}{' '}
                          {item?.taskConfig?.defaultTokenThreshold?.max > 0 &&
                            item?.taskConfig?.defaultTokenThreshold?.token?.symbol}
                        </td>
                        <td>{timelock ? moment(timelock.start).utc().format('YYYY-MM-DD h:mm A [GMT]') : ''}</td>
                        <td>{timelock ? moment(timelock.end).utc().format('YYYY-MM-DD h:mm A [GMT]') : ''}</td>
                        <td>
                          {item?.taskConfig?.defaultMaxSlippage &&
                            parseFloat(
                              formatTokenAmount(item?.taskConfig?.defaultMaxSlippage, 18, {
                                digits: 2,
                              })
                            ) *
                              100 +
                              ' %'}
                        </td>
                        <td>{item?.taskConfig?.defaultTokenOut?.symbol}</td>
                        <td>
                          {item?.taskConfig?.defaultMaxBridgeFee &&
                            formatTokenAmount(
                              item?.taskConfig?.defaultMaxBridgeFee?.amount,
                              item?.taskConfig?.defaultMaxBridgeFee?.token.decimals,
                              { digits: 2 }
                            ) +
                              ' ' +
                              item?.taskConfig?.defaultMaxBridgeFee?.token.symbol}
                        </td>
                        <td>{item?.taskConfig?.defaultDestinationChain}</td>
                        <td>
                          <MiniSwitch
                            ison={deniedTasks?.some((task) => task.address?.includes(item.id))}
                            onToggle={() => handleDeniedEdit(item.id)}
                            oncolor="#F94872"
                            offcolor="#b3b3b3"
                          />
                        </td>
                        <td> {deniedTasks.find((task) => task.address?.includes(item.id))?.comment}</td>
                        {ecoModes?.length > 0 && (
                          <td>
                            <MiniSwitch
                              ison={isolated?.includes(item.id)}
                              onToggle={() => handleIsolatedEdit(item.id)}
                            />
                          </td>
                        )}
                      </tr>
                    )
                  })}
            </tbody>
          </ContainerTable>
        </ScrollTable>
      )}
      {isolatedModalOpen && (
        <CustomConfirmationModal
          message="Are you sure you want to isolate this task on the eco mode?"
          onConfirm={handleConfirmIsolated}
          onCancel={handleCancelIsolated}
        />
      )}
      {deniedModalOpen && (
        <CustomConfirmationModal
          message={
            'Are you sure you want to' +
            (smartVaultInfo.deniedTasks.some((task) => task.address?.includes(selectedTaskId))
              ? ' un-deny '
              : ' deny ') +
            'this task?'
          }
          onConfirm={handleConfirmDenied}
          onCancel={handleCancelDenied}
        >
          {!smartVaultInfo.deniedTasks.some((task) => task.address?.includes(selectedTaskId)) && (
            <>
              <div>Do you want to add a comment</div>
              <input type="text" value={comment} onChange={(e) => setDeniedComment(e.target.value)} />
            </>
          )}
        </CustomConfirmationModal>
      )}
    </>
  )
}

const TaskName = styled.span`
  display: flex;
  align-items: center;
  img {
    height: 15px;
    padding-left: 5px;
  }
`

export default Tasks
