import { useQuery } from '@tanstack/react-query'
import { ChainStatsResponse } from '../types/API'
import { useApi } from './useApi'

const useChainStats = (chainId: number | undefined) => {
  const { callApi } = useApi()

  const fetchChainInfo = async (): Promise<ChainStatsResponse> => {
    const url = `/relayer-executor/chains/${chainId}/stats`
    return await callApi<ChainStatsResponse>(url)
  }

  return useQuery<ChainStatsResponse>({
    queryKey: ['chainInfo', chainId],
    queryFn: fetchChainInfo,
    enabled: !!chainId,
  })
}

export default useChainStats
