import { useQuery } from '@tanstack/react-query'
import { SlippageResponse } from '../types/API'
import { useApi } from './useApi'

const useSlippage = (
  id: string | undefined,
  startDate: Date | null,
  endDate: Date | null,
  tasks: string[],
  chainId: string
) => {
  const { callApi } = useApi()

  const fetchSlippage = async (): Promise<SlippageResponse> => {
    const url = `/relayer-executor/environments/${id}/slippage`

    const params = {
      chainIds: [chainId],
      startDate: startDate?.toISOString(),
      endDate: endDate?.toISOString(),
      tasks,
    }

    return await callApi<SlippageResponse>(url, params)
  }

  return useQuery<SlippageResponse>({
    queryKey: ['slippage', id, startDate, endDate, tasks, chainId],
    queryFn: fetchSlippage,
    enabled: !!id && !!startDate && !!endDate,
  })
}

export default useSlippage
