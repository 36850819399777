import { useQuery } from '@tanstack/react-query'
import { EnvironmentGasResponse } from 'API'
import { useApi } from './useApi'
import { formatTokenAmount } from '../utils/math-utils'

const useEnvironmentGas = (chainId: number) => {
  const { callApi } = useApi()

  const fetchEnvironmentGas = async (): Promise<EnvironmentGasResponse> => {
    const date = new Date()
    date.setHours(date.getHours() - 3)
    const isoDate = date.toISOString()

    const gas = await callApi<{ estimatedGasPrice: number }>('/web3/gas/params', {
      chainId,
    })

    const median = await callApi<number>('/web3/gas/median', {
      chainId,
      startDate: isoDate,
    })

    return {
      currentGas: gas ? formatTokenAmount(gas.estimatedGasPrice, 9, { digits: 2 }) : '0',
      medianGas: median ? formatTokenAmount(median, 9, { digits: 2 }) : '0',
    }
  }

  return useQuery<EnvironmentGasResponse>({
    queryKey: ['gas', chainId],
    queryFn: fetchEnvironmentGas,
    enabled: !!chainId,
  })
}

export default useEnvironmentGas
