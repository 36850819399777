import { useQuery } from '@tanstack/react-query'
import { BotBalanceResponse } from 'API'
import { formatTokenAmount } from '../utils/math-utils'
import { useApi } from './useApi'

function useBotBalance(chainId: number) {
  const { callApi } = useApi()

  const fetchBotBalance = async (): Promise<BotBalanceResponse> => {
    const balances = await callApi<any>('/relayer-executor/wallets/balances')
    const price = await callApi<any>(`/price-oracle/prices/${chainId}/0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee`)

    const balanceInfo = Object.values(balances || {}).reduce((acc: number, obj: any) => {
      const value = obj[chainId]
      return acc + (parseInt(value) || 0)
    }, 0)

    return {
      balance: formatTokenAmount(balanceInfo, 18, { digits: 2 }),
      price: price?.price || 0,
      balanceUsd:
        price?.price !== 0
          ? (Number(formatTokenAmount(balanceInfo, 18, { digits: 10 })) * price.price).toFixed(2)
          : null,
    }
  }

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ['botBalance', chainId],
    queryFn: fetchBotBalance,
  })

  return { data, isLoading, error, refetch }
}

export default useBotBalance
