import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { ButtonViolet, ButtonWhite } from '../utils/styles'

interface CustomConfirmationModalProps {
  message: string
  onConfirm: () => void
  onCancel: () => void
  cancelName?: string
  confirmName?: string
  children?: ReactNode
}

const ModalOverlay = styled.div`
  position: fixed;
  z-index: 400;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`

const ModalContent = styled.div`
  background: #323244;
  padding: 20px;
  border-radius: 8px;
  width: 280px;
  text-align: center;
  input {
    margin: 10px auto 30px auto;
    min-width: 150px;
    width: 250px;
    border: 2px solid #5f5d62;
    background: transparent;
    color: white;
    border-radius: 10px;
    height: 34px;
  }
  p {
    font-family: 'Visby';
    font-size: 20px;
    line-height: 30px;
  }
`

const CustomConfirmationModal: React.FC<CustomConfirmationModalProps> = ({
  message,
  onConfirm,
  onCancel,
  cancelName = 'No',
  confirmName = 'Yes',
  children,
}) => {
  return (
    <ModalOverlay>
      <ModalContent>
        <p>{message}</p>
        {children}
        <ButtonViolet onClick={onConfirm}>{confirmName || 'Yes'}</ButtonViolet>
        <ButtonWhite onClick={onCancel}>{cancelName || 'No'}</ButtonWhite>
      </ModalContent>
    </ModalOverlay>
  )
}

export default CustomConfirmationModal
