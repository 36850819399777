import { useQuery } from '@tanstack/react-query'
import { useApi } from './useApi'

const fetchThresholds = async (callApi: any, mimicId: string): Promise<Environment[]> => {
  const response = await callApi<EnvironmentResponse>(`/relayer-executor/smart-vaults/thresholds/${mimicId}`)
  console.log(response)
  return response
}

const useThresholds = (mimicId: string) => {
  const { callApi } = useApi()

  return useQuery<any>({
    queryKey: ['thresholds', mimicId],
    queryFn: () => fetchThresholds(callApi, mimicId),
    refetchOnWindowFocus: false,
  })
}

export default useThresholds
