import React, { useState } from 'react'
import Switch from '../Switch'
import { ButtonViolet, ButtonWhite, Flexbox, Message, Group, H2 } from '../../utils/styles'
import { NewForm } from '../../utils/typescript-styles'
import CustomInput from '../general/Form'
import { useApi } from '../../hooks/useApi'

const URL = process.env.REACT_APP_SERVER_BASE_URL

interface TokenListFormProps {
  onSuccess?: () => void
}

interface TokenFormData {
  address: string
  symbol: string
  name: string
  decimals: number
  chainId: number
  spamCounter: number
  priority: number
  enabled: boolean
  isNativeToken: boolean
  isWrappedNativeToken: boolean
  [key: string]: string | number | boolean | string[]
}

const TokenListForm: React.FC<TokenListFormProps> = ({ onSuccess = () => {} }) => {
  const { callApi } = useApi()
  const [formData, setFormData] = useState<TokenFormData>({
    address: '',
    symbol: '',
    name: '',
    decimals: 0,
    chainId: 0,
    priority: 0,
    spamCounter: 0,
    enabled: true,
    isNativeToken: false,
    isWrappedNativeToken: false,
  })

  const [message, setMessage] = useState('')
  const [advanced, setAdvanced] = useState(false)

  const handleFormSubmitAdvanced = async (e: React.FormEvent) => {
    e.preventDefault()

    try {
      await callApi('/token-registry/tokens', { ...formData }, 'POST')
      onSuccess()
    } catch (error) {
      console.error('Error creating token:', error)
    }
  }

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    try {
      await callApi(`/token-registry/tokens/${formData.chainId}/${formData.address}`)
      onSuccess()
    } catch (error) {
      console.error('Error fetching token:', error)
    }
  }

  const handleSwitchToggle = (field: keyof TokenFormData) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: !prevData[field],
    }))
  }

  return (
    <NewForm onSubmit={advanced ? handleFormSubmitAdvanced : handleFormSubmit}>
      {message !== '' ? (
        <Message>
          <span>{message}</span>
          <span className="close" onClick={() => setMessage('')}>
            X
          </span>
        </Message>
      ) : (
        <>
          <H2>New token registry</H2>
          <Group>
            <div>
              <CustomInput
                type="text"
                label="Address"
                name="Address"
                modal={true}
                value={formData.address}
                onChange={(e: any) => setFormData({ ...formData, address: e.target.value })}
                required
              />
            </div>
            <div>
              <CustomInput
                type="number"
                label="Chain ID"
                name="Chain ID"
                modal={true}
                value={formData.chainId}
                onChange={(e: any) =>
                  setFormData({
                    ...formData,
                    chainId: parseInt(e.target.value, 10),
                  })
                }
                required
              />
            </div>
          </Group>
          {advanced && (
            <>
              <Group>
                <div>
                  <CustomInput
                    type="text"
                    label="Name"
                    name="Name"
                    modal={true}
                    value={formData.name}
                    onChange={(e: any) => setFormData({ ...formData, name: e.target.value })}
                    {...(advanced && { required: true })}
                  />
                </div>
                <div>
                  <CustomInput
                    type="text"
                    label="Symbol"
                    name="Symbol"
                    modal={true}
                    value={formData.symbol}
                    onChange={(e: any) => setFormData({ ...formData, symbol: e.target.value })}
                    {...(advanced && { required: true })}
                  />
                </div>
              </Group>
              <Group>
                <div>
                  <CustomInput
                    type="number"
                    label="Decimals"
                    name="Decimals"
                    modal={true}
                    value={formData.decimals}
                    onChange={(e: any) =>
                      setFormData({
                        ...formData,
                        decimals: parseInt(e.target.value, 10),
                      })
                    }
                    {...(advanced && { required: true })}
                  />
                </div>
                <div>
                  <CustomInput
                    type="number"
                    label="Spam Counter"
                    name="Spam Counter"
                    modal={true}
                    value={formData.spamCounter}
                    onChange={(e: any) =>
                      setFormData({
                        ...formData,
                        spamCounter: parseInt(e.target.value, 10),
                      })
                    }
                    {...(advanced && { required: true })}
                  />
                </div>
              </Group>
              <Group>
                <div>
                  <CustomInput
                    type="number"
                    label="Priority"
                    name="Priority"
                    modal={true}
                    value={formData.priority}
                    onChange={(e: any) =>
                      setFormData({
                        ...formData,
                        priority: parseInt(e.target.value, 10),
                      })
                    }
                    {...(advanced && { required: true })}
                  />
                </div>
                <div>
                  <label>Native</label>
                  <Switch ison={formData.isNativeToken} onToggle={() => handleSwitchToggle('isNativeToken')} />
                </div>
                <div>
                  <label>Wrapped</label>
                  <Switch
                    ison={formData.isWrappedNativeToken}
                    onToggle={() => handleSwitchToggle('isWrappedNativeToken')}
                  />
                </div>
                <div>
                  <label>Enabled</label>
                  <Switch ison={formData.enabled} onToggle={() => handleSwitchToggle('enabled')} />
                </div>
              </Group>
            </>
          )}
          <br />
          <Flexbox>
            <ButtonViolet type="submit">{advanced ? 'New' : 'Find'}</ButtonViolet>
            <ButtonWhite onClick={() => setAdvanced(!advanced)}>{advanced ? 'Hide advanced' : 'Advanced'}</ButtonWhite>
          </Flexbox>
        </>
      )}
    </NewForm>
  )
}

export default TokenListForm
