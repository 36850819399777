import { useContext } from 'react'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { request, gql } from 'graphql-request'
import SubgraphsContext from '../context/SubgraphsContext'

interface Implementation {
  balance: string
  quotaUsed: string
  maxQuota: string
}

const useRelayer = (id: string): UseQueryResult<Record<string, Implementation[]>> => {
  const subgraphs = useContext(SubgraphsContext)

  return useQuery<Record<string, Implementation[]>>({
    queryKey: ['useRelayer'],
    queryFn: () => fetchAll(id, subgraphs),
  })
}

const fetchRelayer = async (
  id: string,
  chainId: string,
  subgraphs: Record<string, string>
): Promise<Implementation[] | null> => {
  try {
    if (!chainId) return null
    const data = await request<{ relayerConfigs: Implementation[] }>(
      subgraphs[chainId],
      gql`
        query Relayer {
          environment(id: ${'"' + id.toLowerCase() + '"'}) {
            network
            smartVaults {
              relayerConfigs {
                 id
                 balance
                 feeCollector
                 maxQuota
                 quotaUsed
                 relayer {
                  feeCollector
                  id
                 }
                 nativeToken {
                   decimals
                   id
                   name
                   symbol
                }
              }
            }
          }
        }
      `
    )
    return (
      data.environment?.smartVaults[0]?.relayerConfigs.map((config) => ({
        ...config,
        chainId,
      })) || []
    )
  } catch (error) {
    console.error(`Error fetching implementations for chain ${chainId}:`, error)
    return null
  }
}

const fetchAll = async (id: string, subgraphs: Record<string, string>): Promise<Record<string, Implementation[]>> => {
  let allData: Implementation[] = []

  const promises = Object.keys(subgraphs).map((chainId) =>
    fetchRelayer(id, chainId, subgraphs).then((response) => ({
      chainId,
      response,
    }))
  )

  const results = await Promise.allSettled(promises)
  results.forEach((result) => {
    if (result.status === 'fulfilled') {
      allData = allData.concat(result.value?.response)
    } else if (result.status === 'rejected') {
      console.error(`Error fetching data for chain ${result.reason.chainId}:`, result.reason)
    }
  })

  return allData
}

export default useRelayer
