import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { parseName } from '../../../utils/math-utils'
import { useApi } from '../../../hooks/useApi'
import { ButtonViolet, ButtonWhite, Message } from '../../../utils/styles'
import CustomInput from '../../general/Form'
import { Form } from '../../../utils/typescript-styles'

interface ApiSetting {
  _id: string
  rateLimitRequests: number
  rateLimitWindowMs: number
  corsAllowedOrigins: string
}

const URL = process.env.REACT_APP_SERVER_BASE_URL

const ApiSettings: React.FC = () => {
  const { callApi } = useApi()
  const [apiSettings, setApiSettings] = useState<ApiSetting | null>(null)
  const [message, setMessage] = useState('')
  const [editedSettings, setEditedSettings] = useState<Partial<ApiSetting> | null>(null)

  useEffect(() => {
    const fetchApiSettings = async () => {
      try {
        const response = await callApi('/api/settings')
        if (response) {
          setApiSettings(response)
          setEditedSettings(response)
        }
      } catch (error) {
        console.error('Error al obtener las configuraciones de la API:', error)
      }
    }
    fetchApiSettings()
  }, [])

  const handleCancelEditClick = () => {
    setEditedSettings(apiSettings)
  }

  const handleSaveClick = async (e: React.FormEvent) => {
    e.preventDefault()
    try {
      console.log('ed', editedSettings)
      const apiData = {
        ...editedSettings,
        rateLimitRequests: parseFloat(editedSettings.rateLimitRequests),
        rateLimitWindowMs: parseFloat(editedSettings.rateLimitWindowMs),
        corsAllowedOrigins: Array.isArray(editedSettings?.corsAllowedOrigins)
          ? editedSettings.corsAllowedOrigins
          : editedSettings?.corsAllowedOrigins?.split(','), // Only split if it's a string
      }
      const response = await callApi('/api/settings', apiData, 'PUT')
    } catch (error) {
      console.error('Error al guardar las configuraciones de la API:', error)
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setEditedSettings((prevSettings: any) => ({
      ...prevSettings,
      [name]: value,
    }))
  }

  return (
    <>
      {apiSettings ? (
        <>
          <Form onSubmit={handleSaveClick}>
            {message !== '' ? (
              <Message>
                <span>{message}</span>
                <span className="close" onClick={() => setMessage('')}>
                  X
                </span>
              </Message>
            ) : (
              <>
                <div className="container">
                  {Object.entries(editedSettings as ApiSetting).map(
                    ([key, value]) =>
                      key !== '__v' &&
                      key !== 'createdAt' &&
                      key !== 'updatedAt' &&
                      key !== 'lastUpdate' &&
                      key !== '_id' && (
                        <div key={key} className="input-group">
                          <CustomInput
                            type="text"
                            name={key}
                            label={parseName(key)}
                            value={String(value)}
                            onChange={handleInputChange}
                            disabled={key === '_id' || key === 'updatedAt'}
                          />
                        </div>
                      )
                  )}
                </div>
                <div className="buttons">
                  <ButtonViolet type="submit">Save</ButtonViolet>
                  <ButtonWhite onClick={handleCancelEditClick}>Cancel</ButtonWhite>
                </div>
              </>
            )}
          </Form>
        </>
      ) : (
        <p>Loading configurations...</p>
      )}
    </>
  )
}

const ApiSection = styled.div`
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 874px;
`

export default ApiSettings
