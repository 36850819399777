// @ts-nocheck
import React, { useState, FormEvent } from 'react'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { useApi } from '../../../hooks/useApi'
import { ButtonViolet, Message, Group } from '../../../utils/styles'
import { NewForm } from '../../../utils/typescript-styles'
import Switch from '../../Switch'

const URL = process.env.REACT_APP_SERVER_BASE_URL

interface Web3FormProps {
  onSuccess?: () => void
}

interface FormValues {
  name: string
  chainId: string
  nativeTokenSymbol: string
  rpcQueryEndpoints: string[]
  rpcExecutionEndpoints: string[]
}

const Web3Form: React.FC<Web3FormProps> = ({ onSuccess = () => {} }) => {
  const { callApi } = useApi()
  const [formValues, setFormValues] = useState<FormValues>({
    chainId: 0,
    active: false,
    url: '',
    type: '',
    score: 0,
  })
  const [message, setMessage] = useState<string>('')

  const handleFormSubmit = async (e: FormEvent) => {
    e.preventDefault()
    try {
      const formData = {
        chainId: parseInt(formValues.chainId, 10),
        url: formValues.url,
        active: formValues.active,
        type: formValues.type,
        score: parseFloat(formValues.score),
      }

      const response = await callApi('/web3/rpc-endpoints', formData, 'POST')
      if (response) {
        onSuccess()
      }
    } catch (error) {}
  }

  return (
    <NewForm onSubmit={handleFormSubmit}>
      {message !== '' ? (
        <Message>
          <span>{message}</span>
          <span className="close" onClick={() => setMessage('')}>
            X
          </span>
        </Message>
      ) : (
        <>
          <Group>
            <div>
              <label>Chain ID</label>
              <input
                type="number"
                name="chainId"
                value={formValues.chainId}
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    chainId: parseInt(e.target.value, 10),
                  })
                }
                required
              />
            </div>
            <div>
              <label>Url</label>
              <input
                type="text"
                name="url"
                value={formValues.url}
                onChange={(e) => setFormValues({ ...formValues, url: e.target.value })}
                required
              />
            </div>
          </Group>
          <Group>
            <div>
              <label>Type</label>
              <input
                type="text"
                name="nativeTokenSymbol"
                value={formValues.nativeTokenSymbol}
                onChange={(e) => setFormValues({ ...formValues, type: e.target.value })}
                required
              />
            </div>
            <div>
              <label>Score</label>
              <input
                type="number"
                name="nativeTokenSymbol"
                value={formValues.score}
                onChange={(e) => setFormValues({ ...formValues, score: e.target.value })}
                required
              />
            </div>
            <div>
              <label>Active</label>
              <Switch
                ison={formValues.active}
                onToggle={() => setFormValues({ ...formValues, active: !formValues.active })}
              />
            </div>
          </Group>
          <ButtonViolet type="submit">New</ButtonViolet>
        </>
      )}
    </NewForm>
  )
}

export default Web3Form
