import React, { useState } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { Section, ContainerTable, Loader, Filters, Filter } from '../../utils/styles'
import { SupportedChainId } from '../../constants/chainInfo'
import { capitalizeFirstLetter, formatTokenAmount } from '../../utils/math-utils'
import useMonitors from '../../hooks/useMonitors'
import useThresholds from '../../hooks/useThresholds'
import Address from '../general/Address'
import Network from '../general/Network'
import Switch from '../Switch'

export default function AllMonitors() {
  const params = useParams()
  const data = useMonitors(params.id)
  const thresholds = useThresholds(params.id)
  const [filters, setFilters] = useState<any>({
    address: '',
    symbol: '',
    chainId: '',
  })
  const [showAboveThreshold, setShowAboveThreshold] = useState(false) // Estado para el switch

  const SUPPORTED_CHAINS = Object.entries(SupportedChainId).map(([key, value]) => ({
    label: capitalizeFirstLetter(key.toLowerCase()) as string,
    value,
  }))

  const transformBalances = (balances) => {
    const result = []
    for (const chainId in balances) {
      const tokens = balances[chainId]
      for (const address in tokens) {
        result.push({
          ...tokens[address],
          address,
          chainId,
        })
      }
    }
    return result
  }

  const transformedData = transformBalances(data?.data)

  const handleFilterChange = (key: string, value: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }))
  }

  const filteredData = transformedData
    ?.filter((token) => {
      const usd = (
        formatTokenAmount(token.totalAmount, token.decimals, { digit: 7 }) * token.price
      ).toFixed(2)
      const threshold = thresholds.data?.[token.chainId]
      const isAboveThreshold = usd > (threshold || 0)

      return (
        (filters.address === '' || token.address.toLowerCase().includes(filters.address.toLowerCase())) &&
        (filters.symbol === '' || (token.symbol || '').toLowerCase().includes(filters.symbol.toLowerCase())) &&
        (filters.chainId === '' || token.chainId === filters.chainId) &&
        (!showAboveThreshold || isAboveThreshold)
      )
    })
    ?.sort((a, b) => {
      const amountA = formatTokenAmount(a.totalAmount, a.decimals, { digit: 7 }) * a.price
      const amountB = formatTokenAmount(b.totalAmount, b.decimals, { digit: 7 }) * b.price
      return amountB - amountA
    })

  return (
    <Section>
      {transformedData ? (
        <>
          <Filters>
            <Filter>
              <label>Address</label>
              <input
                type="text"
                placeholder="Filter by Address"
                value={filters.address}
                onChange={(e) => handleFilterChange('address', e.target.value)}
              />
            </Filter>
            <Filter>
              <label>Symbol</label>
              <input
                type="text"
                placeholder="Filter by Symbol"
                value={filters.symbol}
                onChange={(e) => handleFilterChange('symbol', e.target.value)}
              />
            </Filter>
            <Filter>
              <label>Chain</label>
              <select value={filters.chainId} onChange={(e) => handleFilterChange('chainId', e.target.value)}>
                <option value="">All</option>
                {SUPPORTED_CHAINS.map((chain) => (
                  <option key={chain.value} value={chain.value}>
                    {chain.label}
                  </option>
                ))}
              </select>
            </Filter>
            <Filter>
              <label>Above Thresholds</label>
              <div style={{ marginTop: '9px'}}>
                <Switch
                  ison={showAboveThreshold}
                  onToggle={() => setShowAboveThreshold(!showAboveThreshold)}
                />
            </div>
            </Filter>
          </Filters>
          <ContainerTable>
            <thead>
              <tr>
                <th>Network</th>
                <th>Symbol</th>
                <th>Address</th>
                <th>Threshold</th>
                <th>Amount</th>
                <th>Price</th>
                <th>USD</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((token, index) => {
                const usd = (
                  formatTokenAmount(token.totalAmount, token.decimals, { digit: 7 }) * token.price
                ).toFixed(2)
                const threshold = thresholds.data?.[token.chainId]
                const isAboveThreshold = usd > (threshold || 0)
                return (
                  <tr key={index}>
                    <td>
                      <Network network={token.chainId} />
                    </td>
                    <td>{token.symbol}</td>
                    <td>
                      <Address address={token.address} chainId={token.chainId} showIdentity={false} />
                    </td>
                    <td>{threshold < 1e-8 ? threshold.toFixed(11) : threshold}</td>
                    <td>
                      {formatTokenAmount(token.totalAmount, token.decimals, { digit: 2 })}
                    </td>
                    <td>{token.price.toFixed(4)}</td>
                    <td className={threshold ? (isAboveThreshold ? 'success' : 'error') : ''}>
                      ${' '}{usd}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </ContainerTable>
        </>
      ) : (
        <Loader />
      )}
    </Section>
  )
}
