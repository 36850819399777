import React, { useState } from 'react'
import moment from 'moment'
import Decimal from 'decimal.js'
import { formatPrice, formatPrices, formatTokenAmount } from '../../../utils/math-utils'
import Address from '../../general/Address'
import Network from '../../general/Network'
import ExpandableComponent from './ExpandableComponent'
import { Row, Column, FlexItem, FlexNoWrap, DetailsItem, Space, Out } from './styles'
import { AggregatedTokenInfo } from './Monitor'
import TableCell from './TableCell'

interface MonitorItemProps {
  item: AggregatedTokenInfo & { status?: { type: string } }
  width: number
  colored: boolean
  environment?: string
  selectedOpenAll: boolean
  selectedThreshold: number | string
  withThreshold: boolean
}

function MonitorItem({
  item,
  width,
  colored,
  environment,
  selectedOpenAll,
  selectedThreshold,
  withThreshold,
}: MonitorItemProps) {
  const { symbol, chainId, decimals, balance, address, price, amountUsd, priceUpdated } = item
  const [isOpen, setOpen] = useState(false)
  const showStatusColored = !!colored && !!item?.status?.type
  const amountBalance = formatTokenAmount(balance, decimals, { digits: 4 })
  const shouldRender = withThreshold ? (amountUsd ? new Decimal(amountUsd || 0).gte(selectedThreshold) : true) : true

  const MAX_HOURS = 24
  const now = moment()
  const hoursDiff = now.diff(priceUpdated || 0, 'hours')
  const isOutdated = hoursDiff >= MAX_HOURS

  if (!shouldRender) return null
  return (
    <>
      <Row key={item?.address} status={showStatusColored} isOpen={isOpen || selectedOpenAll}>
        <TableCell align="left">
          <FlexNoWrap>
            <Network network={chainId} />
          </FlexNoWrap>
        </TableCell>
        <TableCell>
          <Column>
            <FlexItem>{item.symbol}</FlexItem>
          </Column>
        </TableCell>
        <TableCell align="left">
          <FlexItem>
            <Address address={address} chainId={chainId} showIdentity={false} />
          </FlexItem>
        </TableCell>
        <TableCell>
          <FlexNoWrap>{amountBalance}</FlexNoWrap>
        </TableCell>
        <TableCell align="left">
          <FlexNoWrap>{price ? formatPrice(price) : '-'}</FlexNoWrap>
        </TableCell>
        <TableCell align="left">
          <FlexItem>
            {amountUsd ? formatPrices(Number(amountUsd)) : '-'} {isOutdated && <Out>⚠</Out>}
          </FlexItem>
        </TableCell>
        <TableCell>
          <FlexItem>
            <Space />
            <DetailsItem onClick={() => setOpen(!isOpen)}>
              {!isOpen ? <span className="chevron right"></span> : <span className="chevron bottom"></span>}
            </DetailsItem>
          </FlexItem>
        </TableCell>
      </Row>
      {environment && (
        <ExpandableComponent
          isOpen={isOpen}
          environment={environment}
          item={item}
          selectedOpenAll={selectedOpenAll}
          width={width}
        />
      )}
    </>
  )
}

export default MonitorItem
