import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { useApi } from './useApi'
import { TaskExecutionsResponse } from '../types/API'

type NAry<N> = N | N[]
interface Filter {
  chainId?: NAry<number>
  smartVault?: NAry<string>
  task?: NAry<string>
  executionType?: NAry<string>
  token?: NAry<string>
  status?: NAry<string>
  reason?: NAry<string>
  executionPlanId?: NAry<string>
}

const useLogs = (
  id: string,
  page: number = 1,
  limit: number = 50,
  filter: Filter,
  refetchInterval: number = 0,
  enabled: boolean = true
): UseQueryResult<TaskExecutionsResponse> => {
  const { callApi } = useApi()

  const fetchLogs = async (): Promise<TaskExecutionsResponse> => {
    if (!id) throw new Error('No id provided')

    const url = `/relayer-executor/environments/${id}/executions`

    const params = {
      limit,
      page,
      ...filter,
    }

    return await callApi<TaskExecutionsResponse>(url, { ...params })
  }

  return useQuery<TaskExecutionsResponse>({
    queryKey: ['logs', id, page, filter],
    queryFn: fetchLogs,
    refetchInterval: refetchInterval,
    enabled: enabled,
  })
}

export default useLogs
