import React from 'react'
import MonitorItem from './MonitorItem'
import { Table } from './styles'
import { AggregatedTokenInfo } from './Monitor'
import { BalanceSummary } from '../../../hooks/useSimpleMonitor'

interface ShowTableProps {
  selectedView: string
  isLoading: boolean
  summary: BalanceSummary[]
  oa: AggregatedTokenInfo[]
  width: number
  selectedOpenAll: boolean
  selectedThreshold: number | string
  params: { id?: string }
  usingThreshold: boolean
}

const ShowTable: React.FC<ShowTableProps> = ({
  selectedView,
  isLoading,
  summary,
  oa,
  width,
  selectedOpenAll,
  selectedThreshold,
  params,
  usingThreshold,
}) => {
  if (selectedView !== 'TABLE') return null
  return isLoading ? (
    <>
      <br />
      <br />
      Loading...
    </>
  ) : summary?.length > 0 ? (
    <Table>
      <thead>
        <tr>
          <th>Network</th>
          <th>Symbol</th>
          <th>Address</th>
          <th>Amount</th>
          <th>Price</th>
          <th>USD</th>
          <th></th>
        </tr>
      </thead>

      <tbody>
        {oa?.map((task, i) => (
          <MonitorItem
            key={i}
            item={task}
            width={width}
            selectedThreshold={selectedThreshold}
            environment={params.id}
            selectedOpenAll={selectedOpenAll}
            withThreshold={usingThreshold}
            colored={false}
          />
        ))}
      </tbody>
    </Table>
  ) : (
    <div>No summary to show here yet</div>
  )
}

export default ShowTable
