import { useQuery } from '@tanstack/react-query'
import { useApi } from './useApi'
import { FeesResponse } from '../types/API'

const useFees = (
  id: string | undefined,
  startDate: Date | null,
  endDate: Date | null,
  tasks: string[],
  chainId: string
) => {
  const { callApi } = useApi()

  const fetchFees = async (): Promise<FeesResponse> => {
    const url = `/relayer-executor/environments/${id}/fees`

    const params = {
      chainIds: [chainId],
      startDate: startDate?.toISOString(),
      endDate: endDate?.toISOString(),
      tasks,
    }

    return await callApi<FeesResponse>(url, params)
  }

  return useQuery<FeesResponse>({
    queryKey: ['fees', id, startDate, endDate, tasks, chainId],
    queryFn: fetchFees,
    enabled: !!id && !!startDate && !!endDate,
  })
}

export default useFees
