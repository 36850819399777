// @ts-nocheck
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { ScrollTable, ContainerTable, FlexRow, ButtonViolet, ButtonWhite, Flexbox, H2 } from '../../../utils/styles'
import MiniSwitch from '../../formUtils/MiniSwitch'
import EcoModeForm from './EcoModeForm'
import CustomConfirmationModal from '../../CustomConfirmationModal'
import deleteIcon from '../../../assets/delete.png'
import edit from '../../../assets/edit.png'
import save from '../../../assets/save.png'
import cancel from '../../../assets/cancel.png'
import { useApi } from '../../../hooks/useApi'

const URL = process.env.REACT_APP_SERVER_BASE_URL

interface EcoMode {
  smartVault: {
    chainId: number
    address: string
  }
  active: boolean
  averageSuccessSpeedSmoothFactor: number
  safeGuardPeriodPct: number
  maximumExecutionsPerPeriod: number
  gasPriceMedianThreshold: number
  isolatedTasksAvoidanceList: string[]
  updatedAt: string
}

const EcoModes: React.FC<{ chainId: number; smartVaults: any }> = ({ chainId, smartVaults }) => {
  const params = useParams<{ id: string }>()
  const { callApi } = useApi()
  const [ecoModes, setEcoModes] = useState(null)
  const [form, setForm] = useState(false)
  const [ecoModalOpen, setEcoModalOpen] = useState(false)
  const [deleteEcoMode, setDeleteEcoMode] = useState<EcoMode | null>(null)
  const [timelocks, setTimelocks] = useState(null)

  const [editItem, setEditItem] = useState<EcoMode | any>(null)
  const [editIndex, setEditIndex] = useState<number | any>(null)
  const [editedProps, setEditedProps] = useState<EcoMode | any>(null)

  const [smartVaultInfo, setSmartVaultInfo] = useState(smartVaults.find((sv: any) => sv.chainId === chainId))
  const deniedTasks = smartVaultInfo?.deniedTasks

  const fetchEcoModes = async () => {
    try {
      const ecos = await callApi(`/relayer-executor/environments/${params.id}/eco-modes`, { chainId })
      setEcoModes(ecos)
    } catch (error) {
      console.error('Error fetching eco modes:', error)
    }
  }

  const fetchTimelocks = async () => {
    try {
      const timelocks = await callApi(`/relayer-executor/smart-vaults/${smartVaultInfo._id}/timelocks`)
      setTimelocks(timelocks)
    } catch (error) {
      console.error('Error fetching timelocks:', error)
    }
  }

  useEffect(() => {
    fetchEcoModes()
    fetchTimelocks()
  }, [chainId])

  let timelockPeriod = null
  let endDate = null

  if (timelocks) {
    const filteredTimelocks = timelocks.filter((timelock) => !deniedTasks.includes(timelock?.task))
    filteredTimelocks.sort((a, b) => new Date(a?.end) - new Date(b?.end))
    const closestEndDateTimelock = filteredTimelocks[0]
    const startDate = moment(closestEndDateTimelock?.start)
    endDate = moment(closestEndDateTimelock?.end)
    const timelockDuration = moment.duration(endDate?.diff(startDate))
    timelockPeriod = timelockDuration?.asMilliseconds()
  }

  const handleDeleteEcoMode = (item: EcoMode) => {
    setDeleteEcoMode(item)
    setEcoModalOpen(true)
  }

  const handleConfirmDelete = async () => {
    if (deleteEcoMode) {
      const url = `/relayer-executor/eco-modes/${deleteEcoMode._id}`

      try {
        await callApi<void>(url, {}, 'DELETE')
        fetchEcoModes()
      } catch (error) {}
    }
    setEcoModalOpen(false)
  }

  const handleCancelEcoMode = () => {
    setEcoModalOpen(false)
  }

  const handleEdit = (item: EcoMode, index: number) => {
    setEditIndex(index)
    setEditItem(item)
    setEditedProps({ ...item })
  }

  const handleCancelEdit = () => {
    setEditIndex(null)
    setEditedProps(null)
  }

  const handleSaveEdit = async () => {
    try {
      await callApi<void>(`/relayer-executor/eco-modes/${editItem?._id}`, { ...editedProps }, 'PUT')

      fetchEcoModes()
    } catch (error) {
      console.error('There was an error saving the changes:', error)
    }
    setEditIndex(null)
  }

  return (
    <>
      <FlexRow className="space-around" style={{ margin: '0px' }}>
        <H2>Eco Modes</H2>

        {!form && smartVaults?.filter((sv: any) => sv.chainId === chainId).length > ecoModes?.length && (
          <ButtonViolet onClick={() => setForm(true)}>Add</ButtonViolet>
        )}
        {form && <ButtonWhite onClick={() => setForm(false)}>Hide form</ButtonWhite>}
      </FlexRow>
      {form && <EcoModeForm chainId={chainId} smartVaults={smartVaults} onSuccess={fetchEcoModes} />}
      {ecoModes?.data?.length <= 0 ? (
        'There is no eco mode activated for this environment'
      ) : (
        <>
          {ecoModes?.length > 0 && (
            <ScrollTable>
              <ContainerTable className="editingTable full-width">
                <thead>
                  <tr>
                    <th>Smart Vault</th>
                    <th>Active</th>
                    <th>Success Speed Smooth Factor</th>
                    <th>Safe Guard Period Pct</th>
                    <th>Max Executions Per Period</th>
                    <th>Gas Price Median Threshold</th>
                    <th>Edit</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {ecoModes?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item?.smartVault}</td>
                        <td>
                          {editIndex === index ? (
                            <MiniSwitch
                              ison={editedProps?.active}
                              onToggle={() =>
                                setEditedProps({
                                  ...editedProps,
                                  active: !editedProps?.active,
                                })
                              }
                            />
                          ) : item.active ? (
                            '🟢'
                          ) : (
                            '🔴'
                          )}
                        </td>
                        <td>
                          {editIndex === index ? (
                            <input
                              type="number"
                              className="short"
                              value={editedProps?.averageSuccessSpeedSmoothFactor}
                              onChange={(e) =>
                                setEditedProps({
                                  ...editedProps,
                                  averageSuccessSpeedSmoothFactor: parseFloat(e.target?.value),
                                })
                              }
                            />
                          ) : (
                            item?.averageSuccessSpeedSmoothFactor
                          )}
                        </td>
                        <td>
                          {editIndex === index ? (
                            <input
                              type="number"
                              className="short"
                              value={editedProps?.safeGuardPeriodPct}
                              onChange={(e) =>
                                setEditedProps({
                                  ...editedProps,
                                  safeGuardPeriodPct: parseFloat(e?.target?.value),
                                })
                              }
                            />
                          ) : (
                            <>
                              {item?.safeGuardPeriodPct}

                              {endDate &&
                                timelockPeriod &&
                                ' (' +
                                  endDate
                                    .subtract(timelockPeriod * item?.safeGuardPeriodPct, 'milliseconds')
                                    .utc()
                                    .format('YYYY-MM-DD h:mm A [GMT]') +
                                  ')'}
                            </>
                          )}
                        </td>
                        <td>
                          {editIndex === index ? (
                            <input
                              type="number"
                              className="short"
                              value={editedProps?.maximumExecutionsPerPeriod}
                              onChange={(e) =>
                                setEditedProps({
                                  ...editedProps,
                                  maximumExecutionsPerPeriod: parseFloat(e.target?.value),
                                })
                              }
                            />
                          ) : (
                            item?.maximumExecutionsPerPeriod
                          )}
                        </td>
                        <td>
                          {editIndex === index ? (
                            <input
                              type="number"
                              className="short"
                              value={editedProps?.gasPriceMedianThreshold}
                              onChange={(e) =>
                                setEditedProps({
                                  ...editedProps,
                                  gasPriceMedianThreshold: parseFloat(e.target?.value),
                                })
                              }
                            />
                          ) : (
                            item.gasPriceMedianThreshold
                          )}
                        </td>
                        <td>
                          {editIndex === index ? (
                            <Flexbox>
                              <img onClick={handleSaveEdit} src={save} alt="Save" />
                              <img onClick={handleCancelEdit} src={cancel} alt="Cancel" />
                            </Flexbox>
                          ) : (
                            <img onClick={() => handleEdit(item, index)} src={edit} alt="Edit" />
                          )}
                        </td>
                        <td>
                          <img onClick={() => handleDeleteEcoMode(item)} src={deleteIcon} alt="Delete" />
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </ContainerTable>
            </ScrollTable>
          )}
        </>
      )}
      {ecoModalOpen && (
        <CustomConfirmationModal
          message="Are you sure you want to delete this eco mode item?"
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelEcoMode}
        />
      )}
    </>
  )
}

export default EcoModes
