import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import toast, { Toaster } from 'react-hot-toast'
import { Tab, H1, Section, ButtonViolet } from '../utils/styles'
import useLoggedUser from '../hooks/useLoggedUser'
import { useApi } from '../hooks/useApi'
import { PanelForm } from '../utils/styles'

const URL = process.env.REACT_APP_SERVER_BASE_URL

export default function Tabs() {
  const [activeTab, setActiveTab] = useState('overview')
  const navigate = useNavigate()
  const { tab } = useParams()
  const { callApi } = useApi()

  useEffect(() => {
    if (tab) {
      setActiveTab(tab)
    }
  }, [tab])

  const handleTabClick = (tab: string) => {
    setActiveTab(tab)
    navigate(`/user/${tab}`)
  }

  return (
    <Section>
      <H1>Your account</H1>
      <Tab>
        <button onClick={() => handleTabClick('overview')} className={activeTab === 'overview' ? 'active' : ''}>
          Overview
        </button>
      </Tab>
      <div>{activeTab === 'overview' && <Overview />}</div>
    </Section>
  )
}

const PASSWORD_ERROR_MSG =
  'Password must be min 8 chars, with at least a symbol, upper and lower case letters, and a number'

const NOTIFICATIONS_LIMIT = 20

const Overview = () => {
  const { callApi } = useApi()
  const [password, setPassword] = useState<string | null>(null)
  const [confirmPassword, setConfirmPassword] = useState<string | null>(null)
  const { data: me } = useLoggedUser()

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (password !== confirmPassword) {
      toast.error('Both passwords must be the same')
      return
    }
    try {
      const response = await callApi(`/admin/users/${me?._id}`, { ...me, password: password }, 'PUT')
    } catch (error) {}
  }

  return (
    <Section>
      <Toaster position="top-right" />
      <br />
      <br />
      <br />
      <br />
      <div>
        <h3>Change password of {me?.email}</h3>
        <br />
        <PanelForm className="small" onSubmit={handleFormSubmit}>
          <div className="input-group">
            <label>New Password</label>
            <input
              type="password"
              value={password ?? ''}
              onChange={(e) => setPassword(e.target.value)}
              pattern="^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$"
              required
              title={PASSWORD_ERROR_MSG}
            />
          </div>
          <div className="input-group">
            <label>Confirm New Password</label>
            <input
              type="password"
              value={confirmPassword ?? ''}
              onChange={(e) => setConfirmPassword(e.target.value)}
              pattern="^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$"
              required
              title={PASSWORD_ERROR_MSG}
            />
          </div>
          <ButtonViolet type="submit">Change</ButtonViolet>
        </PanelForm>
      </div>
    </Section>
  )
}
