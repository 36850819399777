import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const URL = process.env.REACT_APP_SERVER_BASE_URL

interface Environment {
  namespace: string
  chainIds: number[]
  mimicId: string
}

const fetchEnvironments = async (): Promise<Environment[]> => {
  const response = await axios.get<Environment[]>(`${URL}/public/environments/`)
  return response.data
}

export const useEnvironments = () => {
  return useQuery<Environment[], Error>({
    queryKey: ['environments'],
    queryFn: fetchEnvironments,
  })
}
