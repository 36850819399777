import React from 'react'
import Gauge from './Gauge'
import { ChartCard } from '../../../utils/styles'
import useEnvironmentGas from '../../../hooks/useEnvironmentGas'
import useNetworkInfo from '../../../hooks/useNetworkInfo'
import { convertWeiToGwei } from '../../../utils/web3-utils'

export interface Props {
  chainId: number
}

export default function Gas({ chainId }: Props) {
  const { data, isLoading } = useEnvironmentGas(chainId)
  const { data: networkInfo } = useNetworkInfo(chainId)
  let percent = 0
  if (data?.currentGas && data?.medianGas) {
    percent =
      Math.abs(
        (Number(data.currentGas) - Number(data.medianGas)) / Math.max(Number(data.currentGas), Number(data.medianGas))
      ) * 100
  }

  return (
    <ChartCard className={isLoading ? 'loading' : ''}>
      {!isLoading && data && (
        <>
          <p className="title">Gas Price</p>
          <Gauge
            color={Number(data.currentGas) > Number(data.medianGas) ? '#F94872' : '#5CE6D5'}
            percent={Number(percent)}
          />
          <div className="relative">
            <h1>{data.currentGas} Gwei</h1>
            <p>Estimated: {data.medianGas} Gwei</p>
          </div>
          {networkInfo && (
            <div className="divided-text">
              <div>
                <h1>{(networkInfo.executorGasLimitBufferPct || '0') + ' %'} </h1>
                <p>Limit Buffer</p>
              </div>
              <div>
                <h1>{networkInfo.gasThresholdAmount ? convertWeiToGwei(networkInfo.gasThresholdAmount) : 0} gwei</h1>
                <p>Threshold</p>
              </div>
            </div>
          )}
        </>
      )}
    </ChartCard>
  )
}
