import React from 'react'
import styled from 'styled-components'
import Tooltip from '@mui/material/Tooltip'
import { TimelockItem } from 'API'
import Network from '../general/Network'

type BadgeProps = {
  timelock: TimelockItem
  className?: string
  children: React.ReactNode
}

const StyledBadge = styled.span`
  display: inline-flex;
  align-items: center;
  padding: 5px 7px;
  margin: 3px 0px;
  border-radius: 9px;
  font-size: 10px;
  font-weight: 500;
  color: #eb2f71;
  background: rgba(168, 154, 255, 0.15);
  .name {
    color: white;
  }
  img {
    padding-left: 7px;
    width: 15px;
    object-fit: scale-down;
  }
`

const TaskTime = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #fcce14;
`
const Flex = styled.div`
  margin: 4px 0;
  display: flex;
  align-items: center;
`

const TooltipContent = styled.div`
  padding: 8px;
  font-size: 0.875rem;
  line-height: 1.5;

  h4 {
    margin: 0 0 8px;
    font-size: 1rem;
  }

  ul {
    padding-left: 1.2em;
    margin: 0;
    list-style-type: disc;
  }
`

export function CalendarItem({ timelock, className, children }: BadgeProps) {
  const tooltipContent = (
    <TooltipContent>
      <h4>Timelock</h4>
      <ul>
        <li>Task Name: {timelock.task}</li>
        <li>Task address: -</li>
        <li>Chain ID: {timelock.chainId}</li>
        <li>Client: {timelock.environment}</li>
        <li>Start date: {timelock.start}</li>
        <li>End date: {timelock.end}</li>
      </ul>
    </TooltipContent>
  )
  return (
    <>
      <Tooltip title={tooltipContent} arrow>
        <StyledBadge className={className}>
          <div>
            <Flex>
              <TaskTime>
                {new Date(timelock.start).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
              </TaskTime>
              <Network network={timelock.chainId} small={true} />
            </Flex>
            <div className="name">{timelock.task}</div>
            {children}
          </div>
        </StyledBadge>
      </Tooltip>
    </>
  )
}
