import React from 'react'
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge'

interface GaugeComponentProps {
  percent: number
  color: string
}

const GaugeComponent: React.FC<GaugeComponentProps> = ({ percent, color }) => {
  return (
    <Gauge
      width={250}
      height={200}
      value={percent > 100 ? 100 : percent}
      startAngle={-110}
      endAngle={110}
      innerRadius="90%"
      text=""
      cornerRadius="50%"
      sx={{
        [`& .${gaugeClasses.valueArc}`]: {
          fill: color,
        },
        [`& .${gaugeClasses.referenceArc}`]: {
          fill: '#444456',
        },
      }}
    />
  )
}

export default GaugeComponent
