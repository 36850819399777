import JSBI from 'jsbi'
import Decimal from 'decimal.js'

export const NO_BREAK_SPACE = '\u00a0'
export const generalFormatPrice = '0.0000'
export const currencyFormatPrice = '0,0.00'

export function formatNumber(number: number | string | null | undefined): string {
  if (number === null || number === undefined) return '0'

  const numAsString = String(number)
  const [integer, decimals] = numAsString.split('.')

  return integer
    .split('')
    .reverse()
    .reduce(
      (result, digit, index) => {
        return digit + (index > 0 && index % 3 === 0 ? ',' : '') + result
      },
      decimals ? `.${decimals}` : ''
    )
}

export function divideRoundBigInt(dividend: JSBI | string | number, divisor: JSBI | string | number): string {
  const bigIntDividend = JSBI.BigInt(String(dividend))
  const bigIntDivisor = JSBI.BigInt(String(divisor))
  return JSBI.divide(JSBI.add(bigIntDividend, JSBI.divide(bigIntDivisor, JSBI.BigInt(2))), bigIntDivisor).toString()
}

interface FormatTokenAmountOptions {
  digits?: number
  symbol?: string
  displaySign?: boolean
}

export function formatTokenAmount(
  amount?: string | number,
  decimals: number = 0,
  { digits = 2, symbol = '', displaySign = false }: FormatTokenAmountOptions = {}
): string {
  if (!amount) return '0'
  let amountDecimal = new Decimal(amount)
  const decimalsDecimal = new Decimal(decimals)
  let digitsDecimal = new Decimal(digits)

  if (decimalsDecimal.isNegative()) {
    throw new Error('formatTokenAmount(): decimals cannot be negative')
  }

  if (digitsDecimal.isNegative()) {
    throw new Error('formatTokenAmount(): digits cannot be negative')
  }

  if (decimalsDecimal.lessThan(digitsDecimal)) {
    digitsDecimal = decimalsDecimal
  }

  const negative = amountDecimal.isNegative()

  if (negative) {
    amountDecimal = amountDecimal.neg()
  }

  const divisor = Decimal.pow(10, decimalsDecimal)
  const amountConverted = decimalsDecimal.equals(0)
    ? amountDecimal
    : amountDecimal.div(divisor).toFixed(digitsDecimal.toNumber(), Decimal.ROUND_HALF_UP)
  const amountConvertedStr = String(amountConverted)

  const leftPart = amountConvertedStr?.split('.')[0]
  let rightPart = amountConvertedStr.includes('.') ? amountConvertedStr.split('.')[1] : ''
  rightPart = rightPart.padEnd(digitsDecimal.toNumber(), '0').substring(0, digitsDecimal.toNumber())

  return [
    displaySign && negative ? '-' : '',
    leftPart,
    rightPart.length > 0 ? `.${rightPart}` : '',
    symbol ? ` ${symbol}` : '',
  ].join('')
}

export function percent(num: number): string {
  return new Intl.NumberFormat('default', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 4,
  }).format(num)
}

export function compact(number: number): string {
  if (number < 1000) {
    return new Intl.NumberFormat('default', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number)
  } else if (number >= 1000 && number < 1_000_000) {
    return (number / 1000).toFixed(1) + 'K'
  } else if (number >= 1_000_000 && number < 1_000_000_000) {
    return (number / 1_000_000).toFixed(1) + 'M'
  } else if (number >= 1_000_000_000 && number < 1_000_000_000_000) {
    return (number / 1_000_000_000).toFixed(1) + 'B'
  } else if (number >= 1_000_000_000_000 && number < 1_000_000_000_000_000) {
    return (number / 1_000_000_000_000).toFixed(1) + 'T'
  } else {
    return number.toString()
  }
}

export function formatPrice(value: number | string, currency: string = '$'): string {
  return `${currency}${parseFloat(String(value)).toFixed(2)}`
}

export function formatPrices(amount: number, currency: string = 'USD', locale: string = 'en-US'): string {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
  }).format(amount)
}

function formatUnitsDecimals(value: string | number, unit: number = 18): string {
  const unitValue = new Decimal(10).pow(unit)
  const decimalValue = new Decimal(value)
  const formattedValue = decimalValue.dividedBy(unitValue)
  return formattedValue.toString()
}

export function formatBNPrice(value: string = '', decimals: number = 18): string {
  const Dec = Decimal.clone({ precision: 50, defaults: true })
  if (value.toString() === 'NaN') return '0'
  if (!value.toString()) return '0'
  const a = new Dec(value.toString())
  if (!a.isFinite()) return '0'
  const priceDecimal = formatUnitsDecimals(a.toFixed(0), decimals)
  const b = new Dec(priceDecimal)
  const price = formatPrices(b.toNumber())
  return price
}

export function formatAmountPrice(value: string = '', price: number = 0, decimals: number = 18): string {
  const Dec = Decimal.clone({ precision: 50, defaults: true })
  if (value.toString() === 'NaN') return '0'
  if (!value.toString()) return '0'
  const a = new Dec(value.toString())
  if (!a.isFinite()) return '0'
  const priceDecimal = formatUnitsDecimals(a.toFixed(0), decimals)
  const b = new Dec(priceDecimal).mul(new Dec(price))
  const priceResponse = b.toFixed()
  return priceResponse
}

export function aLessB(b: number | string, a: number | string): number {
  if (!a || !b) return 0
  const ab = new Decimal(b).minus(a)
  return parseFloat(ab.toFixed())
}

export function capitalizeFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function parseName(name: string): string {
  return name
    .split(/[-\s]+|(?=[A-Z])/)
    .map((word) => capitalizeFirstLetter(word))
    .join(' ')
}
