import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Select from 'react-select'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { InnerSection as Section, ButtonViolet, Flexbox } from '../../../utils/styles'
import useTasks from '../../../hooks/useTasks'
import VolumeChart from './VolumeChart'
import Calendar from '../../Calendar'
import FeesChart from './FeesChart'
import GasChart from './GasChart'
import GasUsdChart from './GasUsdChart'
import SimulationsChart from './SimulationsChart'
import SlippageChart from './SlippageChart'
import ExecutionsDelayedChart from './ExecutionsDelayedChart'
import ExecutionsSucceededChart from './ExecutionsSucceededChart'
import ExecutionsRevertedChart from './ExecutionsRevertedChart'
import ExecutionsUnreachedChart from './ExecutionsUnreachedChart'
import useVolume from '../../../hooks/useVolume'
import useFees from '../../../hooks/useFees'
import useGas from '../../../hooks/useGas'
import useSlippage from '../../../hooks/useSlippage'
import useSimulations from '../../../hooks/useSimulations'

interface StatsProps {
  chainId: number
}

const Stats: React.FC<StatsProps> = ({ chainId }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams<{ id: string }>()
  const tasks = useTasks(String(chainId), params.id)
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [component, setComponent] = useState<boolean>(false)
  const [selectedTasks, setSelectedTasks] = useState<string[]>([])

  const handleStartDateChange = (date: Date | null) => {
    setStartDate(date)
    setComponent(false)
  }

  const handleEndDateChange = (date: Date | null) => {
    setEndDate(date)
    setComponent(false)
  }

  const generateCharts = () => {
    if (startDate && endDate) {
      const searchParams = new URLSearchParams()
      searchParams.set('startDate', startDate.toISOString())
      searchParams.set('endDate', endDate.toISOString())
      navigate(`${location.pathname}?${searchParams.toString()}`)
      setComponent(true)
    }
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const startDateParam = searchParams.get('startDate')
    const endDateParam = searchParams.get('endDate')

    if (startDateParam && endDateParam) {
      setStartDate(new Date(startDateParam))
      setEndDate(new Date(endDateParam))
      setComponent(true)
    }
  }, [location.search])

  const showOptions = () => {
    if (!tasks?.data || !Array.isArray(tasks.data.tasks)) return []
    return tasks.data.tasks.map((item) => ({
      value: item.id,
      label: item.name,
    }))
  }

  const handleSelectMultiTasks = (e: unknown) => {
    if (Array.isArray(e)) {
      const statuses = e.map((s) => s.value)
      setSelectedTasks(statuses)
    } else {
      setSelectedTasks([])
    }
  }

  return (
    <Section>
      <br />
      <br />
      <Flex>
        <Calendar label="From date" selectedDate={startDate} onChange={handleStartDateChange} />
        <Calendar label="To date" selectedDate={endDate} onChange={handleEndDateChange} />
        <StyledSelect
          classNamePrefix="Select"
          isMulti
          onChange={handleSelectMultiTasks}
          options={showOptions()}
          placeholder="Tasks..."
        />
        <ButtonViolet onClick={generateCharts}>Generate Charts</ButtonViolet>
      </Flex>
      {component && (
        <Flex>
          <Charts startDate={startDate} endDate={endDate} tasks={selectedTasks} chainId={String(chainId)} />
        </Flex>
      )}
    </Section>
  )
}

interface ChartsProps {
  startDate: Date | null
  endDate: Date | null
  tasks: string[]
  chainId: string
}

const Charts: React.FC<ChartsProps> = ({ startDate, endDate, tasks, chainId }) => {
  const params = useParams<{ id: string }>()

  const { data: volume } = useVolume(params.id, startDate, endDate, tasks, chainId)
  const { data: fees } = useFees(params.id, startDate, endDate, tasks, chainId)
  const { data: gas } = useGas(params.id, startDate, endDate, tasks, chainId)
  const { data: slippage } = useSlippage(params.id, startDate, endDate, tasks, chainId)
  const { data: simulations } = useSimulations(params.id, startDate, endDate, tasks, chainId)

  return (
    <div>
      {volume && (
        <Box>
          <VolumeChart data={volume} />
        </Box>
      )}
      {fees && (
        <Box>
          <FeesChart data={fees} />
        </Box>
      )}
      {gas && (
        <Box>
          <GasChart data={gas} />
        </Box>
      )}
      {gas && (
        <Box>
          <GasUsdChart data={gas} />
        </Box>
      )}
      {slippage && (
        <Box>
          <SlippageChart data={slippage} />
        </Box>
      )}
      {simulations && (
        <Box>
          <SimulationsChart data={simulations} />
        </Box>
      )}
      {simulations && (
        <Box>
          <ExecutionsSucceededChart data={simulations} />
        </Box>
      )}
      {simulations && (
        <Box>
          <ExecutionsDelayedChart data={simulations} />
        </Box>
      )}
      {simulations && (
        <Box>
          <ExecutionsRevertedChart data={simulations} />
        </Box>
      )}
      {simulations && (
        <Box>
          <ExecutionsUnreachedChart data={simulations} />
        </Box>
      )}
    </div>
  )
}

const Flex = styled(Flexbox)`
  align-items: center;
  justify-content: center;
  button {
    margin-top: 0;
  }
`

const StyledSelect = styled(Select)`
  .Select__control {
    min-width: 300px;
    border-radius: 10px;
    color: white;
    background: rgba(168, 154, 255, 0.1);
    border: 0px;
    cursor: pointer;
  }

  .Select__menu {
    color: white;
    background: rgba(168, 154, 255, 0.1);
    .Select__option:hover,
    .Select__option--is-focused {
      color: white;
      background: #6f5ce6;
    }
  }
`

const Box = styled.div`
  float: left;
  width: 800px;
  height: 500px;
  margin: 30px 20px;
`

export default Stats
