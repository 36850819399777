import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { EnvironmentResponse } from '../types/API'
import { useApi } from './useApi'

const useEnvironmentList = (id?: string): UseQueryResult<EnvironmentResponse | null> => {
  const { callApi } = useApi()

  const fetchEnvironment = async (): Promise<EnvironmentResponse | null> => {
    const url = '/relayer-executor/environments'
    const data = await callApi<EnvironmentResponse[]>(url)

    const found = data?.find((e) => e.mimicId.toLowerCase() === id?.toLowerCase()) || null
    return found
  }

  return useQuery<EnvironmentResponse | null>({
    queryKey: ['environmentList', id],
    queryFn: fetchEnvironment,
    enabled: !!id,
  })
}

export default useEnvironmentList
