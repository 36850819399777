import { useQuery } from '@tanstack/react-query'
import { useApi } from './useApi'

type TokenFilter = {
  chainId: number
}

type SetState<T> = React.Dispatch<React.SetStateAction<T>>

export type TokenPrice = {
  address: string
  price: number
  date?: string
}

const useTokenPrices = (
  tokenList: string[],
  filter: TokenFilter,
  limit = 50,
  setLoadPage: SetState<number>,
  setTotalPages: SetState<number>
) => {
  const { callApi } = useApi()

  const fetchAllPrices = async (): Promise<TokenPrice[]> => {
    let allData: TokenPrice[] = []

    // Function to split the tokenList into chunks of a given size
    const chunkArray = (array: string[], size: number): string[][] => {
      return array.reduce((acc, _, i) => (i % size ? acc : [...acc, array.slice(i, i + size)]), [] as string[][])
    }

    // Split the tokenList into chunks
    const tokenChunks = chunkArray(tokenList, limit)

    setTotalPages(tokenChunks.length)
    for (let i = 0; i < tokenChunks.length; i++) {
      const chunk = tokenChunks[i]

      try {
        const data = await fetchPrices(chunk, filter, limit)
        setLoadPage(i + 1)
        if (data) {
          allData = allData.concat(data)
        }
      } catch (error) {
        console.error('dataTokenPrices Error fetching data for chunk', i, error)
      }
    }

    return allData
  }

  const fetchPrices = async (tokenList: string[], filter: TokenFilter, limit: number): Promise<TokenPrice[]> => {
    if (!tokenList) return []

    const params = {
      addresses: tokenList,
      limit,
      chainId: parseInt(filter.chainId),
    }


    return (await callApi<TokenPrice[]>('/price-oracle/prices/last', params)) || []
  }

  return useQuery<TokenPrice[], Error>({
    queryKey: ['tokenPrices', JSON.stringify(tokenList), filter.chainId],
    queryFn: fetchAllPrices,
    enabled: tokenList?.length > 0,
  })
}

export default useTokenPrices
