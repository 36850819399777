import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { useApi } from './useApi'

type TaskMetadata = Record<string, unknown>

const useTaskMetadata = (id: string, isOpen: boolean): UseQueryResult<TaskMetadata> => {
  const { callApi } = useApi()

  const fetchTokenInfo = async (): Promise<TaskMetadata> => {
    if (!id) throw new Error('No id provided')

    const url = `/relayer-executor/task-executions/${id}/metadata`

    return await callApi<TaskMetadata>(url)
  }

  return useQuery<TaskMetadata>({
    queryKey: ['useTaskMetadata', id],
    queryFn: fetchTokenInfo,
    enabled: isOpen,
  })
}

export default useTaskMetadata
