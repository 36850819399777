import React, { useState } from 'react'
import { ThemeProvider } from 'styled-components'
import { NextUIProvider } from '@nextui-org/react'
import { Route, Routes, Navigate, HashRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import Login from './components/Login'
import Dashboard from './components/structure/Dashboard'
import ErrorAlert from './components/ErrorAlert'
import { SubgraphsProvider } from './context/SubgraphsContext'
import { AuthProvider, useAuth } from './context/AuthContext'
import theme from './styles/theme'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retryDelay: 1000,
    },
  },
})

function ProtectedRoute({ children }: { children: JSX.Element }) {
  const { user } = useAuth()
  if (!user) {
    return <Navigate to="/login" replace />
  }
  return children
}

export default function App() {
  const [error, setError] = useState<string | null>(null)

  const closeError = () => setError(null)
  const showError = (message: string) => setError(message)

  return (
    <HashRouter>
      <NextUIProvider>
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <AuthProvider>
              <SubgraphsProvider>
                {error && <ErrorAlert message={error} onClose={closeError} />}
                <Routes>
                  <Route path="/" element={<Navigate to="/login" replace />} />
                  <Route path="/login" element={<Login showError={showError} />} />
                  <Route
                    path="/dashboard/*"
                    element={
                      <ProtectedRoute>
                        <Dashboard />
                      </ProtectedRoute>
                    }
                  />
                </Routes>
              </SubgraphsProvider>
            </AuthProvider>
          </QueryClientProvider>
        </ThemeProvider>
      </NextUIProvider>
    </HashRouter>
  )
}
