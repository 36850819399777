import styled from 'styled-components'
import { PanelForm } from '../../utils/styles'

export const PasswordForm = styled(PanelForm)`
  padding: 10px 20px;
  background: #292939;
  border-radius: 16px;
  button {
    padding: 7px 10px;
    border-radius: 14px;
    font-size: 14px;
  }
  margin-bottom: 20px;
`

export const Detail = styled.div<{ isopen: string }>`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 100;
  word-break: break-word;
  .label {
    color: ${({ theme }) => theme.textGrey};
  }

  .overlay {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    opacity: 0;
    will-change: opacity;
    pointer-events: none;
    transition: opacity 0.3s cubic-bezier(0, 0, 0.3, 1);
  }

  ${({ isopen }) => isopen === 'true' && '.overlay { opacity: 1; pointer-events: auto;}'};

  .wrap {
    position: fixed;
    box-sizing: border-box;
    height: 100%;
    width: 464px;
    @media only screen and (max-width: 510px) {
      width: 100%;
    }
    padding: 50px;
    background: #121317;
    overflow-y: auto;
    overflow-x: hidden;
    transform: translateX(-100%);
    will-change: transform;
    z-index: 101;
    pointer-events: auto;
    transition: transform 130ms ease-out;
    right: 0;
    transform: translateX(100%);
  }

  ${({ isopen }) =>
    isopen === 'true' && '.wrap { transform: none; pointer-events: auto; transition: transform 330ms ease-in;}'};

  h2 {
    text-align: left !important;
  }
  .token {
    width: 26px;
    padding-left: 7px;
  }
  h4 {
    display: flex;
    align-items: center;
    cursor: pointer;
    img {
      padding-left: 10px;
      padding-right: 10px;
      height: 13px;
    }
  }

  .upside-down {
    transform: rotate(180deg);
  }
`

export const ButtonColor = styled.button`
  display: inline-block;
  width: auto;
  border: 0px;
  background: transparent;
  color: #6f5ce6;
  font-family: 'DMSans';
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  cursor: pointer;

  &:hover {
    color: #9a89ff;
  }

  &:disabled {
    color: #c0c0c0;
    cursor: not-allowed;
  }
`

export const NotificationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`

export const AllNotificationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  div {
    width: 100%;
    box-sizing: border-box;
  }
`
