import React, { useState } from 'react'
import styled from 'styled-components'
import TokenRegistryForm from './TokenRegistryForm'
import MiniSwitch from '../formUtils/MiniSwitch'
import CustomConfirmationModal from '../CustomConfirmationModal'
import Address from '../general/Address'
import FormModal from '../FormModal'
import Price from './Price'
import deleteIcon from '../../assets/delete.png'
import edit from '../../assets/edit.png'
import save from '../../assets/save.png'
import cancel from '../../assets/cancel.png'
import Network from '../general/Network'
import { useTokenRegistry } from '../../hooks/useTokenRegistry'
import { useApi } from '../../hooks/useApi'
import {
  ContainerTable,
  StickyTable,
  LittleButton,
  Filter,
  Filters,
  Flexbox,
  Loader,
  ButtonViolet,
  Right,
  Section,
} from '../../utils/styles'
import { Toaster } from 'react-hot-toast'

interface TokenRegistryData {
  _id: string
  address: string
  chainId: number
  decimals: number
  spamCounter: number
  priority: number
  isNativeToken: boolean
  enabled: boolean
  isWrappedNativeToken: boolean
  name: string
  symbol: string
}

const TokenRegistry: React.FC = () => {
  const { callApi } = useApi()
  const [symbolFilter, setSymbolFilter] = useState<string>('')
  const [addressFilter, setAddressFilter] = useState<string>('')
  const [chainIdFilter, setChainIdFilter] = useState<number | any>('')
  const [isNativeFilter, setIsNativeFilter] = useState<boolean | null>(null)
  const [enabledFilter, setEnabledFilter] = useState<boolean | null>(null)
  const [isWrappedNativeFilter, setIsWrappedNativeFilter] = useState<boolean | null>(null)

  const [currentPage, setCurrentPage] = useState<number>(1)

  const [customModalOpen, setCustomModalOpen] = useState(false)
  const [addNew, setAddNew] = useState(false)
  const [deleteParams, setDeleteParams] = useState<any>('')

  const [editedProps, setEditedProps] = useState<TokenRegistryData | any>(null)
  const [editIndex, setEditIndex] = useState<number | any>(null)

  const filters = {
    chainIdFilter,
    symbolFilter,
    addressFilter,
    isNativeFilter,
    enabledFilter,
    isWrappedNativeFilter,
  }

  const { data: tokenRegistryData, totalPages, totalItems, isLoading, refetch } = useTokenRegistry(currentPage, filters)

  const handleSymbolFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSymbolFilter(event.target.value)
  }

  const handleChainIdFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChainIdFilter(parseInt(event.target.value))
  }

  const handleAddressFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddressFilter(event.target.value)
  }

  const handleIsNativeFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value
    setIsNativeFilter(value === 'yes' ? true : value === 'no' ? false : null)
  }

  const handleEnabledFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value
    setEnabledFilter(value === 'yes' ? true : value === 'no' ? false : null)
  }

  const handleIsWrappedNativeFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value
    setIsWrappedNativeFilter(value === 'yes' ? true : value === 'no' ? false : null)
  }

  const handleDeleteClick = (item: any) => {
    setDeleteParams(item)
    setCustomModalOpen(true)
  }

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1)
      refetch()
    }
  }

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
      refetch()
    }
  }

  const handleEdit = (item: TokenRegistryData, index: number) => {
    setEditIndex(index)
    setEditedProps(item)
  }

  const handleCancelEdit = () => {
    setEditIndex(null)
  }

  const handleSaveEdit = async () => {
    try {
      await callApi(
        `/token-registry/tokens/${editedProps.chainId}/${editedProps.address}`,
        { ...editedProps, decimals: parseInt(editedProps.decimals) },
        'PUT'
      )
      refetch(currentPage)
    } catch (error) {
      console.error('There was an error updating the token list item:', error)
    }

    setEditIndex(null)
  }

  const handleAddNewConfirm = () => {
    refetch(1)
    setAddNew(false)
  }

  const handleAddNewCancel = () => {
    setAddNew(false)
  }

  return (
    <Section>
      <Toaster position="top-right" />
      <Right>
        <ButtonViolet onClick={() => setAddNew(!addNew)}>+ Add new</ButtonViolet>
      </Right>
      {addNew && (
        <FormModal onConfirm={handleAddNewConfirm} onCancel={handleAddNewCancel}>
          <TokenRegistryForm onSuccess={handleAddNewConfirm} />
        </FormModal>
      )}
      <Filters>
        <Filter>
          <label>Symbol</label>
          <input type="text" placeholder="Filter by Symbol" value={symbolFilter} onChange={handleSymbolFilterChange} />
        </Filter>
        <Filter>
          <label>Address</label>
          <input
            type="text"
            placeholder="Filter by Address"
            value={addressFilter}
            onChange={handleAddressFilterChange}
          />
        </Filter>
        <Filter>
          <label>Chain</label>
          <input
            type="number"
            placeholder="Filter by Chain"
            value={chainIdFilter}
            onChange={handleChainIdFilterChange}
          />
        </Filter>
        <Filter>
          <label>Native</label>
          <select
            value={isNativeFilter === null ? 'all' : isNativeFilter ? 'yes' : 'no'}
            onChange={handleIsNativeFilterChange}
          >
            <option value="all">All</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </Filter>
        <Filter>
          <label>Enabled</label>
          <select
            value={enabledFilter === null ? 'all' : enabledFilter ? 'yes' : 'no'}
            onChange={handleEnabledFilterChange}
          >
            <option value="all">All</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </Filter>
        <Filter>
          <label>Wrapped</label>
          <select
            value={isWrappedNativeFilter === null ? 'all' : isWrappedNativeFilter ? 'yes' : 'no'}
            onChange={handleIsWrappedNativeFilterChange}
          >
            <option value="all">All</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </Filter>
      </Filters>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {tokenRegistryData?.data && (
            <>
              <StickyTable>
                <Table>
                  <thead>
                    <tr>
                      <th>Address</th>
                      <th>Symbol</th>
                      <th>Name</th>
                      <th>Network</th>
                      <th>Decimals</th>
                      <th>Spam</th>
                      <th>Priority</th>
                      <th>Enabled</th>
                      <th>Native</th>
                      <th>Wrapped</th>
                      <th>Price</th>
                      <th>Actions</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.values(tokenRegistryData.data).map((item, index) => (
                      <tr key={index}>
                        <td className="accent">
                          {editIndex === index ? (
                            <input
                              type="text"
                              className="large"
                              value={editedProps?.address}
                              onChange={(e) =>
                                setEditedProps({
                                  ...editedProps,
                                  address: e.target.value,
                                })
                              }
                            />
                          ) : (
                            <Address address={item.address} short={true} showIdentity={false} chainId={item.chainId} />
                          )}
                        </td>
                        <td>
                          {editIndex === index ? (
                            <input
                              type="text"
                              value={editedProps?.symbol}
                              onChange={(e) =>
                                setEditedProps({
                                  ...editedProps,
                                  symbol: e.target.value,
                                })
                              }
                            />
                          ) : (
                            item.symbol
                          )}
                        </td>
                        <td>
                          {editIndex === index ? (
                            <input
                              type="text"
                              value={editedProps?.name}
                              onChange={(e) =>
                                setEditedProps({
                                  ...editedProps,
                                  name: e.target.value,
                                })
                              }
                            />
                          ) : (
                            item.name
                          )}
                        </td>
                        <td>
                          {editIndex === index ? (
                            <input
                              type="text"
                              className="medium"
                              value={editedProps?.chainId}
                              onChange={(e) =>
                                setEditedProps({
                                  ...editedProps,
                                  chainId: e.target.value,
                                })
                              }
                            />
                          ) : (
                            <Network network={item.chainId} />
                          )}
                        </td>
                        <td>
                          {editIndex === index ? (
                            <input
                              type="text"
                              className="short"
                              value={editedProps?.decimals}
                              onChange={(e) =>
                                setEditedProps({
                                  ...editedProps,
                                  decimals: e.target.value,
                                })
                              }
                            />
                          ) : (
                            item.decimals
                          )}
                        </td>
                        <td>
                          {editIndex === index ? (
                            <input
                              type="text"
                              className="short"
                              value={editedProps?.spamCounter}
                              onChange={(e) =>
                                setEditedProps({
                                  ...editedProps,
                                  spamCounter: e.target.value,
                                })
                              }
                            />
                          ) : (
                            item.spamCounter
                          )}
                        </td>
                        <td>
                          {editIndex === index ? (
                            <input
                              type="text"
                              className="short"
                              value={editedProps?.priority}
                              onChange={(e) =>
                                setEditedProps({
                                  ...editedProps,
                                  priority: e.target.value,
                                })
                              }
                            />
                          ) : (
                            item.priority
                          )}
                        </td>
                        <td>
                          {editIndex === index ? (
                            <MiniSwitch
                              ison={editedProps.enabled}
                              onToggle={() =>
                                setEditedProps({
                                  ...editedProps,
                                  enabled: !editedProps.enabled,
                                })
                              }
                            />
                          ) : item.enabled ? (
                            '✅'
                          ) : (
                            '❌'
                          )}
                        </td>
                        <td>
                          {editIndex === index ? (
                            <MiniSwitch
                              ison={editedProps.isNativeToken}
                              onToggle={() =>
                                setEditedProps({
                                  ...editedProps,
                                  isNativeToken: !editedProps.isNativeToken,
                                })
                              }
                            />
                          ) : item.isNativeToken ? (
                            '✅'
                          ) : (
                            '❌'
                          )}
                        </td>
                        <td>
                          {editIndex === index ? (
                            <MiniSwitch
                              ison={editedProps.isWrappedNativeToken}
                              onToggle={() =>
                                setEditedProps({
                                  ...editedProps,
                                  isWrappedNativeToken: !editedProps.isWrappedNativeToken,
                                })
                              }
                            />
                          ) : item.isWrappedNativeToken ? (
                            '✅'
                          ) : (
                            '❌'
                          )}{' '}
                        </td>
                        <td>
                          <Price item={item} />
                        </td>
                        <td>
                          {editIndex === index ? (
                            <Flexbox>
                              <img onClick={handleSaveEdit} src={save} alt="Save" />
                              <img onClick={handleCancelEdit} src={cancel} alt="Cancel" />
                            </Flexbox>
                          ) : (
                            <img onClick={() => handleEdit(item, index)} src={edit} alt="Edit" />
                          )}
                        </td>
                        <td>
                          <img onClick={() => handleDeleteClick(item)} src={deleteIcon} alt="Delete" />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </StickyTable>
              <Pagination>
                <LittleButton onClick={handlePrevPage} disabled={currentPage === 1}>
                  Previous
                </LittleButton>
                <p>
                  Page {currentPage} of {tokenRegistryData.pages}, Total Items: {tokenRegistryData.total}
                </p>
                <LittleButton onClick={handleNextPage} disabled={currentPage === tokenRegistryData.pages}>
                  Next
                </LittleButton>
              </Pagination>
              {customModalOpen && (
                <CustomConfirmationModal
                  message="Are you sure you want to delete this token list item?"
                  onConfirm={() => console.log('Confirm delete')}
                  onCancel={() => console.log('Cancel delete')}
                />
              )}
            </>
          )}
        </>
      )}
    </Section>
  )
}

const Table = styled(ContainerTable)`
  .accent {
    a {
      color: #33c2b0 !important;
    }
  }
`

const Pagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  button {
    margin-top: 0;
  }
  p {
    margin: 0 20px;
  }
`

export default TokenRegistry
