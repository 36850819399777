import { useContext } from 'react'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { request, gql } from 'graphql-request'
import SubgraphsContext from '../context/SubgraphsContext'
import { Environment } from '../types/graphql'

const useTasks = (chainId: string, id?: string): UseQueryResult<Environment | null> => {
  const subgraphs = useContext(SubgraphsContext)

  return useQuery<Environment | null>({
    queryKey: ['useTasks', id + chainId],
    queryFn: () => fetchTasks(chainId, id!, subgraphs),
    enabled: !!id && !!subgraphs && Object.keys(subgraphs).length > 0,
  })
}

const fetchTasks = async (
  chainId: string,
  id: string,
  subgraphs: Record<string, string>
): Promise<Environment | null> => {
  try {
    const environmentId = id.toLowerCase()
    const data = await request<{ environment: Environment }>(
      subgraphs[chainId],
      gql`
        query TasksQuery($id: String!) {
          environment(id: $id) {
            namespace
            id
            tasks {
              id
              name
              permissions
              taskConfig {
                connector
                acceptanceList {
                  id
                  tokens
                  type
                }
                defaultTokenThreshold {
                  max
                  min
                  token {
                    decimals
                    id
                    name
                    symbol
                  }
                }
                defaultVolumeLimit {
                  amount
                  id
                  period
                }
                defaultTokenOut {
                  decimals
                  id
                  name
                  symbol
                }
                defaultDestinationChain
                defaultMaxSlippage
                defaultMaxBridgeFee {
                  amount
                  token {
                    decimals
                    id
                    name
                    symbol
                  }
                }
                gasLimits {
                  gasPriceLimit
                  priorityFeeLimit
                  txCostLimit
                  txCostLimitPct
                }
                nextBalanceConnector
                previousBalanceConnector
                recipient
                timeLock {
                  window
                  mode
                  allowedAt
                  frequency
                }
              }
            }
          }
        }
      `,
      { id: environmentId }
    )
    return data.environment
  } catch (error) {
    console.error(`Error fetching subgraph for chain ${chainId}:`, error)
    return null
  }
}

export default useTasks
