import { useQuery } from '@tanstack/react-query'
import { useApi } from './useApi'
import { GasUsedResponse } from '../types/API'

const useGas = (
  id: string | undefined,
  startDate: Date | null,
  endDate: Date | null,
  tasks: string[],
  chainId: string
) => {
  const { callApi } = useApi()

  const fetchGas = async (): Promise<GasUsedResponse> => {
    const url = `/relayer-executor/environments/${id}/gas-used`

    const params = {
      chainIds: [chainId],
      startDate: startDate?.toISOString(),
      endDate: endDate?.toISOString(),
      tasks,
    }

    return await callApi<GasUsedResponse>(url, { ...params })
  }

  return useQuery<GasUsedResponse>({
    queryKey: ['gas', id, startDate, endDate, tasks, chainId],
    queryFn: fetchGas,
    enabled: !!id && !!startDate && !!endDate,
  })
}

export default useGas
