// @ts-nocheck
import React, { useState, useEffect } from 'react'
import { parseName } from '../../../utils/math-utils'
import { useApi } from '../../../hooks/useApi'
import { ButtonViolet, ButtonWhite, Message } from '../../../utils/styles'
import CustomInput from '../../general/Form'
import { Form } from '../../../utils/typescript-styles'

const URL = process.env.REACT_APP_SERVER_BASE_URL

interface RelayerExecutorFormProps {
  onSuccess?: () => void
}

interface RelayerData {
  _id: string
  rateLimitRequests: number
  rateLimitWindowMs: number
  corsAllowedOrigins: string[]
  relayerAddresses: string[]
}

const RelayerExecutorForm: React.FC<RelayerExecutorFormProps> = ({ onSuccess = () => {} }) => {
  const [message, setMessage] = useState('')
  const [relayerData, setRelayerData] = useState<RelayerData | null>(null)
  const [editedSettings, setEditedSettings] = useState<Partial<RelayerData> | null>(null)
  const { callApi } = useApi()

  useEffect(() => {
    const fetchApiSettings = async () => {
      try {
        const data = await callApi('/relayer-executor/settings')
        setRelayerData(data)
        setEditedSettings(data)
      } catch (error) {
        console.error('There was an error with relayer executor form data:', error)
      }
    }

    fetchApiSettings()
  }, [])

  const handleCancelEditClick = () => {
    setEditedSettings(relayerData)
  }

  const handleSaveClick = async (e: React.FormEvent) => {
    e.preventDefault()
    try {
      await callApi(
        '/relayer-executor/settings',
        {
          ...editedSettings,
          relayerAddresses: editedSettings.relayerAddresses.filter((val) => val !== ''),
        },
        'PUT'
      )
    } catch (error) {
      console.error('Error saving API settings:', error)
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target

    setEditedSettings((prevSettings: any) => {
      let updatedValue = e.target.type === 'number' ? parseFloat(value) : value
      if (name === 'relayerAddresses') {
        updatedValue = value.split(/,\s*/)
      }

      return {
        ...prevSettings,
        [name]: updatedValue,
      }
    })
  }

  return (
    <Form onSubmit={handleSaveClick}>
      {message !== '' ? (
        <Message>
          <span>{message}</span>
          <span className="close" onClick={() => setMessage('')}>
            X
          </span>
        </Message>
      ) : (
        <>
          <div className="container">
            {editedSettings &&
              Object.entries(editedSettings as RelayerData).map(
                ([key, value]) =>
                  key !== '__v' &&
                  key !== 'createdAt' &&
                  key !== 'updatedAt' &&
                  key !== 'lastUpdate' &&
                  key !== '_id' && (
                    <div key={key} className="input-group">
                      {key === 'relayerAddresses' ? (
                        <CustomInput
                          type="text"
                          name={key}
                          label={key === 'relayerAddresses' ? parseName(key) + ' (comma-separated)' : parseName(key)}
                          value={String(value.join(', '))}
                          onChange={handleInputChange}
                          disabled={key === '_id'}
                        />
                      ) : (
                        <CustomInput
                          type={typeof value === 'number' ? 'number' : 'text'}
                          name={key}
                          label={key === 'relayerAddresses' ? parseName(key) + ' (comma-separated)' : parseName(key)}
                          value={typeof value === 'number' ? value : String(value)}
                          onChange={handleInputChange}
                          disabled={key === '_id'}
                        />
                      )}
                    </div>
                  )
              )}
          </div>
          <div className="buttons">
            <ButtonViolet type="submit">Save</ButtonViolet>
            <ButtonWhite onClick={handleCancelEditClick}>Cancel</ButtonWhite>
          </div>
        </>
      )}
    </Form>
  )
}

export default RelayerExecutorForm
