/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { NavLink } from 'react-router-dom'
import { Loader } from '../../utils/styles'
import { Toaster } from 'react-hot-toast'
import NotificationCard from './notifications/NotificationCard'
import useAdminNotifications from '../../hooks/useAdminNotifications'
import { ButtonColor, Detail, NotificationsContainer } from './styles'

interface Props {
  open: boolean
  onClose: () => void
}
const NOTIFICATIONS_LIMIT = 20

const UserPanel = ({ open, onClose }: Props) => {
  const {
    notifications,
    isLoading: notificationsLoading,
    isFetching,
    dismissedNotifications,
    dismissNotification,
    showNotificationsAgain,
    fetchNextPage,
  } = useAdminNotifications({ limit: NOTIFICATIONS_LIMIT })

  const unviewedNotifications = notifications?.filter((notif) => !dismissedNotifications.has(notif._id))

  const showLoadMoreButton = notifications.length % NOTIFICATIONS_LIMIT === 0

  return (
    <Detail isopen={open.toString()}>
      <Toaster position="top-right" />
      <div className="overlay" onClick={onClose} />
      <div className="wrap" onClick={(e: any) => e.target.nodeName.toLowerCase() === 'a' && onClose()}>
        <NotificationsContainer>
          <NavLink
            to="/dashboard/notifications"
            onClick={() => {
              onClose()
            }}
          >
            <ButtonColor>See all notifications</ButtonColor>
          </NavLink>
          {notificationsLoading && <Loader />}
          {unviewedNotifications &&
            unviewedNotifications.length > 0 &&
            unviewedNotifications
              .filter((notification) => notification.severity === 'critical')
              .map((notification) => (
                <NotificationCard key={notification._id} notification={notification} onDismiss={dismissNotification} />
              ))}
          {unviewedNotifications && unviewedNotifications.length === 0 && (
            <ButtonColor onClick={showNotificationsAgain}>Show dismissed notifications</ButtonColor>
          )}
          {showLoadMoreButton && (
            <ButtonColor disabled={isFetching} onClick={() => fetchNextPage()}>
              {isFetching ? 'Loading...' : 'Load more notifications'}
            </ButtonColor>
          )}
        </NotificationsContainer>
      </div>
    </Detail>
  )
}

export default UserPanel
