import styled from 'styled-components'
import { ContainerTable } from '../../../utils/styles'
import TableCell from './TableCell'

export const Heading = styled.div`
  font-size: 20px;
  font-weight: 600;
  padding: 25px;
`

export const FlexMenu = styled.div`
  width: 100%;
  max-width: 900px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0px auto;
  min-width: 874px;
  max-width: 100%;
`

export const Td = styled.td<{ lite?: boolean; align?: string; colSpan?: number }>`
  padding: 10px 5px;
  color: ${(props) => props.theme.textWhite};
  width: ${(props) => (props.lite ? '200px' : 'none')};
  vertical-align: middle;
  font-size: 15px;
  text-align: ${(props) => props.align};
  ${(props) => (props.colSpan ? `col-span: ${props.colSpan};` : '')}
  @media only screen and (max-width: 700px) {
    padding: 20px 10px;
  }
`

export const Content = styled.div<{ align?: string }>`
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.align === 'center' ? 'center' : 'flex-start')};
`

export const ActivitySection = styled.section`
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 874px;
  max-width: 90%;
  select {
    padding: 6px 13px 6px 16px;
    border: 0px;
    background: #232632;
    color: white;
    border-radius: 10px;
    font-family: 'DMSans';
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    outline: none !important;
  }

  .custom-select-container {
    position: relative;
    display: inline-block;
    margin-right: 20px;
  }

  .custom-plan-container {
    position: relative;
    display: inline-block;
    /* margin-right: 20px; */
  }

  .custom-load-container {
    display: flex;
    margin: 0px 20px;
    color: rgb(118, 118, 118);
    font-size: 14px;
  }

  .loading {
    color: #d2d31663;
    padding-left: 5px;
  }

  .done {
    color: #33c2b0;
    padding-left: 5px;
  }

  .custom-realtime-container {
    display: inline-block;
    color: rgb(118, 118, 118);
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .custom-loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    margin-bottom: 21px;
  }

  .custom-select {
    padding: 6px 13px 6px 16px;
    min-width: 150px;
    border: 0px;
    background: rgba(168, 154, 255, 0.1);
    color: white;
    border-radius: 10px;
    font-family: 'DMSans';
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    outline: none !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .custom-plan {
    padding: 6px 13px 6px 16px;
    min-width: 100px;
    border: 0px;
    background: rgba(168, 154, 255, 0.1);
    color: white;
    border-radius: 10px;
    font-family: 'DMSans';
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    margin-right: 20px;
    /* line-height: 32px; */
    outline: none !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin-bottom: 0px !important;
  }

  .arrow-container {
    position: absolute;
    transform: translateY(-50%);
    top: 55%;
    right: 13px;
  }

  .arrow-container img {
    height: 15px;
    width: 15px;
    object-fit: contain;
    height: 100%;
  }
`

export const Flex = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const FlexRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-width: 150px;
`
export const FlexRowRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  align-items: flex-end;
`

export const Details = styled.button<{ selected?: boolean }>`
  display: flex;
  justify-items: center;
  align-items: center;
  background: ${(props) => (!props.selected ? ' rgba(168, 154, 255, 0.10)' : '#6F5CE6')};
  transition: background-color 0.3s ease;
  color: white;
  border: 0px;
  padding: 10px 15px;
  border-radius: 10px;
  margin-right: 20px;
  height: 50px;
  cursor: pointer;
  font-weight: 600;
  &:disabled {
    background: rgba(239, 239, 239, 0.3);
    color: rgba(16, 16, 16, 0.3);
  }

  &:hover {
    background: ${(props) => props.theme.main};
  }
`

export const SwitchText = styled.div`
  background-color: #5dc89a85;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 35px;
  height: 22px;
  padding: 0px 5px;
  margin-left: 10px;
  border-radius: 10px;
  font-weight: 200;
  font-size: 12px;
`

export const Switch = styled.div<{ mode: string }>`
  background-color: ${(props) => (props.mode === 'true' ? '#5dc89a85' : '#cd578e85')};
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 35px;
  height: 22px;
  padding: 0px 2px;
  margin-left: 10px;
  border-radius: 10px;
  font-weight: 200;
  font-size: 10px;
`

export const Table = styled(ContainerTable)`
  overflow-x: auto;
  max-width: 100%;
  min-height: 80%;
  position: relative;

  td {
    /* padding: 0px 10px !important; */
  }
  td.networks {
    min-width: 156px;
  }
  .accent {
    color: #33c2b0;
    font-weight: bold;
    font-family: 'DMSansBold';
  }
  .accent-2 {
    color: #ff975f;
    font-weight: bold;
    font-family: 'DMSansBold';
  }
  a {
    &:hover {
      text-decoration: underline;
    }
  }

  th:first-child {
    min-width: 150px;
    max-width: 200px;
    position: sticky;
    left: 0;
    z-index: 1;
    padding: 0px 15px;
  }

  tbody {
    tr {
      td:first-child {
        padding: 0px 0px;
        min-width: 150px;
      }
    }
  }
`

export const Tab = styled.div`
  width: 100%;
  background: #6f5ce6;
  padding: 0;
  margin-top: -30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
  height: 40px;
  button {
    margin-top: 0 !important;
    border-radius: 0 !important;
    padding: 10px 15px;
    cursor: pointer;
    border: none;
    background: none;
    color: white;
    font-size: 16px;

    &.active {
      border-bottom: 2px solid white;
    }

    &:not(:last-child) {
      margin-right: 20px;
    }
  }
`

export const Messages = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 15px 20px;
`

export const Extra = styled.div`
  background-color: #dcd7ff17;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
`
export const TableRow = styled.tr``

export const TableRowM = styled(TableRow)`
  height: 0px;
`
export const Line = styled.div`
  width: 100%;
`

export const Out = styled.span`
  color: yellow;
  padding-left: 5px;
`

export const Space = styled.div`
  width: 5px;
`
export const ExtraInfo = styled.div`
  color: rgb(118, 118, 118);
`

export const TableCellM = styled(TableCell)`
  border: none !important;
  padding: 0px;
`

export const ExpandableContent = styled.div<{ expanded?: boolean }>`
  max-height: ${(props) => (props.expanded ? '100%' : '0')};
  margin-bottom: ${(props) => (props.expanded ? '50px' : '0')};
  display: ${(props) => (props.expanded ? 'inline-table' : 'none')};
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  width: 100%;
`

export const FlexItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  padding: 10px 0px;
  height: 100%;
`

export const FlexOptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  /* text-overflow: ellipsis; */
  max-width: 250px;
  a {
    margin: 0px 20px;
    margin-right: 15px;
    text-decoration: underline;
  }
`

export const FlexNoWrap = styled.div`
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #ffffff80;
  max-width: 200px;
  padding: 10px;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`

export const DetailsItem = styled.button`
  background: rgba(168, 154, 255, 0.1);
  transition: background-color 0.3s ease;
  color: white;
  border: 0px;
  padding: 7px 15px;
  border-radius: 10px;
  min-height: 20px;
  min-width: 45px;
  cursor: pointer;
  &:disabled {
    background: rgba(239, 239, 239, 0.3);
    color: rgba(16, 16, 16, 0.3);
  }
  .chevron::before {
    border-style: solid;
    border-width: 0.25em 0.25em 0 0;
    content: '';
    display: inline-block;
    height: 5px;
    left: 2.5px;
    position: relative;
    top: 2.5px;
    transform: rotate(-45deg);
    vertical-align: top;
    width: 5px;
  }
  &.zeropadding {
    padding: 0px !important;
  }

  .warning {
    color: yellow;
  }

  .chevron.right:before {
    left: 0;
    transform: rotate(45deg);
  }

  .chevron.bottom:before {
    top: 2.5px;
    left: 0px;
    transform: rotate(135deg);
  }

  .chevron.left:before {
    left: 0.25em;
    transform: rotate(-135deg);
  }
`
export const Row = styled(TableRow)<{ status: boolean; isOpen: boolean }>`
  background-color: ${(props) => (props.isOpen ? '#dcd7ff17' : 'transparent')};
  padding-top: 20px !important;
  &:hover {
    ${Details} {
      background: ${(props) => props.theme.main};
    }
  }
`
