import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { useApi } from './useApi'

const useMonitors = (id: string): UseQueryResult<any> => {
  const { callApi } = useApi()

  const fetchMonitors = async (): Promise<any> => {
    if (!id) throw new Error('No id provided')

    const url = `/token-monitor/balances/${id}`

    const response = await callApi<any>(url)
    return response.balances
  }

  return useQuery<any>({
    queryKey: ['taskData', id],
    queryFn: fetchMonitors,
    enabled: true,
  })
}

export default useMonitors
