import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { TransparentButton } from '../utils/styles'

interface FormModalProps {
  onConfirm: () => void
  onCancel: () => void
  children?: ReactNode
}

const ModalOverlay = styled.div`
  position: fixed;
  z-index: 400;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`

const ModalContent = styled.div`
  background: #323244;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  text-align: center;
  overflow-y: scroll;
  max-height: 90vh;
  input {
    margin: 10px auto 30px auto;
    min-width: 150px;
    width: 250px;
    border: 2px solid #5f5d62;
    background: transparent;
    color: white;
    border-radius: 10px;
    height: 34px;
  }
  p {
    font-family: 'Visby';
    font-size: 20px;
    line-height: 30px;
  }
`

const ModalActions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 30px;
`

const FormModal: React.FC<FormModalProps> = ({ onConfirm, onCancel, children }) => {
  return (
    <ModalOverlay>
      <ModalContent>
        <ModalActions>
          <TransparentButton onClick={onCancel}>x</TransparentButton>
        </ModalActions>
        {children}
      </ModalContent>
    </ModalOverlay>
  )
}

export default FormModal
