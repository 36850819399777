import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { TokenInfo } from '../types/API'
import { useApi } from './useApi'

const useTokenInfo = (address: string, chainId: number, isOpen: boolean): UseQueryResult<TokenInfo> => {
  const { callApi } = useApi()

  const fetchTokenInfo = async (): Promise<TokenInfo> => {
    if (!address) throw new Error('No address provided')
    return await callApi<TokenInfo>(`/public/tokens/${chainId}/${address}`)
  }

  return useQuery<TokenInfo>({
    queryKey: ['tokenInfo', chainId, address],
    queryFn: fetchTokenInfo,
    enabled: isOpen,
  })
}

export default useTokenInfo
