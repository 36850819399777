import styled from 'styled-components'

export const ContainerTable = styled.table`
  margin-top: 50px;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
  box-sizing: border-box;

  &.full-width {
    min-width: 100%;
  }
  img {
    cursor: pointer;
  }
  tbody tr:hover {
    background: #323244;
  }

  button.violet,
  a.violet {
    color: white;
    background: #6f5ce6;
    border: 0px;
    padding: 3px 7px;
    border-radius: 4px;
    cursor: pointer;
    margin: 0 10px;
    &:hover {
      background: #582ea0;
    }
  }
  th {
    padding-top: 10px;
    text-align: left;
    padding-bottom: 10px;
    font-family: 'DMSans';
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.5);
    border-bottom: 1px solid #2d2b31;
    padding: 20px 8px;
  }
  td:first-child,
  th:first-child {
    padding-left: 25px;
    white-space: nowrap;
    text-align: left;
  }
  td:last-child,
  th:last-child {
    padding-right: 25px;
    text-align: left;
  }
  td {
    padding: 8px;
    vertical-align: top;
    font-size: 12px;
  }
  .error {
    color: #ff7575;
    font-weight: bold;
  }
  .success {
    color: #33c2b0;
    font-weight: bold;
  }
  .warning {
    color: #f9ca51;
    font-weight: bold;
  }
  .accent {
    color: #33c2b0;
    font-wight: bold;
    font-family: 'DMSansBold';
    a {
      color: #33c2b0;
      &:hover {
        color: #91fffb;
      }
    }
  }
  &.editingTable {
    input {
      margin-bottom: 0 !important;
      height: 26px !important;
      width: 100px !important;
      min-width: 100px !important;
      &.short {
        width: 30px !important;
        min-width: 30px !important;
      }
      &.medium {
        width: 70px !important;
        min-width: 70px !important;
      }
      &.large {
        width: 130px !important;
        min-width: 130px !important;
      }

      &.x-large {
        width: 230px !important;
        min-width: 230px !important;
      }
      &.xx-large {
        width: 450px !important;
        min-width: 450px !important;
      }
    }
  }
`

export const StickyTable = styled.div`
  overflow-x: auto;
  max-width: 100%;
  position: relative;

  th:first-child {
    min-width: 200px;
    max-width: 200px;
    position: sticky;
    @media only screen and (max-width: 700px) {
      min-width: auto;
    }
    left: 0;
    z-index: 1;
    background: ${(props) => props.theme.background};
  }

  tbody {
    tr {
      td:first-child {
        position: sticky;
        left: 0;
        background: ${(props) => props.theme.background};
      }
    }
  }
`

export const ScrollTable = styled.div`
  overflow-x: auto;
  max-width: 100%;
  position: relative;

  /* th:first-child {
    min-width: 200px;
    max-width: 200px;
    position: sticky;
    @media only screen and (max-width: 700px) {
      min-width: auto;
    }
    left: 0;
    z-index: 1;
    background: ${(props) => props.theme.background};
  }

  tbody {
    tr {
      td:first-child {
        position: sticky;
        left: 0;
        background: ${(props) => props.theme.background};
      }
    }
  } */
`

export const Button = styled.button`
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`

export const LoadingTable = styled.div`
  margin-top: 50px;
`

export const FlexButtons = styled.div`
  display: flex;
  gap: 40px;
  width: 100%;
  justify-content: center;
  margin-top: 30px;
  button {
    background: #6f5ce6;
    color: white;
    border: 0px solid;
    padding: 8px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 4px 2px;
    cursor: pointer;
    &.active,
    &:hover {
      background: #582ea0;
    }
  }
`

export const FlexRow = styled.div`
  display: flex;
  align-items: center;
  &.space-around {
    justify-content: space-between;
    margin: 0 20px;
  }
  @media only screen and (max-width: 700px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`

export const LittleButton = styled(Button)`
  padding: 3px 7px;
  font-size: 12px;
  margin: 0px 2px;
`

export const Tab = styled.div`
  width: 100%;
  padding: 0;
  margin-top: -30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #979797;
  @media only screen and (max-width: 700px) {
    flex-wrap: wrap;
  }

  button {
    margin-top: 0 !important;
    border-radius: 0 !important;
    padding: 5px 15px;
    @media only screen and (max-width: 700px) {
      padding: 5px;
    }
    cursor: pointer;
    border: none;
    background: none;
    color: white;
    font-size: 16px;
    line-height: 32px;

    &.active {
      border-bottom: 5px solid #5ce6d5;
    }

    &:not(:last-child) {
      margin-right: 20px;
    }
  }
`

export const Filters = styled.div`
  display: flex;
  margin-top: 70px;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-end;
`

export const Filter = styled.div`
  margin-right: 20px;

  label {
    margin-right: 5px;
    margin-bottom: 5px;
  }

  select {
    display: block;
    height: 50px;
    border-radius: 7px;
    padding: 2px 5px;
    margin: 5px 0 20px 0;
    height: 34px;
    background: #2a2a36;
    border-color: #2a2a36;
    color: white;
  }

  input {
    display: block;
    margin: 5px 0 20px 0;
    min-width: 300px;
    @media only screen and (max-width: 700px) {
      min-width: 150px;
    }
    height: 30px;
    padding: 2px 5px;
    border-radius: 7px;
    border: 0px;
    font-family: 'DMSans';
    font-size: 15px;
    background: #2a2a36;
    color: white;
  }
`

export const Details = styled.button<{ selected: boolean }>`
  display: flex;
  justify-items: center;
  align-items: center;
  background: ${(props) => (!props.selected ? ' rgba(168, 154, 255, 0.10)' : '#6F5CE6')} !important;
  transition: background-color 0.3s ease;
  color: white;
  border: 0px;
  padding: 10px 15px;
  border-radius: 10px;
  margin-right: 20px;
  height: 50px;
  cursor: pointer;
  font-weight: 600;
  &:disabled {
    background: rgba(239, 239, 239, 0.3);
    color: rgba(16, 16, 16, 0.3);
  }

  &:hover {
    background: ${(props) => props.theme.main};
  }
`

export const ButtonViolet = styled.button`
  display: inline-block;
  width: auto;
  padding: 10px 80px;
  border: 0px;
  border-radius: 10px;
  background: #5ce6d5;
  border: 2px solid #5ce6d5;
  font-family: 'Visby';
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  cursor: pointer;
  color: #000;
  margin-top: 20px;
  &:hover {
    background: #009281;
    border-color: #009281;
  }
`

export const ButtonWhite = styled(ButtonViolet)`
  background: transparent;
  color: #5ce6d5;
  &:hover {
    color: black;
  }
`

export const BackButton = styled(ButtonWhite)`
  padding: 3px 7px;
  font-size: 33px;
  align-self: flex-start;
  &:hover {
    background: #ccc;
  }
`

export const TableDetails = styled.button`
  background: rgba(168, 154, 255, 0.1);
  transition: background-color 0.3s ease;
  color: white;
  border: 0px;
  padding: 7px 15px;
  border-radius: 10px;
  min-height: 20px;
  min-width: 45px;
  cursor: pointer;
  &:disabled {
    background: rgba(239, 239, 239, 0.3);
    color: rgba(16, 16, 16, 0.3);
  }
  .chevron::before {
    border-style: solid;
    border-width: 0.25em 0.25em 0 0;
    content: '';
    display: inline-block;
    height: 5px;
    left: 2.5px;
    position: relative;
    top: 2.5px;
    transform: rotate(-45deg);
    vertical-align: top;
    width: 5px;
  }
  &.zeropadding {
    padding: 0px !important;
  }

  .warning {
    color: yellow;
  }

  .chevron.right:before {
    left: 0;
    transform: rotate(45deg);
  }

  .chevron.bottom:before {
    top: 2.5px;
    left: 0px;
    transform: rotate(135deg);
  }

  .chevron.left:before {
    left: 0.25em;
    transform: rotate(-135deg);
  }
`

export const ShowMore = styled.button`
  background: transparent;
  font-weight: bold;
  color: #33c2b0;
  cursor: pointer;
  border: solid 0 transparent;
  &:hover {
    color: #6cdfb9;
  }
`

export const PanelForm = styled.form`
  input {
    display: block;
    margin-bottom: 20px;
    min-width: 300px;
    @media only screen and (max-width: 700px) {
      min-width: 150px;
    }
    height: 40px;
    padding: 0 10px;
    border-radius: 7px;
    border: 0px;
    font-family: 'DMSans';
    font-size: 15px;
  } */
  /* label {
    color: white;
    font-size: 18px;
    margin: 0;
    font-family: 'DMSansBold';
    font-weight: bold;
    font-style: normal;
    line-height: 180%;
    margin-bottom: 50px;
    @media only screen and (max-width: 800px) {
      font-size: 12px;
    }
  }
  &.small {
    input {
      display: block;
      margin-bottom: 10px;
      min-width: 300px;
      @media only screen and (max-width: 700px) {
        min-width: 150px;
      }
      height: 27px;
      padding: 0 7px;
      font-size: 12px;
    }
    label {
      font-size: 12px;
      margin-bottom: 20px;
    }
  }
`

export const Message = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  width: 100%;
  .close {
    cursor: pointer;
    font-weight: bold;
  }
`

export const Group = styled.div`
  display: flex;
  gap: 30px;
  @media only screen and (max-width: 700px) {
    flex-wrap: wrap;
  }
`

export const Flexbox = styled.div`
  display: flex;
  gap: 15px;
  @media only screen and (max-width: 700px) {
    flex-wrap: wrap;
  }
`

export const Card = styled.div<{ width?: string }>`
  margin: 7px;
  border-radius: 10px;
  background: #2a2a36;
  width: ${(props) => props.width};
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  padding: 15px;
  h1 {
    font-feature-settings:
      'clig' off,
      'liga' off;
    font-family: 'VisbyBold';
    font-size: 27px;
    font-style: normal;
    font-weight: 500;
    line-height: 39px;
    letter-spacing: 0px;
    color: white;
    margin: 0;
  }
  p {
    font-family: 'DMSans';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: white;
    margin-top: 0;
    margin-bottom: 10px;
  }
  p.title {
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    font-size: 10px;
  }
  @media only screen and (max-width: 1400px) {
    h1 {
      font-size: 20px;
    }
    p.title {
      letter-spacing: 0.5px;
    }
  }

  &.loading {
    animation: loadingAnimation 1.5s infinite;
  }

  @keyframes loadingAnimation {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
`

export const ChartCard = styled(Card)`
  width: 300px;
  height: 420px;
  text-align: center;
  div {
    margin: auto;
  }
  div.relative {
    position: relative;
    top: -100px;
  }
  .divided-text {
    display: flex;
    gap: 20px;
    justify-content: center;
    div {
      margin: 0;
    }
  }
`

export const InnerSection = styled.div`
  padding: 50px;
`

// Definitive

export const TransparentButton = styled(LittleButton)`
  background: transparent;
  border-color: transparent;
  color: #5ce6d5;
  font-family: 'VisbyBold';
  font-size: 20px;
`

export const Section = styled.div`
  margin: 0px auto;
  width: 100%;
  overflow-x: auto;
  padding: 0 20px;
  min-height: calc(100vh - 150px);
  box-sizing: border-box;
  max-width: 1500px;
  @media only screen and (max-width: 700px) {
    padding: 0;
  }
`

export const Right = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`

export const H2 = styled.h2`
  font-family: 'VisbyBold';
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 48px;
`

export const H1 = styled.h1`
  font-family: 'VisbyBold';
  font-size: 72px;
  font-style: normal;
  font-weight: 500;
  line-height: 68px;
  letter-spacing: 1px;
  @media only screen and (max-width: 700px) {
    font-size: 50px;
    line-height: 57px;
  }
`

export const Loader = styled.div`
  @keyframes l13 {
    100% {
      transform: rotate(1turn);
    }
  }
  width: 50px;
  margin: 25px;
  aspect-ratio: 1;
  border-radius: 50%;
  background:
    radial-gradient(farthest-side, #5ce6d5 94%, #0000) top/8px 8px no-repeat,
    conic-gradient(#0000 30%, #5ce6d5);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  animation: l13 1s infinite linear;
`

export const selectStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: '#323244',
    borderColor: state.isFocused ? '#1d1d1d' : '#1C1C24',
    color: '#fff',
    boxShadow: state.isFocused ? '0 0 0 1px #1d1d1d' : null,
    '&:hover': {
      borderColor: '#666',
    },
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: '#323244',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#fff',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#aaa',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#fff',
    '&:hover': {
      color: '#aaa',
    },
  }),
  input: (provided) => ({
    ...provided,
    color: '#fff',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: '#1C1C24',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#1C1C24' : state.isFocused ? '#1C1C24' : '#323244',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#1C1C24',
    },
  }),
}
