import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { ChainResponse } from '../types/API'
import { useApi } from './useApi'

const useNetworkHeader = (id: string, chainId: string): UseQueryResult<ChainResponse | undefined> => {
  const { callApi } = useApi()

  const fetchNetwork = async (): Promise<ChainResponse | undefined> => {
    if (!chainId) return undefined

    try {
      const response = await callApi<ChainResponse[]>('/relayer-executor/chains', {
        chainId,
      })

      const chainsInfo = response?.find((object) => object.chainId === chainId)
      return chainsInfo
    } catch (error) {
      console.error('Error fetching network:', error)
      throw error
    }
  }

  return useQuery<ChainResponse | undefined>({
    queryKey: ['networkHeader', id, chainId],
    queryFn: fetchNetwork,
  })
}

export default useNetworkHeader
