import React, { createContext, useState, useEffect, ReactNode } from 'react'
import axios from 'axios'
import { ChainSubgraphsResponse } from '../types/API'

interface SubgraphsProviderProps {
  children: ReactNode
}

const SubgraphsContext = createContext<ChainSubgraphsResponse>({})

const URL = process.env.REACT_APP_SERVER_BASE_URL

export const SubgraphsProvider: React.FC<SubgraphsProviderProps> = ({ children }) => {
  const [data, setData] = useState<ChainSubgraphsResponse>({})

  const fetchData = async () => {
    try {
      const response = await axios.get<ChainSubgraphsResponse>(`${URL}/public/subgraphs`)
      setData(response.data)
    } catch (error) {
      console.error('Error fetching the subgraphs url')
    }
  }


  useEffect(() => {
    fetchData()
  }, [])

  return <SubgraphsContext.Provider value={data}>{children}</SubgraphsContext.Provider>
}

export default SubgraphsContext
