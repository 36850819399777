import { useQuery } from '@tanstack/react-query'
import { SimulationsResponse } from '../types/API'
import { useApi } from './useApi'

const useSimulations = (
  id: string | undefined,
  startDate: Date | null,
  endDate: Date | null,
  tasks: string[],
  chainId: string
) => {
  const { callApi } = useApi()

  const fetchSimulations = async (): Promise<SimulationsResponse> => {
    const url = `/relayer-executor/environments/${id}/simulations`

    const params = {
      chainIds: [chainId],
      startDate: startDate?.toISOString(),
      endDate: endDate?.toISOString(),
      tasks,
    }

    return await callApi<SimulationsResponse>(url, params)
  }

  return useQuery<SimulationsResponse>({
    queryKey: ['simulations', id, startDate, endDate, tasks, chainId],
    queryFn: fetchSimulations,
    enabled: !!id && !!startDate && !!endDate,
  })
}

export default useSimulations
