import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { TransactionResponse } from '../types/API'
import { useApi } from './useApi'

const useTaskData = (id: string, isOpen: boolean): UseQueryResult<TransactionResponse> => {
  const { callApi } = useApi()

  const fetchTokenInfo = async (): Promise<TransactionResponse> => {
    if (!id) throw new Error('No id provided')

    const url = `/relayer-executor/task-executions/${id}/transaction`

    return await callApi<TransactionResponse>(url)
  }

  return useQuery<TransactionResponse>({
    queryKey: ['taskData', id],
    queryFn: fetchTokenInfo,
    enabled: isOpen,
  })
}

export default useTaskData
