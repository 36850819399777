import React from 'react'
import styled from 'styled-components'

const isStaging = process.env.REACT_APP_SERVER_BASE_URL?.includes('staging')

export default function StagingBanner() {
  if (!isStaging) return null
  return (
    <Banner>
      <p>staging</p>
    </Banner>
  )
}

const Banner = styled.section`
  width: 100%;
  text-align: center;
  font-weight: bold;
  background-color: #a43b3b;

  p {
    margin: 0;
    padding: 2px;
  }
`
