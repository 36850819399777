import React from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'
import { Tab, H2, H1, Section, ContainerTable, Loader } from '../../utils/styles'
import { formatTokenAmount } from '../../utils/math-utils'
import useRelayer from '../../hooks/useRelayer'
import Address from '../general/Address'
import Network from '../general/Network'

export default function Relayer() {
  const params = useParams()
  const data = useRelayer(params.id)

  return (
    <Section>
      {data?.data ? (
        <ContainerTable>
          <thead>
            <tr>
              <th>Address</th>
              <th>Network</th>
              <th>Balance</th>
              <th>Max Quota</th>
              <th>Quota Used</th>
            </tr>
          </thead>
          <tbody>
            {data.data
              .slice()
              .sort((a, b) => {
                const addressA = a?.relayer?.id?.toLowerCase() || ''
                const addressB = b?.relayer?.id?.toLowerCase() || ''
                return addressA.localeCompare(addressB)
              })
              .map((item, index) => (
                <>
                  {item && (
                    <tr key={index}>
                      <td className="accent" style={{ width: '320px' }}>
                        <Address address={item.relayer?.id} chainId={item.chainId} showIdentity={false} />
                      </td>
                      <td>
                        <Network network={item.chainId} />
                      </td>
                      <td>
                        {Number(
                          formatTokenAmount(item.balance, item.nativeToken?.decimals, {
                            digits: 5,
                          })
                        )}{' '}
                        {item.nativeToken?.symbol}
                      </td>
                      <td>
                        {Number(
                          formatTokenAmount(item.maxQuota, item.nativeToken?.decimals, {
                            digits: 5,
                          })
                        )}{' '}
                        {item.nativeToken?.symbol}
                      </td>
                      <td>
                        {Number(
                          formatTokenAmount(item.quotaUsed, item.nativeToken?.decimals, {
                            digits: 5,
                          })
                        )}{' '}
                        {item.nativeToken?.symbol}
                      </td>
                    </tr>
                  )}
                </>
              ))}
          </tbody>
        </ContainerTable>
      ) : (
        <Loader />
      )}
    </Section>
  )
}
