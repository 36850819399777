import React from 'react'
import ApiSettings from './config/ApiSettings'
import Web3Settings from './config/Web3Settings'
import TokenRegistry from './config/TokenRegistry'
import Notifications from './config/Notifications'
import RelayerExecutorForm from './config/RelayerExecutorForm'
import { PriceOracleSettings } from './config/PriceOracle'
import { H2 } from '../../utils/styles'

export default function Config() {
  return (
    <div>
      <H2>Relayer Executor</H2>
      <RelayerExecutorForm />
      <br />
      <br />
      <H2>Web3</H2>
      <Web3Settings />
      <br />
      <br />
      <H2>Price Oracle</H2>
      <PriceOracleSettings />
      <br />
      <br />
      <H2>Token Registry</H2>
      <TokenRegistry />
      <br />
      <br />
      <H2>Notifications</H2>
      <Notifications />
      <br />
      <br />
      <H2>API</H2>
      <ApiSettings />
    </div>
  )
}
