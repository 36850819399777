import { useQuery } from '@tanstack/react-query'
import { EnvironmentResponse } from '../types/API'
import { useApi } from './useApi'

const useEnvironment = (id: string | undefined) => {
  const { callApi } = useApi()

  const fetchEnvironment = async (): Promise<EnvironmentResponse> => {
    const url = `/relayer-executor/environments/${id}`
    return await callApi<EnvironmentResponse>(url)
  }

  return useQuery<EnvironmentResponse>({
    queryKey: ['environment', id],
    queryFn: fetchEnvironment,
    enabled: !!id,
  })
}

export default useEnvironment
