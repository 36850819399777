import React, { useState } from 'react'
import moment from 'moment'
import { ButtonViolet, Message, Group, H2 } from '../../utils/styles'
import { NewForm } from '../../utils/typescript-styles'
import CustomInput from '../general/Form'
import { useApi } from '../../hooks/useApi'

const URL = process.env.REACT_APP_SERVER_BASE_URL

interface TokenListFormProps {
  onSuccess?: () => void
}

const TokenListForm: React.FC<TokenListFormProps> = ({ onSuccess = () => {} }) => {
  const [name, setName] = useState('')
  const [url, setUrl] = useState('')
  const [message, setMessage] = useState('')
  const { callApi } = useApi()

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    const lastUpdate = moment().toISOString()
    const isActive = true

    try {
      await callApi(
        '/token-registry/lists',
        {
          name,
          url,
          isActive,
          lastUpdate,
        },
        'POST'
      )
      onSuccess()
    } catch (error) {
      console.error('Error creating token:', error)
    }
  }

  return (
    <NewForm onSubmit={handleFormSubmit}>
      {message !== '' ? (
        <Message>
          <span>{message}</span>
          <span className="close" onClick={() => setMessage('')}>
            X
          </span>
        </Message>
      ) : (
        <>
          <H2>New token list</H2>
          <Group>
            <div>
              <CustomInput
                label="Name"
                name="Name"
                type="text"
                modal={true}
                value={name}
                onChange={(e: any) => setName(e.target.value)}
                required
              />
            </div>
            <div>
              <CustomInput
                label="Url"
                name="Url"
                type="text"
                modal={true}
                value={url}
                onChange={(e: any) => setUrl(e.target.value)}
                required
              />
            </div>
          </Group>
          <ButtonViolet type="submit">New</ButtonViolet>
        </>
      )}
    </NewForm>
  )
}

export default TokenListForm
