import { useQuery } from '@tanstack/react-query'
import { useApi } from './useApi'

interface TokenRegistryData {
  _id: string
  address: string
  chainId: number
  decimals: number
  spamCounter: number
  priority: number
  isNativeToken: boolean
  enabled: boolean
  isWrappedNativeToken: boolean
  name: string
  symbol: string
}

interface TokenRegistryResponse {
  data: TokenRegistryData[]
  pages: number
  total: number
}

export const useTokenRegistry = (
  page: number,
  filters: {
    chainIdFilter?: number | ''
    symbolFilter?: string
    addressFilter?: string
    isNativeFilter?: boolean | null
    enabledFilter?: boolean | null
    isWrappedNativeFilter?: boolean | null
  }
) => {
  const { callApi } = useApi()

  const fetchTokenRegistry = async (): Promise<TokenRegistryResponse> => {
    const params = {
      limit: 20,
      page,
      ...(filters.chainIdFilter !== '' && { chainId: filters.chainIdFilter }),
      ...(filters.symbolFilter && { symbol: filters.symbolFilter }),
      ...(filters.addressFilter && { addresses: [filters.addressFilter] }),
      isNativeToken: filters.isNativeFilter,
      isWrappedNativeToken: filters.isWrappedNativeFilter,
      enabled: filters.enabledFilter,
    }

    const data = await callApi<TokenRegistryResponse>('/token-registry/tokens', { ...params })
    return data
  }

  return useQuery<TokenRegistryResponse>({
    queryKey: ['tokenRegistry', page, filters],
    queryFn: fetchTokenRegistry,
    keepPreviousData: true,
  })
}
