type PaginatedList<T> = {
  data: T[]
  total: number
  pages: number
}

export type DeniedAddress = DeniedAddressRequest & {
  deniedBy: string
  createdAt: string
}

export type DeniedAddressRequest = {
  address: string
  comment: string
}

export type SmartVaultResponse = {
  chainId: number
  _id: string
  address: string
  name: string
  denied: boolean
  deniedTasks: DeniedAddress[]
  deniedTokens: DeniedAddress[]
  createdAt: string
  updatedAt: string
  environment: {
    mimicId: string
    namespace: string
    createdAt: string
    updatedAt: string
  }
}

export type EcoModesResponse = {
  averageSuccessSpeedSmoothFactor: number
  active: boolean
  createdAt: string
  updatedAt: string
  _id: string
  gasPriceMedianThreshold: number
  isolatedTasksAvoidanceList: string[]
  maximumExecutionsPerPeriod: number
  safeGuardPeriodPct: number
  smartVault: string
}

export type EnvironmentGasResponse = {
  currentGas: string | number
  medianGas: string | number
}

export type BotBalanceResponse = {
  balance: string | number
  price: string | number
  balanceUsd: string | null
}

export type NetworkResponse = {
  chainId: string | number
  denied: boolean
  executorGasLimitBufferPct: number
  gasThresholdAmount: number
  minimumMinutesBetweenExecutions: number
  missingTxMinutesTimeout: number
  name: string
  subgraphUrl: string
  symbol: string
  thresholdAmount: number
  transactionDelaySeconds: number
  transactionsExpirationSeconds: number
}

export type EnvironmentResponse = {
  mimicId: string
  namespace: string
  smartVaults: SmartVaultResponse[]
  createdAt: string
  updatedAt: string
}

export type QueueExecutionsResponse = {
  environment: EnvironmentResponse
  smartVault: SmartVaultResponse
  chainId: number
  status: string
  allowedAt: Date
  startedAt?: Date
  finishedAt?: Date
  podJobId?: string
  createdAt: Date
  updatedAt: Date
}

export type SmartVault = {
  id: string
  name: string
  registry: string
  implementation: object
  relayerConfigs: RelaerConfig[]
}

export type RelaerConfig = {
  balance: string
  relayer: {
    id: string
  }
  nativeToken: {
    decimals: number
    id: string
    name: string
    symbol: string
  }
}

export interface TimelockResponse {
  namespace?: string
  chainIds?: number[]
  mimicId?: string
  timelocks?: TimelockItem[] // Define mejor el tipo según la estructura real de los timelocks
}

export interface TimelockItem {
  start: string
  end: string
  task: string
  chainId: number
  namespace?: string
}

export enum QueueExecutionStatus {
  Enqueued = 'enqueued',
  Running = 'running',
  Finished = 'finished',
  Errors = 'finished with errors',
  Stale = 'stale',
  Denied = 'denied',
}

export type UserResponse = {
  _id: string
  email: string
  roles: string[]
  createdAt: string
  updatedAt: string
}

export type AdminNotificationResponse = {
  _id: string
  notificationId: string
  title: string
  details: string
  severity: SeverityLevel
  service: string
  createdAt: string
}

export enum SeverityLevel {
  CRITICAL = 'critical',
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
  INFORMATIONAL = 'informational',
}

export type TokenInfo = {
  symbol: string
  name: string
  decimals: number
  priority: number
  spamCounter: number
  enabled: boolean
  isNativeToken: boolean
  isWrappedNativeToken: boolean
  address: string
  chainId: number
}

export type TokenResponse = {
  data: Record<string, TokenInfo>
  pages: number
  total: number
}

export type TaskExecutions = {
  id: string
  planId: string
  chainId: number
  mimicId: string
  smartVault: string
  index: number
  calldata: string
  task: {
    name: string
    address: string
    executionType: string
  }
  status: {
    type: string
    result?: string
    reason?: string
  }
  in: {
    token: string
    amount: string
    amountUsd: number
  }
  out: {
    token: string
    amount: string
    amountUsd: number
  }[]
  executedAt: string
  protocolFee?: {
    token: string
    amount: string
    amountUsd: number
  }
  gas?: {
    amount: string
    amountUsd: number
  }
}

export type TaskExecutionsResponse = PaginatedList<TaskExecutions>

export type TaskPlanResponse = {
  _id: string
  environment: EnvironmentResponse
  smartVault: SmartVaultResponse
  tasks: TaskExecutions[]
  chainId: number
  parallel: boolean
  simulation: string
  blockNumber?: number
}

export type TransactionResponse = {
  _id: string
  environment: EnvironmentResponse
  smartVault: SmartVaultResponse
  executionPlan: TaskPlanResponse
  chainId: number
  sender: string
  hash: string
  nonce: number
  status: string
  blockNumber?: number
  gas?: {
    amount: string
    amountUsd: number
  }
}

export type SimulationResponse = {
  simulationUrl: string
}

export type ChainResponse = {
  chainId: string
  denied: boolean
  gasThresholdAmount: number
  thresholdAmount: number
  transactionDelaySeconds: number
  subgraphUrl: string
  name: string
  symbol: string
  executorGasLimitBufferPct: number
  transactionsExpirationSeconds: number
  missingTxMinutesTimeout: number
  minimumMinutesBetweenExecutions: number
}

export type CronStatusResponse = {
  name: string
  schedule: string
  suspend: boolean
  active?: boolean
  lastStartTime?: Date
  lastCompletionTime?: Date
  lastScheduleTime?: Date
  nextScheduleTime?: Date
}

export type VolumeResponse = {
  [task: string]: {
    [day: string]: {
      count: number
      totalUsd: number
    }
  }
}

export type FeesResponse = {
  [task: string]: {
    [day: string]: {
      [token: string]: {
        totalAmount: number
        totalAmountUsd: number
        totalFee: number
        totalFeeUsd: number
      }
    }
  }
}

export type GasUsedResponse = {
  [task: string]: {
    [day: string]: {
      totalGas: number
      totalUsd: number
    }
  }
}

export type SlippageResponse = {
  [task: string]: {
    [day: string]: {
      averageSlippage: number
      totalInAmountUsd: number
      totalOutAmountUsd: number
    }
  }
}

export type SimulationsResponse = {
  [day: string]: {
    [status: string]: number
  }
}

export type TransactionsStatusStats = {
  [key: string]: number
}

export type WalletNonceStats = {
  [key: string]: {
    onChain: number
    local: number
  }
}

export type ChainStatsResponse = {
  transactions: TransactionsStatusStats
  nonces: WalletNonceStats
  averageTxCost: number
}

export type ChainSubgraphsResponse = {
  [chainId: number]: string
}
