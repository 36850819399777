// @ts-nocheck
import React, { useState, ChangeEvent, FormEvent } from 'react'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { useApi } from '../../../hooks/useApi'
import { ButtonViolet, Message, Group } from '../../../utils/styles'
import { convertGweiToWei } from '../../../utils/web3-utils'
import { NewForm } from '../../../utils/typescript-styles'
import CustomInput from '../../general/Form'
import Switch from '../../Switch'

const URL = process.env.REACT_APP_SERVER_BASE_URL

interface Web3FormProps {
  onSuccess?: () => void
}

interface FormValues {
  chainId: string
  denied: boolean
  name: string
  symbol: string
  subgraphUrl: string
  thresholdAmount: number
  executorGasLimitBufferPct: number
  transactionDelaySeconds: number
  transactionsExpirationSeconds: number
  missingTxMinutesTimeout: number
  minimumMinutesBetweenExecutions: number
}

const Web3Form: React.FC<Web3FormProps> = ({ onSuccess = () => {} }) => {
  const { callApi } = useApi()
  const [formValues, setFormValues] = useState<FormValues>({
    chainId: '',
    denied: false,
    name: '',
    symbol: '',
    subgraphUrl: '',
    thresholdAmount: 0,
    gasThresholdAmount: 0,
    executorGasLimitBufferPct: 0,
    transactionDelaySeconds: 0,
    transactionsExpirationSeconds: 0,
    missingTxMinutesTimeout: 0,
    minimumMinutesBetweenExecutions: 0,
  })
  const [message, setMessage] = useState<string>('')

  const handleFormSubmit = async (e: FormEvent, formValues: any, onSuccess: () => void) => {
    e.preventDefault()
    try {
      const params = {
        chainId: parseInt(formValues.chainId, 10),
        denied: formValues.denied,
        name: formValues.name,
        symbol: formValues.symbol,
        subgraphUrl: formValues.subgraphUrl,
        thresholdAmount: parseInt(formValues.thresholdAmount, 10),
        gasThresholdAmount: convertGweiToWei(parseInt(formValues.gasThresholdAmount, 10)),
        executorGasLimitBufferPct: parseInt(formValues.executorGasLimitBufferPct, 10),
        transactionDelaySeconds: parseInt(formValues.transactionDelaySeconds, 10),
        transactionsExpirationSeconds: parseInt(formValues.transactionsExpirationSeconds, 10),
        missingTxMinutesTimeout: parseInt(formValues.missingTxMinutesTimeout, 10),
        minimumMinutesBetweenExecutions: parseInt(formValues.minimumMinutesBetweenExecutions, 10),
      }

      const response = await callApi('/relayer-executor/chains', params, 'POST')
      onSuccess()
    } catch (error) {
      console.error('Error in handleFormSubmit:', error)
    }
  }
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }))
  }

  const handleSwitchToggle = (field: keyof FormValues) => {
    setFormValues((prevData) => ({
      ...prevData,
      [field]: !prevData[field],
    }))
  }

  return (
    <NewForm onSubmit={handleFormSubmit}>
      {message !== '' ? (
        <Message>
          <span>{message}</span>
          <span className="close" onClick={() => setMessage('')}>
            X
          </span>
        </Message>
      ) : (
        <>
          <Group>
            <div>
              <CustomInput
                type="number"
                label="Chain Id"
                name="chainId"
                modal={true}
                value={formValues.chainId}
                onChange={handleInputChange}
                required
              />
            </div>
            <div>
              <CustomInput
                type="text"
                label="Symbol"
                name="symbol"
                modal={true}
                value={formValues.symbol}
                onChange={handleInputChange}
                required
              />
            </div>
            <div>
              <CustomInput
                type="text"
                label="Name"
                name="name"
                modal={true}
                value={formValues.name}
                onChange={handleInputChange}
                required
              />
            </div>
          </Group>
          <Group>
            <div>
              <CustomInput
                type="number"
                label="Threshold Amount"
                name="thresholdAmount"
                modal={true}
                value={formValues.thresholdAmount || 0}
                onChange={handleInputChange}
                required
              />
            </div>
            <div>
              <CustomInput
                type="number"
                label="Gas Threshold Amount (Gwei)"
                name="gasThresholdAmount"
                modal={true}
                value={formValues.gasThresholdAmount || 0}
                onChange={handleInputChange}
                required
              />
            </div>
            <div>
              <CustomInput
                type="number"
                label="Gas Limit Buffer Pct"
                modal={true}
                name="executorGasLimitBufferPct"
                value={formValues.executorGasLimitBufferPct || 0}
                onChange={handleInputChange}
                required
              />
            </div>
          </Group>
          <Group>
            <div>
              <CustomInput
                type="number"
                label="Tx Delay Seconds"
                modal={true}
                name="transactionDelaySeconds"
                value={formValues.transactionDelaySeconds || 0}
                onChange={handleInputChange}
                required
              />
            </div>

            <div>
              <CustomInput
                type="number"
                label="Tx Expiration Seconds"
                modal={true}
                name="transactionsExpirationSeconds"
                value={formValues.transactionsExpirationSeconds || 0}
                onChange={handleInputChange}
                required
              />
            </div>

            <div>
              <CustomInput
                type="number"
                label="Tx Missing Timeout"
                modal={true}
                name="missingTxMinutesTimeout"
                value={formValues.missingTxMinutesTimeout || 0}
                onChange={handleInputChange}
                required
              />
            </div>
          </Group>
          <Group>
            <div>
              <CustomInput
                type="text"
                label="Subgraph Url"
                modal={true}
                name="subgraphUrl"
                value={formValues.subgraphUrl || ''}
                onChange={handleInputChange}
                required
              />
            </div>
            <div>
              <CustomInput
                type="number"
                label="Minimum Minutes Between Executions"
                modal={true}
                name="minimumMinutesBetweenExecutions"
                value={formValues.minimumMinutesBetweenExecutions || ''}
                onChange={handleInputChange}
                required
              />
            </div>
            <div>
              <label>Denied</label>
              <Switch ison={formValues.denied} onToggle={() => handleSwitchToggle('denied')} />
            </div>
          </Group>
          <br />
          <br />
          <ButtonViolet type="submit">New</ButtonViolet>
        </>
      )}
    </NewForm>
  )
}

export default Web3Form
