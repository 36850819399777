import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { useApi } from './useApi'
import { TaskPlanResponse } from '../types/API'

const useTaskPlanId = (id: string, isOpen: boolean): UseQueryResult<TaskPlanResponse> => {
  const { callApi } = useApi()

  const fetchTaskPlan = async (): Promise<TaskPlanResponse> => {
    if (!id) throw new Error('No id provided')

    const url = `/relayer-executor/task-executions/${id}/plan`
    return await callApi<TaskPlanResponse>(url)
  }

  return useQuery<TaskPlanResponse>({
    queryKey: ['useTaskPlanId', id],
    queryFn: fetchTaskPlan,
    enabled: isOpen,
  })
}

export default useTaskPlanId
