import { useState } from 'react'
import { useApi } from './useApi'
import { SimulationResponse } from '../types/API'

const useSimulateExecution = (executionId: string) => {
  const { callApi } = useApi()
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState<SimulationResponse | null>(null)

  const simulateExecution = async (): Promise<void> => {
    setIsLoading(true)
    setData(null)
    try {
      const url = `/relayer-executor/task-executions/${executionId}/simulate`
      const response = await callApi<SimulationResponse>(url, {}, 'POST')
      setData(response)
    } catch (error) {
      console.error('Error simulate:', error)
    } finally {
      setIsLoading(false)
    }
  }

  return { isLoading, data, simulateExecution }
}

export default useSimulateExecution
