import React, { createContext, useContext, useState, ReactNode } from 'react'
import axios from 'axios'
const BASE_URL = process.env.REACT_APP_SERVER_BASE_URL

interface User {
  token: string
  refreshToken: string
  email: string
  role: string
}

interface AuthContextType {
  user: User | null
  login: (userData: User) => void
  logout: () => void
  refreshToken: () => Promise<boolean>
}

const AuthContext = createContext<AuthContextType | undefined>(undefined)

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const storedUser = localStorage.getItem('user')
  const [user, setUser] = useState<User | null>(storedUser ? JSON.parse(storedUser) : null)

  const login = (userData: User) => {
    setUser(userData)
    localStorage.setItem('user', JSON.stringify(userData))
    localStorage.setItem('token', userData.token)
    localStorage.setItem('refreshToken', userData.refreshToken)
  }

  const logout = () => {
    localStorage.removeItem('user')
    localStorage.removeItem('token')
    localStorage.removeItem('refreshToken')
    setUser(null)
    window.location.reload()
  }

  const refreshToken = async (): Promise<boolean> => {
    try {
      const storedUser = localStorage.getItem('user')
      if (!storedUser) {
        logout()
        return false
      }
      const refreshEndpoint = `${BASE_URL}/public/users/renew-token`
      const user = JSON.parse(storedUser)
      const refreshToken = localStorage.getItem('refreshToken')
      const response = await axios.post(
        refreshEndpoint,
        { refreshToken },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )

      if (response.data.accessToken) {
        const updatedUser = { ...user, token: response.data.accessToken }
        localStorage.setItem('user', JSON.stringify(updatedUser))
        localStorage.setItem('token', response.data.accessToken)
        setUser(updatedUser)
        return true
      }
    } catch (error) {
      console.error('Error refreshing token:', error)
      logout()
    }
    return false
  }

  return <AuthContext.Provider value={{ user, login, logout, refreshToken }}>{children}</AuthContext.Provider>
}

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }

  return context
}
