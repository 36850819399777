/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import useSimpleMonitor, { BalanceSummary } from '../../../hooks/useSimpleMonitor'
import useManyTokenInfo, { ManyTokenFilters } from '../../../hooks/useManyTokenInfo'
import useTokenPrices, { TokenPrice } from '../../../hooks/useTokenPrices'
import { aLessB, formatAmountPrice } from '../../../utils/math-utils'
import { ActivitySection, Details, FlexMenu, FlexRowRight, Switch } from './styles'
import { TokenInfo } from '../../../types/API'
import ShowTable from './ShowTable'
import GenericTable from './GenericTable'

interface MonitorProps {
  chainId: number
}

export interface AggregatedTokenInfo {
  balance: BalanceSummary['balance']
  address: string
  source: BalanceSummary['source']
  symbol?: string
  chainId?: TokenInfo['chainId']
  decimals: number
  price?: number
  priceUpdated?: TokenPrice['date']
  amountUsd?: string
}

function objectToCsv(data: unknown[]) {
  if (data.length === 0) {
    return '' // Return an empty string if there is no data
  }

  // Assuming the structure of each object returned by convertData is the same
  // We use the first element to determine the headers
  const headers = Object.keys(convertData(data[0])[0])
  const csvRows = [headers.join(',')] // Add headers as the first row

  for (const item of data) {
    const convertedData = convertData(item) // Convert each item
    for (const row of convertedData) {
      const values = headers.map((header) => {
        const value = row[header]
        return `"${value}"` // Enclose each value in quotes to handle commas and line breaks
      })
      csvRows.push(values.join(',')) // Add the row to CSV rows
    }
  }

  return csvRows.join('\n')
}

function convertData(obj: any) {
  if (!obj.source || !Array.isArray(obj.source)) {
    return [] // Return an empty array if obj.source is not an array
  }
  return obj.source.map((s: any) => ({
    sourceAddress: s.address,
    address: obj.address,
    symbol: obj.symbol,
    decimals: obj.decimals,
    chainId: obj.chainId,
    amount: s.balance,
    price: obj.price,
    amountUsd: formatAmountPrice(s.balance, obj?.price, obj?.decimals),
    priceUpdated: obj.priceUpdated,
  }))
}

const Monitor: React.FC<MonitorProps> = ({ chainId }) => {
  const params = useParams<{ id: string }>()
  const [selectedView, setSelectedView] = useState('TABLE')
  const [selectedOpenAll, setSelectedOpenAll] = useState(false)
  const [selectedThreshold, setSelectedThreshold] = useState<number | string>(100)
  const [usingThreshold, setUsingThreshold] = useState(false)
  const [filters, setFilters] = useState<ManyTokenFilters & { token?: string; threshold?: string; chainId: number }>({
    chainId: chainId,
  })
  const [dat, setDat] = useState<AggregatedTokenInfo[]>([])
  const [page, setPage] = useState(1)
  const [loadPageData, setLoadPageData] = useState(1)
  const [totalPagesData, setTotalPagesData] = useState(1)
  const [loadPagePrice, setLoadPagePrice] = useState(1)
  const [totalPagesPrice, setTotalPagesPrice] = useState(1)
  const [limit] = useState(50)
  const { summary, isLoading, tokenList } = useSimpleMonitor(params.id, chainId, page, 200, filters, 0)

  const { data: dataTokenInfo, isFetched: isFetchedTokenInfo } = useManyTokenInfo(
    tokenList,
    filters,
    limit,
    setLoadPageData,
    setTotalPagesData
  )

  const {
    data: dataTokenPrices,
    isLoading: isLoadingTokenPrices,
    isFetched: isFetchedTokenPrices,
  } = useTokenPrices(tokenList, { chainId: filters.chainId }, limit, setLoadPagePrice, setTotalPagesPrice)

  const navigate = useNavigate()
  const location = useLocation()

  const aggregateFull = (
    summary: BalanceSummary[],
    dataTokenInfo: Record<string, TokenInfo> | undefined,
    dataTokenPrices: TokenPrice[] | undefined
  ) => {
    const aggregated: Record<string, AggregatedTokenInfo> = {}

    summary.forEach((token) => {
      const { balance, address, source } = token

      if (!aggregated[address]) {
        aggregated[address] = {
          balance,
          address,
          source,
          decimals: 18,
        }
      }

      if (dataTokenInfo?.data && dataTokenInfo.data[address]) {
        aggregated[address].symbol = dataTokenInfo.data[address].symbol
        aggregated[address].decimals = dataTokenInfo.data[address].decimals
      }

      if (dataTokenPrices) {
        const pricing = dataTokenPrices.find((t) => address === t?.address)
        if (pricing) {
          aggregated[address].price = pricing.price
          aggregated[address].priceUpdated = pricing.date
          aggregated[address].amountUsd = formatAmountPrice(balance, pricing.price, aggregated[address].decimals)
        }
      }
    })

    return Object.values(aggregated).sort((a, b) => aLessB(b.amountUsd || 0, a.amountUsd || 0))
  }

  const updateURL = (newFilters: Record<string, any>) => {
    const searchParams = new URLSearchParams()

    Object.keys(newFilters).forEach((key) => {
      if (newFilters[key]) {
        searchParams.set(key, newFilters[key])
      } else {
        searchParams.delete(key)
      }
    })

    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    })
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const token = searchParams.get('token') || ''
    const withThreshold = searchParams.get('withThreshold') || ''
    const openAll = searchParams.get('openAll') || ''
    const threshold = searchParams.get('threshold') || ''

    setFilters({ token, threshold, chainId })
    setSelectedOpenAll(openAll === 'true')
    setUsingThreshold(withThreshold === 'true')
    setSelectedThreshold(threshold.replace('$', ''))

    updateURL({ token, threshold, chainId })
  }, [chainId])

  const handleRealTimeChange = () => {
    setUsingThreshold(!usingThreshold)
    if (!filters?.threshold) {
      handleSelectThreshold('$100')
    }
    updateURL({
      ...filters,
      openAll: selectedOpenAll,
      usingThreshold: !usingThreshold,
    })
  }

  const handleColored = () => {
    setSelectedOpenAll(!selectedOpenAll)
    updateURL({
      ...filters,
      realtime: usingThreshold,
      openAll: !selectedOpenAll,
    })
  }

  const handleSelectThreshold = (limit: string) => {
    setSelectedThreshold(limit.replace('$', ''))
    setPage(1)
    setTimeout(() => {
      if (limit !== '') {
        setFilters({ ...filters, threshold: limit })
        updateURL({ ...filters, threshold: limit })
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { threshold, ...otherFilters } = filters
        setFilters({ ...otherFilters })
        updateURL({ ...otherFilters })
      }
    }, 500)
  }

  function download(csvData: string) {
    const blob = new Blob([csvData], { type: 'text/csv' })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.setAttribute('hidden', '')
    a.setAttribute('href', url)
    a.setAttribute('download', `monitor-${params.id}-chainId-${chainId}.csv`)
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    window.URL.revokeObjectURL(url)
  }

  const handleDownload = () => {
    const csvData = objectToCsv(dat)
    download(csvData)
  }

  useEffect(() => {
    setDat(aggregateFull(summary, dataTokenInfo, dataTokenPrices))
  }, [summary, dataTokenInfo, dataTokenPrices])

  const changeView = (view: string) => {
    if (selectedView === view) {
      setSelectedView('TABLE')
    } else {
      setSelectedView(view)
    }
  }

  return (
    <div>
      <br />
      <br />
      <ActivitySection>
        <FlexMenu>
          <Details selected={selectedView === 'MONITOR'} onClick={() => changeView('MONITOR')} disabled={isLoading}>
            Monitor
          </Details>
          <Details
            selected={selectedView === 'PRICES'}
            onClick={() => changeView('PRICES')}
            disabled={isLoadingTokenPrices}
          >
            Prices
          </Details>
          <Details onClick={handleDownload} disabled={isLoading}>
            <span>CSV ⇣</span>
          </Details>
          <input
            value={`$${selectedThreshold}`}
            placeholder="threshold"
            onChange={(e) => handleSelectThreshold(e.target.value)}
            className="custom-plan"
          />
          <Details onClick={handleRealTimeChange}>
            <span>
              Use threshold <Switch mode={usingThreshold.toString()}>{usingThreshold ? 'ON' : 'OFF'}</Switch>
            </span>
          </Details>
          <Details onClick={handleColored}>
            <span>
              Open all <Switch mode={selectedOpenAll.toString()}>{selectedOpenAll ? 'ON' : 'OFF'}</Switch>
            </span>
          </Details>

          <FlexRowRight>
            <div className={'custom-load-container'}>
              Monitor {`(${summary?.length})`}{' '}
              {isLoading ? <span className="loading">Loading!</span> : <span className="done">✓</span>}
            </div>
            <div className={'custom-load-container'}>
              Tokens Data {`(${Object?.values(dataTokenInfo || {})?.length})`}{' '}
              {!isFetchedTokenInfo ? (
                <span className="loading">Loading! {`${loadPageData}/${totalPagesData}`}</span>
              ) : (
                <span className="done">✓</span>
              )}
            </div>
            <div className={'custom-load-container'}>
              Prices {`(${dataTokenPrices?.length || 0})`}{' '}
              {!isFetchedTokenPrices ? (
                <span className="loading">Loading! {`${loadPagePrice}/${totalPagesPrice}`}</span>
              ) : (
                <span className="done">✓</span>
              )}
            </div>
          </FlexRowRight>
        </FlexMenu>
        <GenericTable selectedView={selectedView} index="MONITOR" data={summary} isLoadingResponse={isLoading} />
        <GenericTable
          selectedView={selectedView}
          index="PRICES"
          data={dataTokenPrices}
          isLoadingResponse={isLoadingTokenPrices}
        />
        <ShowTable
          selectedView={selectedView}
          isLoading={isLoading}
          summary={summary}
          oa={dat}
          width={1200}
          selectedOpenAll={selectedOpenAll}
          selectedThreshold={selectedThreshold}
          params={params}
          usingThreshold={usingThreshold}
        />
      </ActivitySection>
    </div>
  )
}

export default Monitor
