import React, { useState } from 'react'
import styled from 'styled-components'
import { shortenAddress } from '../utils/web3-utils'
import { ReactComponent as CopyImg } from '../assets/copy.svg'
import useCopyToClipboard from '../hooks/useCopyToClipboard'

interface LongIdProps {
  longId: string
}

const LongId: React.FC<LongIdProps> = ({ longId }) => {
  const { copy } = useCopyToClipboard()
  const [copied, setCopied] = useState<boolean>(false)

  const handleCopy = () => {
    copy(longId).then(() => {
      setCopied(true)
      setTimeout(() => {
        setCopied(false)
      }, 5000)
    })
  }

  return (
    <Container>
      <span>{shortenAddress(longId)}</span>
      {copied ? <CopyText>ok</CopyText> : <CopyImgSm onClick={handleCopy} />}
    </Container>
  )
}

const CopyImgSm = styled(CopyImg)`
  height: 15px !important;
  width: 15px !important;
  padding: 0 !important;
  margin: 0 5px;
  margin-top: 1px;
`

const Container = styled.div`
  display: inline-flex;
  cursor: pointer;
`

const CopyText = styled.div`
  height: 15px !important;
  width: 15px !important;
  padding: 0 !important;
  margin: 0 5px;
  margin-top: 1px;
  color: ${(props) => props.theme.complementaryRoyalPurple};
`

export default LongId
