/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import UserPanel from './UserPanel'
import StagingBanner from './StagingBanner'
import useAdminNotifications from '../../hooks/useAdminNotifications'
const user: string = require('../../assets/user.svg').default
const logo: string = require('../../assets/mimic-admin.svg').default

const NavItem: React.FC<{ to: string; label: string; name: string }> = ({ to, label, name = '' }) => {
  const location = useLocation()
  const isActive = location.pathname.includes(name)

  return (
    <div className={`nav-item ${isActive ? 'active' : ''}`}>
      <NavLink to={to} className="nav-link">
        {label}
      </NavLink>
    </div>
  )
}

export default function NavBar() {
  const [isOpen, setOpen] = useState(false)
  const { updateViewedNotifications, areNewNotifications } = useAdminNotifications()

  const handleOpenUserPanel = () => {
    setOpen(true)
    updateViewedNotifications()
  }

  return (
    <NavbarSection>
      <StagingBanner />
      <NavbarContainer>
        <img alt="logo" src={logo} />
        <NavbarLink>
          <UserIconContainer onClick={handleOpenUserPanel}>
            <img src={user} />
            {areNewNotifications && <NotificationDot />}
          </UserIconContainer>
        </NavbarLink>
        <UserPanel open={isOpen} onClose={() => setOpen(false)} />
      </NavbarContainer>
    </NavbarSection>
  )
}

const NavbarSection = styled.section`
  z-index: 100;
  width: 100%;
  margin: auto;
  position: sticky;
  background: ${(props) =>
    `linear-gradient(to right, ${props.theme.backgroundBelow} 80px, ${props.theme.background} 80px)`};

  @media only screen and (max-width: 700px) {
    background: ${(props) => props.theme.background};
  }
  /* .nav-item.active {
    .nav-link {
      color: #6f5ce6;
    }
    border-bottom: 2px solid #6f5ce6;
    padding-bottom: 7px;
  }
  .nav-item {
    display: inline;
    margin: 0 10px;
    font-weight: bold;
  }
  img {
    cursor: pointer;
    &:hover {
      filter: brightness(50%);
    }
  } */
`

const NavbarContainer = styled.div`
  margin: 0 27px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
`

const NavbarLink = styled.div`
  color: #a5a1b7;
  font-feature-settings:
    'clig' off,
    'liga' off;

  a {
    font-weight: 700;
    color: #6f5ce6;
    font-family: 'DMSansBold';
  }
`

const UserIconContainer = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
`

const NotificationDot = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.background};
`

export { NavItem }
