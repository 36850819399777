import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { ContainerTable, Loader } from '../utils/styles'
import Address from '../components/general/Address'
import { filterByNamespace } from '../utils/web3-utils'
import { useEnvironments } from '../hooks/useEnvironments'
import Network from '../components/general/Network'

interface EnvironmentsListProps {
  searchTerm?: string
}

const EnvironmentsList: React.FC<EnvironmentsListProps> = ({ searchTerm = '' }) => {
  const navigate = useNavigate()
  const { data } = useEnvironments()

  const handleRowClick = (mimicId: string) => {
    navigate(`/dashboard/environments/${mimicId}/`)
  }

  return (
    <Section>
      {data ? (
        <>
          <Table>
            <thead>
              <tr>
                <th className="networks">Namespace</th>
                <th>Mimic ID</th>
                <th>Networks</th>
              </tr>
            </thead>
            <tbody>
              {filterByNamespace(data, searchTerm)?.map((item, index) => (
                <tr key={index} onClick={() => handleRowClick(item.mimicId)}>
                  <td className="accent">{item.namespace}</td>
                  <td>
                    <Address address={item.mimicId} short={false} showIdentity={false} withLink={false} chainId={1} />
                  </td>
                  <td>
                    <Stack>
                      {item.chainIds.map((c, i) => (
                        <Network key={`network${i}`} network={c} small />
                      ))}
                    </Stack>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      ) : (
        <Loader />
      )}
    </Section>
  )
}

const Stack = styled.div`
  display: flex;
  margin: 5px 0px;
`

const Section = styled.div`
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 874px;
  max-width: 90%;
`

const Table = styled(ContainerTable)`
  overflow-x: auto;
  max-width: 100%;
  position: relative;

  .accent {
    color: #33c2b0;
    font-weight: bold;
    font-family: 'DMSansBold';
  }
  tbody {
    tr {
      cursor: pointer;
    }
  }
`

export default EnvironmentsList
