interface Props {
  fill?: string
}

function Config({ fill = '#96969C' }: Props) {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0005 15.5C13.6573 15.5 15.0005 14.1569 15.0005 12.5C15.0005 10.8431 13.6573 9.5 12.0005 9.5C10.3436 9.5 9.00049 10.8431 9.00049 12.5C9.00049 14.1569 10.3436 15.5 12.0005 15.5Z"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.28957 19.8711L9.87402 21.1856C10.0478 21.5768 10.3313 21.9093 10.6902 22.1426C11.0492 22.3759 11.4681 22.5001 11.8962 22.5C12.3244 22.5001 12.7433 22.3759 13.1022 22.1426C13.4612 21.9093 13.7447 21.5768 13.9185 21.1856L14.5029 19.8711C14.711 19.4047 15.0609 19.0159 15.5029 18.76C15.9477 18.5034 16.4622 18.3941 16.9729 18.4478L18.4029 18.6C18.8286 18.645 19.2582 18.5656 19.6396 18.3713C20.021 18.1771 20.3379 17.8763 20.5518 17.5056C20.766 17.135 20.868 16.7103 20.8455 16.2829C20.823 15.8555 20.677 15.4438 20.4251 15.0978L19.5785 13.9344C19.277 13.5171 19.1159 13.0148 19.1185 12.5C19.1184 11.9866 19.281 11.4864 19.5829 11.0711L20.4296 9.90778C20.6814 9.56175 20.8275 9.15007 20.85 8.72267C20.8725 8.29528 20.7704 7.87054 20.5562 7.5C20.3423 7.12923 20.0255 6.82849 19.644 6.63423C19.2626 6.43997 18.833 6.36053 18.4074 6.40556L16.9774 6.55778C16.4667 6.61141 15.9521 6.50212 15.5074 6.24556C15.0645 5.98825 14.7144 5.59736 14.5074 5.12889L13.9185 3.81444C13.7447 3.42317 13.4612 3.09072 13.1022 2.8574C12.7433 2.62408 12.3244 2.49993 11.8962 2.5C11.4681 2.49993 11.0492 2.62408 10.6902 2.8574C10.3313 3.09072 10.0478 3.42317 9.87402 3.81444L9.28957 5.12889C9.0825 5.59736 8.73245 5.98825 8.28957 6.24556C7.84479 6.50212 7.33024 6.61141 6.81957 6.55778L5.38513 6.40556C4.95946 6.36053 4.52987 6.43997 4.14844 6.63423C3.76702 6.82849 3.45014 7.12923 3.23624 7.5C3.02206 7.87054 2.92002 8.29528 2.94251 8.72267C2.96499 9.15007 3.11103 9.56175 3.36291 9.90778L4.20957 11.0711C4.51151 11.4864 4.67411 11.9866 4.67402 12.5C4.67411 13.0134 4.51151 13.5137 4.20957 13.9289L3.36291 15.0922C3.11103 15.4382 2.96499 15.8499 2.94251 16.2773C2.92002 16.7047 3.02206 17.1295 3.23624 17.5C3.45036 17.8706 3.76727 18.1712 4.14864 18.3654C4.53001 18.5596 4.95949 18.6392 5.38513 18.5944L6.81513 18.4422C7.3258 18.3886 7.84034 18.4979 8.28513 18.7544C8.72966 19.011 9.08134 19.402 9.28957 19.8711Z"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Config
