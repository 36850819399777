import React, { useRef, useEffect } from 'react'
import Chart, { ChartData } from 'chart.js/auto'
import styled from 'styled-components'
import { SimulationsResponse } from '../../../types/API'

interface SimulationsChartProps {
  data: SimulationsResponse
}

const SimulationsChart: React.FC<SimulationsChartProps> = ({ data }) => {
  const lineData: Record<string, number> = {}
  let totalizer = 0

  for (const [date, { simulationFailed, simulationReverted, simulationSucceeded }] of Object.entries(data)) {
    if (lineData[date]) {
      lineData[date] += simulationFailed + simulationReverted + simulationSucceeded
    } else {
      lineData[date] = simulationFailed + simulationReverted + simulationSucceeded
    }

    totalizer += simulationFailed + simulationReverted + simulationSucceeded
  }

  const processData = () => {
    const allDateLabels = Object.keys(data)
    allDateLabels.sort()
    return { dateLabels: allDateLabels }
  }

  const { dateLabels } = processData()

  const chartData: ChartData<'line', number[], string> = {
    labels: dateLabels,
    datasets: [
      {
        type: 'line',
        fill: true,
        label: 'Simulations',
        yAxisID: 'tasks-y-axis',
        data: Object.values(lineData),
        borderColor: 'rgb(164,59,59)',
        backgroundColor: 'rgba(117,33,40,0.82)',
        pointBackgroundColor: 'rgb(129,40,64)',
        tension: 0.8,
        pointRadius: 7,
        pointHoverRadius: 10,
        pointHoverBackgroundColor: 'rgb(129,40,64)',
        pointHoverBorderColor: 'rgb(129,40,64)',
      },
    ],
  }

  const canvasRef = useRef<HTMLCanvasElement | null>(null)
  const chartRef = useRef<Chart | null>(null)

  const createChart = () => {
    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext('2d')
      if (ctx) {
        chartRef.current = new Chart(ctx, {
          type: 'bar',
          data: chartData,
          options: {
            responsive: true,
            scales: {
              'tasks-y-axis': {
                beginAtZero: true,
              },
            },
          },
        })
      }
    }
  }

  const destroyChart = () => {
    if (chartRef.current) {
      chartRef.current.destroy()
    }
  }

  useEffect(() => {
    createChart()

    return () => {
      destroyChart()
    }
  }, [data])

  return (
    <>
      <h1>Simulations {totalizer > 0 && <Tot>Total: {totalizer}</Tot>}</h1>
      <div>
        <canvas ref={canvasRef} />
      </div>
    </>
  )
}

const Tot = styled.p`
  font-size: 14px;
  display: inline-flex;
  background: rgb(164, 59, 59);
  padding: 5px 10px;
  border-radius: 22px;
  margin-left: 18px;
`

export default SimulationsChart
