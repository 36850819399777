import { useState } from 'react'
import { useApi } from './useApi'

const usePrice = (chainId: number, address: string) => {
  const [price, setPrice] = useState<string | null>(null)
  const { callApi } = useApi()

  const getPrice = async () => {
    try {
      const params = {
        addresses: [address],
        chainId: chainId,
      }
      const response = await callApi<any>('/price-oracle/prices/last', params)
      setPrice(response?.[0]?.price)
    } catch (error) {
      console.error('Error fetching price:', error)
    }
  }

  const refreshPrice = async () => {
    try {
      const response = await callApi<any>(`/price-oracle/prices/${chainId}/${address}`)
      setPrice(response?.price)
    } catch (error) {
      console.error('Error refreshing price:', error)
    }
  }

  return {
    price,
    getPrice,
    refreshPrice,
  }
}

export default usePrice
