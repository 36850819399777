// Componente.tsx
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { PLAYERS } from '../../constants/playerAddress'
import { getEtherscanLink, shortenAddress, compareAddresses } from '../../utils/web3-utils'
import copyImg from '../../assets/copy.png'
import ok from '../../assets/check.png'
import open from '../../assets/link.png'
import useCopyToClipboard from '../../hooks/useCopyToClipboard'
import { SingleNameBlockies } from './Blockies'

interface AddressProps {
  address: string
  chainId?: number | undefined
  showIdentity?: boolean
  short?: boolean
  withLink?: boolean
  type?: 'address' | 'transaction'
}

const Address: React.FC<AddressProps> = ({
  address,
  chainId = 1,
  showIdentity = true,
  short = false,
  type = 'address',
  withLink = true,
}) => {
  const player = PLAYERS[address.toLowerCase()]
  const { copy } = useCopyToClipboard()
  const [copied, setCopied] = useState(false)

  const [totalWidth, setTotalWidth] = useState(window.innerWidth)
  useEffect(() => {
    const handleResize = () => setTotalWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const pleaseCopy = () => {
    copy(address)
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 5000)
  }

  return (
    <Container>
      {showIdentity && <SingleNameBlockies imageSize={20} address={address} />}
      {withLink ? (
        <TextAddress
          href={getEtherscanLink(chainId, address, type === 'transaction' ? 'transaction' : 'address')}
          target="_blank"
          rel="noreferrer"
        >
          {totalWidth < 700 || short
            ? shortenAddress(address)
            : player
              ? `${player.name} (${shortenAddress(address)})`
              : address}
        </TextAddress>
      ) : (
        <>
          {totalWidth < 700 || short
            ? shortenAddress(address)
            : player
              ? `${player.name} (${shortenAddress(address)})`
              : address}
        </>
      )}
      {copied ? <ImgSm src={ok} alt="copy" /> : <ImgSm src={copyImg} onClick={() => pleaseCopy()} alt="copy" />}
      {withLink && (
        <a
          href={getEtherscanLink(chainId, address, type === 'transaction' ? 'transaction' : 'address')}
          target="_blank"
          rel="noreferrer"
        >
          <ImgSm src={open} alt="open" />
        </a>
      )}
    </Container>
  )
}

const ImgSm = styled.img`
  height: 15px !important;
  width: 15px !important;
  padding: 0px !important;
  margin: 0px 5px;
  margin-top: 1px;
`

const Container = styled.div`
  display: inline-flex;
  a,
  img {
    cursor: pointer;
  }
`

const TextAddress = styled.a`
  &:hover {
    transition: 0.15s ease color;
    color: ${(props) => props.theme.complementaryRoyalPurple};
  }
`

export default Address
