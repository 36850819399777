import { useQuery } from '@tanstack/react-query'
import { NetworkResponse } from 'API'
import { useApi } from './useApi'

const useNetworkInfo = (chainId: number) => {
  const { callApi } = useApi()

  const fetchNetworkInfo = async (): Promise<NetworkResponse> => {
    const params = {
      chainId: chainId,
    }

    try {
      const chains = await callApi<NetworkResponse[]>('/relayer-executor/chains', params)
      const chainsInfo = chains?.find((object) => object.chainId === chainId)
      return chainsInfo as NetworkResponse
    } catch (error) {
      console.error('Error fetching network info:', error)
      throw error
    }
  }

  return useQuery<NetworkResponse>({
    queryKey: ['networkInfo', chainId],
    queryFn: fetchNetworkInfo,
  })
}

export default useNetworkInfo
