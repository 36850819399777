const theme = {
  background: '#202029',
  backgroundAvobe: '#323244',
  backgroundBelow: '#1C1C24',
  backgroundSurface: '#27252B',

  mainLightest: '#DCD7FF',
  mainLight: '#A89AFF',
  main: '#6F5CE6',
  mainDarker: '#5042A5',
  mainDarkest: '#1E1652',
  complementaryAqua: '#121418',
  complementaryLemon: '#242734',
  panel: '#2C3041',
  complementaryRoyalPurple: '#6F5CE6',
  success: '#33C2B0',
  error: '#DE0000',
  attempt: '#FFCC33',
  textCharcoal: '#121418',
  textDarkGrey: '#5E5F65',
  textGrey: '#A5A1B7',
  textWhite: '#FFF',

  // severity levels
  critical: '#D32F2F',
  high: '#F57C00',
  medium: '#FBC02D',
  low: '#388E3C',
  informational: '#1976D2',
}

export default theme
