import { SupportedChainId } from '../../constants/chainInfo'
import { QueueExecutionsFilters } from '../../hooks/useQueueExecutions'
import { QueueExecutionStatus } from '../../types/API'
import { capitalizeFirstLetter } from '../../utils/math-utils'
import { Filter, Filters } from '../../utils/styles'

interface Props {
  filters: QueueExecutionsFilters
  setFilters: (filters: QueueExecutionsFilters) => void
}

const SUPPORTED_CHAINS = Object.entries(SupportedChainId).map(([key, value]) => ({
  label: capitalizeFirstLetter(key.toLowerCase()) as string,
  value,
}))

function QueueFilters({ filters, setFilters }: Props) {
  const handleMimicIdFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setFilters({ mimicId: value ? value : undefined })
  }

  const handleChainIdFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target
    setFilters({ chainId: value ? parseInt(value) : undefined })
  }

  const handleStatusFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target
    setFilters({ status: value === 'all' ? undefined : value })
  }

  const handleOrderFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target
    setFilters({ order: value as 'asc' | 'desc' })
  }

  return (
    <Filters>
      <Filter>
        <label>Mimic ID</label>
        <input
          type="text"
          placeholder="Filter by Mimic ID"
          value={filters.mimicId ?? ''}
          onChange={handleMimicIdFilterChange}
        />
      </Filter>
      <Filter>
        <label>Chain</label>
        <select value={filters.chainId ?? ''} onChange={handleChainIdFilterChange}>
          <option value="">All</option>
          {SUPPORTED_CHAINS.map((chain) => (
            <option key={chain.value} value={chain.value}>
              {chain.label}
            </option>
          ))}
        </select>
      </Filter>
      <Filter>
        <label>Status</label>
        <select value={filters.status ?? 'all'} onChange={handleStatusFilterChange}>
          <option value="all">All</option>
          {Object.values(QueueExecutionStatus).map((status) => (
            <option key={status} value={status}>
              {capitalizeFirstLetter(status)}
            </option>
          ))}
        </select>
      </Filter>
      <Filter>
        <label>Order</label>
        <select value={filters.order ?? 'asc'} onChange={handleOrderFilterChange}>
          <option value="asc">Asc</option>
          <option value="desc">Desc</option>
        </select>
      </Filter>
    </Filters>
  )
}

export default QueueFilters
