import { Table } from './styles'
import TableCell from './TableCell'

interface GenericTableProps<T> {
  data?: T[]
  isLoadingResponse: boolean
  index: string
  selectedView: string
}

function GenericTable<T extends object>({ data, isLoadingResponse, index, selectedView }: GenericTableProps<T>) {
  if (index !== selectedView) return null
  if (isLoadingResponse) {
    return <div>Loading...</div>
  }

  const headers = data && data.length > 1 ? Object.keys(data[0]) : []

  return (
    <Table>
      <thead>
        <tr>{headers.length && headers.map((header) => <th key={header}>{header}</th>)}</tr>
      </thead>
      <tbody>
        {data &&
          data.map((row, index) => (
            <tr key={index}>
              {headers.map((header) => (
                <TableCell key={header}>{JSON.stringify(row[header as keyof T])}</TableCell>
              ))}
            </tr>
          ))}
      </tbody>
    </Table>
  )
}

export default GenericTable
