// @ts-nocheck
import React from 'react'
import useSubgraphs from '../../hooks/useSubgraphs'
import { ContainerTable, Loader } from '../../utils/styles'
import Network from '../general/Network'

const StatusRelayer: React.FC = () => {
  const data = useSubgraphs()

  return (
    <div>
      {!data.isLoading ? (
        <>
          <ContainerTable>
            <thead>
              <tr>
                <th>Network</th>
                <th>Subgraph</th>
                <th>Synced</th>
                <th>Fatal Error</th>
                <th>Non Fatal Errors</th>
              </tr>
            </thead>
            <tbody>
              {data?.data?.map((item, index) => (
                <tr key={index}>
                  <td>
                    <Network network={item.chainId} />
                  </td>
                  <td>{item.subgraph}</td>
                  <td>{item.synced ? '🟢' : '🔴'}</td>
                  <td>{item.fatalError ? item.fatalError : '-'}</td>
                  <td>{item?.nonFatalErrors?.map((error, key) => <p key={key}>{error}</p>)}</td>
                </tr>
              ))}
            </tbody>
          </ContainerTable>
        </>
      ) : (
        <Loader />
      )}
    </div>
  )
}

export default StatusRelayer
