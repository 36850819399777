import React from 'react'
import styled from 'styled-components'

interface SwitchProps {
  ison: boolean
  onToggle: () => void
  oncolor?: string
  offcolor?: string
}

const StyledSwitch = styled.div<{ ison: string; oncolor: string; offcolor: string }>`
  width: 32px;
  height: 16px;
  background-color: ${({ ison, oncolor, offcolor }) => (ison === 'true' ? oncolor : offcolor)};
  border-radius: 15px;
  position: relative;
  cursor: pointer;
`

const SwitchHandle = styled.div<{ ison: boolean }>`
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  transition: transform 0.3s ease;
  transform: ${({ ison }) => (ison ? 'translateX(16px)' : 'translateX(0)')};
`

const Switch: React.FC<SwitchProps> = ({ ison, onToggle, oncolor = '#5CE6D5', offcolor = '#F94872' }) => {
  return (
    <StyledSwitch ison={ison?.toString() || 'false'} oncolor={oncolor} offcolor={offcolor} onClick={onToggle}>
      <SwitchHandle ison={ison} />
    </StyledSwitch>
  )
}

export default Switch
