import React, { useEffect, useState } from 'react'
import axios from 'axios'
import moment from 'moment'
import { ScrollTable, ContainerTable, Loader } from '../../utils/styles'
import { useApi } from '../../hooks/useApi'
import { formatDuration } from '../../hooks/useCrons'

interface Status {
  suspend: boolean
  schedule: string
  name: string
  lastCompletionTime: any
  lastStartTime: any
  lastScheduleTime: any
  nextScheduleTime: any
  _id: string
  active: boolean
}

const URL = process.env.REACT_APP_SERVER_BASE_URL

const StatusRelayer: React.FC = () => {
  const { callApi } = useApi()
  const [statusRelayerData, setData] = useState<Status[] | null>(null)
  const [customModalOpen, setCustomModalOpen] = useState(false)
  const [deleteParams, setDeleteParams] = useState<string>('')

  const fetchData = async () => {
    try {
      const response = await callApi('/admin/crons')
      setData(response)
    } catch (error) {
      console.error('There was an error loading the data:', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const crons = statusRelayerData
    ? statusRelayerData.filter((obj) => !obj.name.startsWith('relayer-executor-cron'))
    : null
  const relayers = statusRelayerData
    ? statusRelayerData.filter((obj) => obj.name.startsWith('relayer-executor-cron'))
    : null

  return (
    <div>
      {statusRelayerData ? (
        <>
          <br />
          <br />
          <h1>Relayer Executor</h1>
          <ScrollTable>
            <ContainerTable>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Duration</th>
                  <th>Last Start Time</th>
                  <th>Last Completion Time</th>
                  <th>Last Schedule Time</th>
                  <th>Next Schedule Time</th>
                  <th>Schedule</th>
                  <th>Suspended</th>
                </tr>
              </thead>
              <tbody>
                {relayers?.map((item, index) => (
                  <tr key={index}>
                    <td className="accent">{item.name}</td>
                    <td>
                      {formatDuration(
                        moment.duration(moment(item.lastCompletionTime).diff(moment(item.lastStartTime)))
                      )}
                      {moment.duration(moment(item.lastCompletionTime).diff(moment(item.lastStartTime))).asMinutes() <
                        2 && item.active !== true ? (
                        <> 🚨</>
                      ) : (
                        ''
                      )}
                    </td>
                    <td>{moment(item.lastStartTime).format('DD/MM/YY HH:mm[hs]')}</td>
                    <td>
                      {item.active === true
                        ? 'Running 🏃‍♂️'
                        : moment(item.lastCompletionTime).format('DD/MM/YY HH:mm[hs]')}
                    </td>
                    <td>{moment(item.lastScheduleTime).format('DD/MM/YY HH:mm[hs]')}</td>

                    <td>{moment(item.nextScheduleTime).format('DD/MM/YY HH:mm[hs]')}</td>
                    <td>{item.schedule}</td>
                    <td>{item.suspend ? '🔴' : '🟢'}</td>
                  </tr>
                ))}
              </tbody>
            </ContainerTable>
          </ScrollTable>
          <br />
          <br />
          <br />
          <br />
          <h1>Others</h1>
          <ScrollTable>
            <ContainerTable>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Duration</th>
                  <th>Last Start Time</th>
                  <th>Last Completion Time</th>
                  <th>Last Schedule Time</th>
                  <th>Next Schedule Time</th>
                  <th>Schedule</th>
                  <th>Suspended</th>
                </tr>
              </thead>
              <tbody>
                {crons?.map((item, index) => (
                  <tr key={index}>
                    <td className="accent">{item.name}</td>
                    <td>
                      {formatDuration(
                        moment.duration(moment(item.lastCompletionTime).diff(moment(item.lastStartTime)))
                      )}
                      {moment.duration(moment(item.lastCompletionTime).diff(moment(item.lastStartTime))).asMinutes() <
                        2 && item.active !== true ? (
                        <> 🚨</>
                      ) : (
                        ''
                      )}
                    </td>
                    <td>{moment(item.lastStartTime).format('DD/MM/YY HH:mm[hs]')}</td>
                    <td>
                      {item.active === true
                        ? 'Running 🏃‍♂️'
                        : moment(item.lastCompletionTime).format('DD/MM/YY HH:mm[hs]')}
                    </td>
                    <td>{moment(item.lastScheduleTime).format('DD/MM/YY HH:mm[hs]')}</td>

                    <td>{moment(item.nextScheduleTime).format('DD/MM/YY HH:mm[hs]')}</td>
                    <td>{item.schedule}</td>
                    <td>{item.suspend ? '🔴' : '🟢'}</td>
                  </tr>
                ))}
              </tbody>
            </ContainerTable>
          </ScrollTable>
        </>
      ) : (
        <Loader />
      )}
    </div>
  )
}

export default StatusRelayer
