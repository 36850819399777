interface Props {
  fill?: string
}

function Tokens({ fill = '#96969C' }: Props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.101 4.5C11.3636 3.26281 13.0927 2.5 15 2.5C18.866 2.5 22 5.63401 22 9.5C22 11.4073 21.2372 13.1365 19.9999 14.399M16 15.5C16 19.366 12.866 22.5 9 22.5C5.13401 22.5 2 19.366 2 15.5C2 11.634 5.13401 8.5 9 8.5C12.866 8.5 16 11.634 16 15.5Z"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Tokens
