import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { Duration } from 'moment'
import { CronStatusResponse } from '../types/API'
import { useApi } from './useApi'

const useCrons = (): UseQueryResult<CronStatusResponse[]> => {
  const { callApi } = useApi()

  const fetchData = async (): Promise<CronStatusResponse[]> => {
    try {
      const data = await callApi<CronStatusResponse[]>('/admin/crons')
      return data || []
    } catch (error) {
      console.error('There was an error loading the crons data:', error)
      return []
    }
  }

  return useQuery({
    queryKey: ['crons'],
    queryFn: fetchData,
  })
}

export const formatDuration = (duration: Duration): string => {
  const hours = duration.hours()
  const minutes = duration.minutes()
  const seconds = duration.seconds()

  let formattedDuration = ''
  if (hours > 0) {
    formattedDuration += `${hours} h${hours > 1 ? 's' : ''} `
  }
  if (minutes > 0) {
    formattedDuration += `${minutes} min `
  }
  if (seconds > 0) {
    formattedDuration += `${seconds} sec `
  }

  return formattedDuration.trim()
}

export default useCrons
