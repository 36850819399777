import React from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { ChartCard, ButtonViolet } from '../../../utils/styles'
import useNetworkInfo from '../../../hooks/useNetworkInfo'
import useEcoModes from '../../../hooks/useEcoModes'
import { ChainStatsResponse } from '../../../types/API'

export interface ActivityData {
  chainInfo?: ChainStatsResponse
  chainId: number
}

export default function Activity({ chainId, chainInfo }: ActivityData) {
  const { data: networkInfo, isLoading } = useNetworkInfo(chainId)
  const params = useParams()
  const { data: ecoModes, isLoading: ecoModeLoading } = useEcoModes(chainId, params.id || '')

  return (
    <ChartCard className={isLoading ? 'loading' : ''}>
      {!isLoading && (
        <>
          <p className="title">Activity</p>
          {!ecoModeLoading && ecoModes && (
            <EcoModeButton className={ecoModes[0]?.active ? 'on' : 'off'}>
              Eco Mode: {ecoModes[0]?.active ? 'ON' : 'OFF'}
            </EcoModeButton>
          )}
          <div>
            <h1>{chainInfo?.transactions ? chainInfo.transactions.success + ' tx' : ''}</h1>
            <p>in the last hour</p>
          </div>
          <div className="divided-text">
            <div style={{ marginTop: '100px' }}>
              <h1>$ {networkInfo && (networkInfo.thresholdAmount || '-')} </h1>
              <p>Threshold Amount</p>
            </div>
          </div>
        </>
      )}
    </ChartCard>
  )
}

const EcoModeButton = styled(ButtonViolet)`
  padding: 10px 20px;
  border-radius: 50px;
  margin-bottom: 38px;
  &.off {
    background: #f94872;
    border: #f94872;
  }
`
