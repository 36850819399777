type IconOptions = {
  size?: number
  scale?: number
  seed?: string
  color?: string
  bgcolor?: string
  spotcolor?: string
}

const randseed: number[] = new Array(4) // Xorshift: [x, y, z, w] 32 bit values

function seedrand(seed: string): void {
  for (let i = 0; i < randseed.length; i++) {
    randseed[i] = 0
  }
  for (let i = 0; i < seed.length; i++) {
    randseed[i % 4] = (randseed[i % 4] << 5) - randseed[i % 4] + seed.charCodeAt(i)
  }
}

function rand(): number {
  // Based on Java's String.hashCode(), expanded to 4 32-bit values
  const t = randseed[0] ^ (randseed[0] << 11)

  randseed[0] = randseed[1]
  randseed[1] = randseed[2]
  randseed[2] = randseed[3]
  randseed[3] = randseed[3] ^ (randseed[3] >> 19) ^ t ^ (t >> 8)

  return (randseed[3] >>> 0) / ((1 << 31) >>> 0)
}

function createColor(): string {
  const h = Math.floor(rand() * 360)
  const s = rand() * 60 + 40 + '%'
  const l = (rand() + rand() + rand() + rand()) * 25 + '%'
  return `hsl(${h}, ${s}, ${l})`
}

function createImageData(size: number): number[] {
  const width = size // Only support square icons for now
  const height = size

  const dataWidth = Math.ceil(width / 2)
  const mirrorWidth = width - dataWidth

  const data: number[] = []
  for (let y = 0; y < height; y++) {
    const row: number[] = []
    for (let x = 0; x < dataWidth; x++) {
      // this makes foreground and background color to have a 43% (1/2.3) probability
      // spot color has 13% chance
      row[x] = Math.floor(rand() * 2.3)
    }
    const r = row.slice(0, mirrorWidth).reverse()
    row.push(...r)

    data.push(...row)
  }

  return data
}

function createCanvas(
  imageData: number[],
  color: string,
  scale: number,
  bgcolor: string,
  spotcolor: string
): HTMLCanvasElement {
  const c = document.createElement('canvas')
  const width = Math.sqrt(imageData.length)
  c.width = c.height = width * scale

  const cc = c.getContext('2d')
  if (!cc) throw new Error('Canvas rendering context not available.')

  cc.fillStyle = bgcolor
  cc.fillRect(0, 0, c.width, c.height)
  cc.fillStyle = color

  for (let i = 0; i < imageData.length; i++) {
    const row = Math.floor(i / width)
    const col = i % width
    // if data is 2, choose spot color, if 1 choose foreground
    cc.fillStyle = imageData[i] === 1 ? color : spotcolor

    // if data is 0, leave the background
    if (imageData[i]) {
      cc.fillRect(col * scale, row * scale, scale, scale)
    }
  }

  return c
}

function createIcon(opts: IconOptions = {}): HTMLCanvasElement {
  const size = opts.size || 8
  const scale = opts.scale || 4
  const seed = opts.seed || Math.floor(Math.random() * Math.pow(10, 16)).toString(16)

  seedrand(seed)

  const color = opts.color || createColor()
  const bgcolor = opts.bgcolor || createColor()
  const spotcolor = opts.spotcolor || createColor()
  const imageData = createImageData(size)
  const canvas = createCanvas(imageData, color, scale, bgcolor, spotcolor)

  return canvas
}

export default createIcon
